import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import "./Dashboard.css";

// UI libraries
import ReactTooltip from "react-tooltip";
import { Skeleton } from "primereact/skeleton";
import { Tooltip } from "primereact/tooltip";
import CalendarHeatmap from "react-calendar-heatmap";
import { Badge } from "primereact/badge";
//helpers
import { ConvertTexttoChart, convertTexttoStatus } from "../../Helper/Helper";
import httpService from "../../../services/http.service";
import { milisecondToTime } from "../../Helper/Helper";
import _ from "lodash";
//icons
import Daily from "../../../assets/DailySuite.svg";
import Yearly from "../../../assets/YearlySuite.svg";
import Monthly from "../../../assets/MonthlySuite.svg";
import Weekly from "../../../assets/WeeklySuite.svg";
import runningSuites from "../../../assets/runningSuites.svg";
import suitCompletedIcon from "../../../assets/time-oclock.svg";
import ActiveUsers from "../../../assets/ActiveUsers.svg";
import TotalProjects from "../../../assets/TotalProjects.svg";
import AutomationTC from "../../../assets/automationTC.svg";
import ManualTC from "../../../assets/manualTC.svg";
import { handleToastErrorCatch } from "../../Helper/ToastHelper";
import { connectSocket } from "../../Helper/SocketHelper";
import KillExecution from "./KillExecution";

const Dashboard = () => {
  const projectData = useSelector((state) => state.autolytics.projectData);
  const pidArray = useRef(null);
  const [polarChartData, setPolarChartData] = useState(null);
  const [polarChartDataIsLoading, setPolarChartDataIsLoading] = useState(true);
  const [last5runsData, setLast5runsData] = useState(null);
  const [last5runsDataIsLoading, setLast5runsDataIsLoading] = useState(true);
  const [currentRuns, setCurrentRuns] = useState(null);
  const [currentRunsIsLoading, setCurrentRunsIsLoading] = useState(true);
  const [heatmapData, setHeatmapData] = useState(null);
  const [totalSuiteRuns, setTotalSuiteRuns] = useState(0);
  const [statsData, setStatsData] = useState(null);
  const [userProjectAnalysis, setUserProjectAnalysis] = useState(null);
  const subscribepids = useRef(null);
  const testpadConnection = useRef(null);

  const suiteIcons = {
    Daily: Daily,
    Weekly: Weekly,
    Monthly: Monthly,
    Yearly: Yearly,
  };

  const SuiteDataImgs = {
    "Active users": ActiveUsers,
    "Total projects": TotalProjects,
    "Automation testcase": AutomationTC,
    "Manual testcase": ManualTC,
  };

  const removeUnderScore = (str) => {
    return str.replace(/_/g, " ");
  };
  useEffect(() => {
    // connect();
    connectSocket("execution", "subscribe/private-dashboard", subscribepids, notifyClient)
    connectSocket("testpad", "subscribe/private-dashboard", testpadConnection, notifyClient)
    return () => {
      subscribepids?.current?.unsubscribe();
      testpadConnection?.current?.unsubscribe();

    };
  }, []);
  useEffect(() => {
    if (projectData.length > 0) {
      let pids = projectData.map((data) => data["pid"]);
      pidArray.current = pids;
      let payload = {
        pids: pids,
      };
      getDashboardData(payload);
    }
  }, [projectData]);

  const notifyClient = (e) => {
    let payload = { pids: pidArray.current };
    if (e["body"]) {
      let ifRefresh = JSON.parse(e["body"]);
      if (pidArray.current.includes(parseInt(Object.keys(ifRefresh)[0], 10))) {
        getDashboardData(payload);
      }
    }
  };



  const currentSuites = (suites, type) => {
    const final =
      suites && suites.length === 0 ? (
        <>
          <h6 className="card-subtitle mt-4 text-center text-muted">
            No {_.capitalize(type)}{" "}
            {type === "running" ? "Suite(s) " : "Run(s) "}
            Found
          </h6>
        </>
      ) : (
        suites.map((data, index) => (
          <React.Fragment key={data["S_RUN_ID"]}>
            <tr>
              <td className="suiteNameTable">
                <div className="heading1"> {data["SUITE NAME"]} </div>
                <div className="heading2 color-grey ">
                  {data["PROJECT NAME"]}
                </div>
                <div className="heading3 color-light-grey">
                  <img
                    alt="suit icon"
                    style={{ marginRight: "4px" }}
                    src={type === "running" ? runningSuites : suitCompletedIcon}
                  />
                  {data["COMPLETED ON"] ? "Completed " : "Started "}
                  {`${milisecondToTime(
                    data["COMPLETED ON"]
                      ? data["COMPLETED ON"]
                      : data["STARTED ON"]
                  )} `}
                  ago
                </div>
              </td>
              <td className="vertical-alignment pt-3">
                {type === "running"
                  ? convertTexttoStatus(data["STATUS"])
                  : convertTexttoStatus(data["STATUS"], "", {
                    id: index + 1,
                    count: data["TESTCASE COUNT"] ?? {},
                  })}
              </td>
              <td className="vertical-alignment pt-3">
                <Tooltip target=".tabIcon"></Tooltip>
                {type === "running" &&
                  <KillExecution s_run_id={data?.["S_RUN_ID"]} pid={data?.["P ID"]} />}
                <i
                  onClick={() => {
                    window.open(
                      window.location.origin +
                      "/#/autolytics/execution-report?s_run_id=" +
                      data["S_RUN_ID"] +
                      "&p_id=" +
                      data["P ID"],
                      "_blank"
                    );
                  }}
                  data-pr-tooltip="View report in new tab"
                  data-pr-position="bottom"
                  style={{ color: "#FF7E7C" }}
                  className="pi pi-external-link tabIcon"
                />
              </td>
            </tr>
          </React.Fragment>
        ))
      );
    return final;
  };
  const createSkeletonStats = () => {
    const skelStats = Array.from({ length: 4 });
    const skelStatsCards = skelStats.map(() => (
      <>
        <Skeleton className="mt-2" width="12rem" height="6rem"></Skeleton>
      </>
    ));
    return skelStatsCards;
  };
  const createStats = (data) => {
    const final =
      data && data.length > 0
        ? data.map((cardData) => (
          <>
            <div className="mb-2 suiteNameMap">
              <div className="text-center d-flex align-items-center justify-content-evenly">
                <div className="d-flex align-items-center justify-content-evenly">
                  <img width={20} src={cardData.icon} alt="suite icon" />
                  <span className="px-2 color-light-grey suiteName">
                    {cardData.suite}
                  </span>
                </div>
                <div className="suiteCount">{cardData.suiteData}</div>
              </div>
            </div>
          </>
        ))
        : null;
    return final;
  };
  const getDashboardData = (payload) => {
    httpService
      .getsuiteStatusCount(payload)
      .then((data) => {
        setPolarChartData(data?.data);
        setPolarChartDataIsLoading(false);
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => setPolarChartDataIsLoading(false));
      });

    httpService
      .getsuiteCurrent5Runs(payload)
      .then((data) => {
        setLast5runsData(data?.data);
        setLast5runsDataIsLoading(false);
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => setLast5runsDataIsLoading(false));
      });

    httpService
      .getsuiteexeRuns(payload)
      .then((data) => {
        setCurrentRuns(data?.data);
        setCurrentRunsIsLoading(false);
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => setCurrentRunsIsLoading(false));
      });
    httpService
      .getSuiteHeatmap(payload)
      .then((data) => {
        if (data?.data) {
          let totalSuitesRun = 0;
          data.data?.data.forEach((suite) => {
            totalSuitesRun += suite.count;
          });
          setTotalSuiteRuns(totalSuitesRun);
          setHeatmapData(data.data);
        }
      })
      .catch((err) => {
        handleToastErrorCatch(err);
      });
    httpService
      .getSuiteStats(payload)
      .then((data) => {
        if (data?.data) {
          let suites = Object.keys(data.data);
          let newData = [];
          suites.forEach((suite) => {
            let newDataObject = {
              suite: suite,
              suiteData: data.data[suite],
              icon: suiteIcons[suite],
            };
            newData.push(newDataObject);
          });
          setStatsData(newData);
        }
      })
      .catch((err) => {
        handleToastErrorCatch(err);
      });
    httpService
      .projectAnalysis(payload)
      .then((data) => {
        if (data?.data) setUserProjectAnalysis(data.data);
      })
      .catch((err) => {
        handleToastErrorCatch(err);
      });
  };
  const ProjectAnalysisCard = (props) => {
    return (
      <div className="project-analysis-div col-sm-6 col-md-3 col-6 ">
        <div
          className={`project-analysis-${props.index + 1
            } project-analysis mt-2 `}
        >
          <h6>
            <span class="tile-content project-anaylysis-span">
              {`${_.capitalize(props.heading)} `}
            </span>
          </h6>
          <div className="d-flex w-100 justify-content-between align-items-center">
            <span class="project-anaylysis-span-value">{props.value}</span>
            <img
              width={40}
              className="project-anaylysis-img"
              src={SuiteDataImgs[`${_.capitalize(props.heading)}`]}
              alt="suite data icon"
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="row mt-2">
        {
          polarChartData ? (
            <>
              <div className="mt-2 col-lg-12 col-md-12 col-sm-12 d-flex align-items-stretch">
                <div
                  className={`w-100 ${polarChartData && polarChartData["data"].length > 0
                    ? ""
                    : "withoutContent"
                    } `}
                >
                  <div className="w-100">
                    {/* <h5 className='card-title mt-1'>Suite Data</h5> <hr /> */}
                    <div class="w-100 m-0 d-flex flex-wrap justify-content-between align-content-center suite-data-div">
                      {userProjectAnalysis ? (
                        <>
                          {userProjectAnalysis.length > 0 &&
                            Object.entries(userProjectAnalysis).map(
                              (item, index) => {
                                return (
                                  <ProjectAnalysisCard
                                    heading={removeUnderScore(item[1].name)}
                                    value={item[1].value}
                                    des={item[1].description}
                                    index={index}
                                  />
                                );
                              }
                            )}
                        </>
                      ) : (
                        <Skeleton
                          height="25vh"
                          className="white-skel"
                        ></Skeleton>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="mt-2 col-lg-12 col-md-12 col-sm-12 d-flex align-items-stretch">
                <Skeleton height="25vh" className="white-skel"></Skeleton>
              </div>
            </>
          )}
        {/* heatmap */}
        {heatmapData || statsData ? (
          <div className="mt-3 col-lg-12 col-md-12 col-sm-12 d-flex align-items-stretch">
            <div
              className={`card minCardHeight w-100 ${heatmapData && Object.keys(heatmapData).length > 0
                ? ""
                : "withoutContent"
                } `}
            >
              <div className="card-body">
                <h5 className="card-title mt-1">
                  Suite Run(s) Analysis over Time
                </h5>
                <hr />
                <div className="d-flex justify-content-around flex-wrap mb-2 heat-stats">
                  {statsData ? createStats(statsData) : createSkeletonStats()}
                </div>
                <div>
                  {heatmapData ? (
                    <>
                      {heatmapData && Object.keys(heatmapData).length === 0 ? (
                        <>
                          <h6 className="card-subtitle mt-4 text-center text-muted">
                            No Data Found
                          </h6>
                        </>
                      ) : (
                        <>
                          <div className="mx-1 heat-map p-3 heatmap-div">
                            {/* <hr /> */}
                            <CalendarHeatmap
                              gutterSize={2}
                              showOutOfRangeDays={true}
                              classForValue={(value) => {
                                if (!value) {
                                  return "color-empty";
                                }
                                if (value.count < 10) {
                                  return `color-github-1`;
                                } else if (
                                  value.count >= 10 &&
                                  value.count < 20
                                ) {
                                  return `color-github-2`;
                                } else if (
                                  value.count >= 20 &&
                                  value.count < 30
                                ) {
                                  return `color-github-3`;
                                } else if (value.count >= 30) {
                                  return `color-github-4`;
                                }
                              }}
                              startDate={heatmapData["startTime"]}
                              endDate={heatmapData["endTime"]}
                              values={heatmapData["data"]}
                              tooltipDataAttrs={(value) => {
                                if (value.date) {
                                  return {
                                    "data-tip": `${new Date(
                                      value.date
                                    ).toLocaleDateString()} <br />${value.count
                                      } Suite(s)`,
                                  };
                                } else {
                                  return {
                                    "data-tip": `No Suite Runs`,
                                  };
                                }
                              }}
                            />
                            <ReactTooltip multiline="true" />
                            <div className="legend pt-3 d-flex justify-content-between">
                              <div className="contri-labels mx-1">
                                {totalSuiteRuns} suite runs in{" "}
                                {new Date().getFullYear()}
                              </div>
                              <div className="d-flex justify-content-end">
                                <div className="contri-labels mx-1">Less</div>
                                <div className="contri-colors mx-1 color-contri-0"></div>
                                <div className="contri-colors mx-1 color-contri-1"></div>
                                <div className="contri-colors mx-1 color-contri-2"></div>
                                <div className="contri-colors mx-1 color-contri-3"></div>
                                <div className="contri-colors mx-1 color-contri-4"></div>
                                <div className="contri-labels mx-1">More</div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="mx-1 heat-map p-3 heatmap-div">
                        <Skeleton width="100%" height="150px"></Skeleton>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="mt-2 col-lg-12 col-md-12 col-sm-12 d-flex align-items-stretch">
              <Skeleton height="54vh" className="white-skel"></Skeleton>
            </div>
          </>
        )}
      </div>
      <div className="row mt-2">
        <div className="mt-2 col-lg-4 col-md-6 col-sm-12 d-flex align-items-stretch">
          {polarChartDataIsLoading ? (
            <Skeleton height="63vh" className="white-skel"></Skeleton>
          ) : (
            <div
              className={`card minCardHeight w-100 ${polarChartData?.data?.length > 0 ? "" : "withoutContent"
                } `}
            >
              <div className="card-body stats-card-body">
                <h5 className="card-title mt-1">Overall Suite Analysis</h5>
                <hr />

                {polarChartData ? (
                  <>
                    {ConvertTexttoChart(
                      polarChartData["data"],
                      polarChartData["subType"],
                      false,
                      "",
                      "",
                      "dashboard"
                    )}
                  </>
                ) : (
                  <div className="card-subtitle mt-4 text-center text-muted">No Result Found</div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="mt-2 col-lg-4 col-md-6 col-sm-12 d-flex align-items-stretch">
          {last5runsDataIsLoading ? (
            <Skeleton height="63vh" className="white-skel"></Skeleton>
          ) : (
            <div
              className={`card minCardHeight w-100 ${last5runsData?.length > 0 ? "" : "withoutContent"
                } `}
            >
              <div className="card-body stats-card-body">
                <h3 className="card-title mt-1">Latest Runs</h3> <hr />
                {last5runsData?.length >= 0 ? (
                  <div className="stats-table">
                    <div className="table-responsive">
                      <table className="w-100 mt-0 table">
                        <tbody>{currentSuites(last5runsData, "latest")}</tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div className="card-subtitle mt-4 text-center text-muted">No Result Found</div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="mt-2 col-lg-4 col-md-6 col-sm-12 d-flex align-items-stretch">
          {currentRunsIsLoading ? (
            <Skeleton height="63vh" className="white-skel"></Skeleton>
          ) : (
            <div
              className={`card minCardHeight w-100 ${currentRuns ? "" : "withoutContent"
                } `}
            >
              <div className="card-body stats-card-body">
                <div className="d-flex justify-content-between">
                  <h5 className="mt-1 card-title">Running Suite(s)</h5>
                  {currentRuns && (
                    <div className="d-flex justify-content-end align-content-center running-suites-live-div">
                      <h5 className="blink">
                        <span className="text-danger">Live: </span>
                        <Badge
                          value={`${currentRuns["Live"]}`}
                          className="dashboard-badge"
                        />
                      </h5>
                    </div>
                  )}
                </div>

                <hr />
                {currentRuns ? (
                  <div className="stats-table">
                    <div className="table-responsive">
                      <table className="w-100 table ">
                        <tbody>
                          {currentSuites(currentRuns["running"], "running")}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div className="card-subtitle mt-4 text-center text-muted">No Result Found</div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Dashboard;
