import Home from "../../Layouts/Home/Home";
import "./HomePage.css";
const HomePage = () => {
  return (
    <>
      <Home />
    </>
  );
};

export default HomePage;
