import {
  faCartPlus,
  faCopy,
  faFolderTree,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useRef, useState } from "react";
import SkeletonUtility from "../../../UI/Skeleton/SkeletonUtility";
import httpService from "../../../../services/http.service";
import {
  createColumns,
  createExpandedRowData,
  createFilters,
  getErrorMessage,
  getInfoMessage,
  getToastMessage,
  loadingData,
} from "../../../Helper/Helper";
import {
  handleToastErrorCatch,
  statementError,
} from "../../../Helper/ToastHelper";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import EditTestcase from "../EditTestcase";
import NewTestcaseDialog from "../ALab/NewTestcaseDialog";
import { Dialog } from "primereact/dialog";
import Paginator from "../../../UI/Paginator/Paginator";
import { InputText } from "primereact/inputtext";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import NoData from "../../../UI/NoData/NoData";

const TestcasePillRevamp = ({ sid, selectedSuite }) => {
  const [testcaseData, setTestcaseData] = useState(null);
  const [filters, setFilters] = useState(null);
  const [newTestcaseDialog, setNewTestcaseDialog] = useState(false);
  const [action, setAction] = useState(null);
  const [editTcId, setEditTcId] = useState(null);
  const [testcaseLib, setTestcaseLib] = useState(null);
  const currentPage = useRef(1);
  const [sortValue, setSortValue] = useState(0);
  const [editBtnLoading,setEditBtnLoading]=useState(false);
  const [sortColumn, setSortColumn] = useState(null);
  const rowPerPage = useRef(25);
  const [pageStartValue, setPageStartValue] = useState(0);
  const [pageEndValue, setPageEndValue] = useState(5);
  const [importFilterValue, setImportFilterValue] = useState(null);
  const [showAlltestcase, setShowAlltestcase] = useState(false);
  const [importTestsLoading, setImportTestsLoading] = useState(false);
  const [selectedTestcases, setSelectedTestcases] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [testexpandedRows, setTestExpandedRows] = useState(null);

  useEffect(() => {
    getSuiteTestcases();
  }, []);

  

  useEffect(() => {
    // if (firstUpdate.current) {
    //     firstUpdate.current = false;
    //     return;
    // }
    const filterData = testcaseData?.headers
      ? createFilters(testcaseData.headers)
      : null;
    filterData && setFilters(filterData);
  }, [testcaseData]);

  const importTestcases = (type) => {
    const tcids = selectedTestcases
      ? Object.keys(selectedTestcases).map((data) => {
          return selectedTestcases[data]["Testcase ID"]["value"];
        })
      : null;
    let payload = {
      s_id: sid,
      isolatedVersionIds: tcids,
    };
    if (tcids && tcids.length > 0) {
      setImportTestsLoading(true);
      if (type === "import") {
        httpService
          .importTestcases(payload)
          .then((data) => {
            if (
              data?.["operation"]?.toLowerCase() === "success" &&
              data?.["data"] &&
              typeof data?.["data"] === "object"
            ) {
              const errMsg = data["data"] ? Object.keys(data["data"]) : null;
              errMsg &&
                errMsg?.map((key, val) =>
                  statementError(key + " : " + data?.["data"]?.[key] + "!")
                );
            }
            setShowAlltestcase(false);
            setSelectedTestcases(null);
            currentPage.current = 1;
            setPageStartValue(0);
            setPageEndValue(5);
            rowPerPage.current = 25;
            setSortColumn(null);
            setSortValue(0);
            getToastMessage(data);
            setImportFilterValue(null);
            setTestcaseData(null);
            getSuiteTestcases();
            setImportTestsLoading(false);
          })
          .catch((err) => {
            handleToastErrorCatch(err);
          });
      } else {
        httpService.copyTestcase(payload).then((data) => {
          if (
            data?.["operation"]?.toLowerCase() === "success" &&
            data?.["data"] &&
            typeof data?.["data"] === "object"
          ) {
            const errMsg = data["data"] ? Object.keys(data["data"]) : null;
            errMsg &&
              errMsg.map((key) =>
                statementError(key + " : " + data?.["data"]?.[key] + "!")
              );
          }
          setShowAlltestcase(false);
          setSelectedTestcases(null);
          currentPage.current = 1;
          setPageStartValue(0);
          setPageEndValue(5);
          rowPerPage.current = 25;
          setSortColumn(null);
          setSortValue(0);
          getToastMessage(data);
          setImportFilterValue(null);
          setTestcaseData(null);
          getSuiteTestcases();
          setImportTestsLoading(false);
        });
      }
    } else {
      setShowAlltestcase(true);
      setSelectedTestcases(null);
      toast.info("No Testcase(s) selected from library to import!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const getSuiteTestcases = () => {
    httpService
      .getAvailableSuiteTestCases(sid)
      .then((data) => {
        setTestcaseData(data?.data);
        getErrorMessage(data);
      })
      .catch((err) => {
        handleToastErrorCatch(err);
      });
  };

  const createTestcaseLibrary = () => {
    let payload = {
      s_id: sid,
      key: importFilterValue,
    };
    setTestcaseLib(null);
    httpService
      .getTestcaseLibrary(
        payload,
        currentPage.current,
        sortValue,
        sortColumn,
        rowPerPage.current
        // importFilterValue
      )
      .then((data) => {
        setTestcaseLib(data?.data);
        getErrorMessage(data);
      })
      .catch((err) => {
        handleToastErrorCatch(err);
      });

    setShowAlltestcase(true);
  };

  const onImportTCPagination = (sortField, sortOrder, filter, filterKey) => {
    let payload = {
      s_id: sid,
    };
    setTestcaseLib(null);
    let sortValueField = sortField
      ? [...sortField.toString().split(".sortValue")][0]
      : null;
    let sortOrderValue = sortOrder ? sortOrder : 0;
    let currentPageNo = currentPage.current;
    let rowPerPageCount = rowPerPage.current;
    let searchKey = !filter ? importFilterValue : filterKey;
    payload["key"] = searchKey;

    httpService
      .getTestcaseLibrary(
        payload,
        currentPageNo,
        sortOrderValue,
        sortValueField,
        rowPerPageCount
        // searchKey
      )
      .then((data) => {
        if (filter) {
          setPageStartValue(0);
          setPageEndValue(
            Math.ceil(data["data"]["totalElements"] / rowPerPage.current) >= 5
              ? 5
              : Math.ceil(data["data"]["totalElements"] / rowPerPage.current)
          );
        }
        setTestcaseLib(data && data["data"] ? data["data"] : null);
      })
      .catch((err) => {
        handleToastErrorCatch(err);
      });
  };

  const handleFilterChange = (e) => {
    let { value } = e.target;
    currentPage.current = 1;
    rowPerPage.current = 25;
    setSortColumn(null);
    setSortValue(0);
    setImportFilterValue(value);
    debounceFilter(value);
  };
  const debounceFilter = useCallback(
    debounce((value) => {
      onImportTCPagination(null, 0, true, value);
    }, 1200),
    []
  );
  return (
    <>
      <div
        className="btn-success btn ms-2 themeBtn mt-2"
        onClick={() => {
          createTestcaseLibrary();
        }}
      >
        <FontAwesomeIcon className="ms-1 tabIcon" icon={faFolderTree} /> Import
        from Library
      </div>
      {testcaseData ? (
        <DataTable
          reorderableColumns
          header={`${selectedSuite?.["Suite Name"]?.value} (${selectedSuite?.["Suite ID"]?.value})`}
          onFilter={(e) => {
            setFilters(e.filters);
          }}
          filters={filters}
          collapsedRowIcon="pi pi-arrow-circle-right"
          expandedRowIcon="pi pi-arrow-circle-down"
          removableSort
          scrollHeight={"55vh"}
          className="test-stepTable mt-3"
          rowExpansionTemplate={(mainData) => (
            // const main =
            <>
              {mainData?.frameworkData?.data &&
              mainData?.frameworkData?.data?.length > 0 ? (
                <>
                  <div className="mt-4 text-center overHeadAnalytics">
                    <h4>Testcase Details</h4>
                  </div>
                  <div className="d-flex justify-content-center ">
                    <div className="w-50">
                      <table className=" shadow-sm mt-3 mb-3 table table-borderless testcaseTable">
                        <tbody>
                          {createExpandedRowData(mainData["frameworkData"])}{" "}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              ) : (
                <div className="text-center my-3">
                  <h5>
                    <strong> No Data Found </strong>
                  </h5>
                </div>
              )}
            </>
          )}
          expandedRows={expandedRows}
          onRowToggle={(e, v) => {
            setExpandedRows(e.data); //to expand row without collaping other
          }}
          resizableColumns
          stripedRows
          columnResizeMode="expand"
          value={testcaseData?.data}
          paginator
          rows={25}
          filterDisplay="menu"
          responsiveLayout="scroll"
          // selection={selectandRun}
          // onSelectionChange={(e) => setSelectandRun(e.value)}
          emptyMessage={<NoData />}
          currentPageReportTemplate="Total {totalRecords} Testcase(s) Found"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
        >
          <Column
            // header="Step Report"
            headerClassName={`headercolumnsPrime`}
            className={"columnsPrime"}
            expander={testcaseData?.data ? true : false}
            style={{ width: "10em" }}
          />
          {/* <Column
            selectionMode="multiple"
            headerClassName={`headercolumnsPrime`}
            className={"columnsPrime"}
            headerStyle={{ width: "3em" }}
          ></Column> */}

          {createColumns(
            testcaseData?.headers,
            true,
            true,
            testcaseData?.data,
            false,
            "maxWidthTableText",
            null,
            {
              edit_testcase: true,
            },
            {
              edit_testcase: EditTestcase,
            },
            {
              edit_testcase_props: {
                sid: sid,
                getSuiteTestcases: getSuiteTestcases,
                setAction: setAction,
                setEditTcId: setEditTcId,
                editBtnLoading:editBtnLoading
              },
            }
          )}
        </DataTable>
      ) : (
        <SkeletonUtility
          header={true}
          headerValue={`${selectedSuite?.["Suite Name"]?.value} (${selectedSuite?.["Suite ID"]?.value})`}
          paginator={false}
        />
      )}

      <NewTestcaseDialog
        newTestcaseDialog={newTestcaseDialog}
        sid={sid}
        setElabTcData={setTestcaseData}
        setTestcaseDialog={setNewTestcaseDialog}
        action={action}
        setAction={setAction}
        editTcId={editTcId}
        setEditTcId={setEditTcId}
        actionFrom={"elab"}
        createNewTestcaseButtonLoading={editBtnLoading}
        setCreateNewTestcaseButtonLoading={setEditBtnLoading}
      />

      <Dialog
        blockScroll={true}
        className="createTestcaseDialog"
        header={`Testcase(s) Archives`}
        visible={showAlltestcase}
        onHide={() => {
          setShowAlltestcase(false);
          setSelectedTestcases(null);
          currentPage.current = 1;
          setPageStartValue(0);
          setPageEndValue(5);
          rowPerPage.current = 25;
          setSortColumn(null);
          setSortValue(0);
          setImportFilterValue(null);
          setImportTestsLoading(false);
        }}
        footer={() => (
          <>
            <button
              disabled={importTestsLoading}
              onClick={() => {
                importTestcases("import");
              }}
              className="btn-success mt-3  btn themeBtn"
            >
              <FontAwesomeIcon className="me-2 tabIcon" icon={faCartPlus} />
              Import Original
            </button>
            <button
              disabled={importTestsLoading}
              onClick={() => {
                importTestcases("copy");
              }}
              className="btn-success mt-3  btn themeBtn"
            >
              <FontAwesomeIcon className="me-2 tabIcon" icon={faCopy} />
              Create Copy
            </button>
          </>
        )}
        maximizable
        draggable={false}
        breakpoints={{ "960px": "75vw" }}
        // onMaximize={(e) => setDialogMax(true)}
        style={{
          width: "80vw",
        }}
      >
        {importTestsLoading ? (
          <>
            <div className="pb-4">
              {loadingData("Importing/Copying Testcases... ")}
            </div>
          </>
        ) : (
          <div className="pb-4">
            <div className="p-input-icon-left w-100">
              <i className="pi pi-search" />
              <InputText
                className="reqFormInputs mb-2 mt-1 w-50"
                placeholder="Search"
                value={importFilterValue}
                onChange={(e) => {
                  handleFilterChange(e);
                }}
              />
            </div>
            {testcaseLib && testcaseLib["data"] ? (
              <div>
                <DataTable
                  // header={paginationFilter}
                  reorderableColumns
                  collapsedRowIcon="pi pi-arrow-circle-right"
                  expandedRowIcon="pi pi-arrow-circle-down"
                  selection={selectedTestcases}
                  onSelectionChange={(e) => {
                    setSelectedTestcases(e.value);
                  }}
                  removableSort
                  rowExpansionTemplate={(mainData) => (
                    <>
                      {mainData?.frameworkData?.data &&
                      mainData?.frameworkData?.data?.length > 0 ? (
                        <>
                          <div className="mt-4 text-center overHeadAnalytics">
                            <h4>Testcase Details</h4>
                          </div>
                          <div className="d-flex justify-content-center ">
                            <div className="w-50">
                              <table className=" shadow-sm mt-3 mb-3 table table-borderless testcaseTable">
                                <tbody>
                                  {createExpandedRowData(
                                    mainData["frameworkData"]
                                  )}{" "}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="text-center my-3">
                          <h5>
                            <strong> No Data Found </strong>
                          </h5>
                        </div>
                      )}
                    </>
                  )}
                  expandedRows={testexpandedRows}
                  className="test-stepTable"
                  onRowToggle={(e, v) => {
                    // setExpandedRows([e.data[e.data.length - 1]]); //to Toggle the rows if another row is expanded
                    setTestExpandedRows(e.data); //to expand row without collaping other
                  }}
                  // alwaysShowPaginator={false}
                  resizableColumns
                  stripedRows
                  columnResizeMode="expand"
                  value={
                    testcaseLib && testcaseLib["data"]
                      ? testcaseLib["data"]
                      : null
                  }
                  dragSelection
                  filterDisplay="menu"
                  responsiveLayout="scroll"
                  emptyMessage={<NoData />}
                  sortField={sortColumn}
                  sortOrder={sortValue}
                  onSort={(e) => {
                    setSortColumn(e.sortField);
                    setSortValue(e.sortOrder);
                    onImportTCPagination(e.sortField, e.sortOrder, false);
                  }}
                  footer={
                    <Paginator
                      currentPage={currentPage}
                      totalElements={testcaseLib["totalElements"]}
                      rowsPerPage={rowPerPage}
                      onPagination={onImportTCPagination}
                      pageStartValue={pageStartValue}
                      setPageStartValue={setPageStartValue}
                      pageEndValue={pageEndValue}
                      setPageEndValue={setPageEndValue}
                      sortColumn={sortColumn}
                      sortValue={sortValue}
                    />
                  }
                  scrollHeight={"48vh"}
                >
                  <Column
                    // header="Step Report"
                    headerClassName={`headercolumnsPrime`}
                    className={"columnsPrime"}
                    expander={true}
                    // style={{ width: "10em" }}
                  />
                  <Column
                    selectionMode="multiple"
                    headerClassName={`headercolumnsPrime selectAllHeader`}
                    className={"columnsPrime"}
                    headerStyle={{ width: "3em" }}
                  ></Column>

                  {testcaseLib && testcaseLib["data"] ? (
                    createColumns(
                      testcaseLib["headers"],
                      true,
                      false,
                      testcaseLib["data"]
                    )
                  ) : (
                    <></>
                  )}
                </DataTable>
              </div>
            ) : (
              <SkeletonUtility
                paginator={true}
                currentPage={currentPage}
                totalElements={"..."}
                rowsPerPage={rowPerPage}
                pageStartValue={pageStartValue}
                pageEndValue={pageEndValue}
              />
            )}
          </div>
        )}
      </Dialog>
    </>
  );
};

export default TestcasePillRevamp;
