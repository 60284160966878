import axios from "axios";
import { MICRO_API_URL_REPORT } from "../../config/API_URLS";
import authHeader from "../auth-header";

class CommentService {

    getAllComments=async(payload)=>{
      return await axios
      .get(
        MICRO_API_URL_REPORT.baseURL + `v1/report/comments/?sRunId=${payload.s_run_id}&pId=${payload.p_id}`,
        {
          headers: authHeader(),
        }
      )

      .then((data) => {
        return Promise.resolve(data.data.data);
      })
      .catch((err) => Promise.reject(err));
    }

    getReplyComments=async(payload)=>{
      return await axios
      .get(
        MICRO_API_URL_REPORT.baseURL + `v1/report/comments/?sRunId=${payload.s_run_id}&pId=${payload.p_id}&commentParentId=${payload.parentId}`,
        {
          headers: authHeader(),
        }
      )

      .then((data) => {
        return Promise.resolve(data.data.data);
      })
      .catch((err) => Promise.reject(err));
    }

    createComment=async(payload)=>{
        return await axios
      .post(MICRO_API_URL_REPORT.baseURL + "v1/report/comment/create", payload, {
        headers: authHeader(),
      })
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => 
        Promise.reject(err));
    }

    editComment=async(payload)=>{
      return await axios
    .put(MICRO_API_URL_REPORT.baseURL + "v1/report/comment/update", payload, {
      headers: authHeader(),
    })
    .then((data) => {
      return Promise.resolve(data.data ? data.data : []);
    })
    .catch((err) => 
      Promise.reject(err));
  }

  reactComment=async(payload)=>{
    return await axios
  .put(MICRO_API_URL_REPORT.baseURL + "v1/report/comment/react", payload, {
    headers: authHeader(),
  })
  .then((data) => {
    return Promise.resolve(data.data ? data.data : []);
  })
  .catch((err) => 
    Promise.reject(err));
}


unReactComment=async(payload)=>{
  return await axios
.put(MICRO_API_URL_REPORT.baseURL + "v1/report/comment/unreact", payload, {
  headers: authHeader(),
})
.then((data) => {
  return Promise.resolve(data.data ? data.data : []);
})
.catch((err) => 
  Promise.reject(err));
}

    deleteComment=async(id)=>{
        return await axios
        .delete(MICRO_API_URL_REPORT.baseURL + "v1/report/comment/delete?commentId=" + id, {
            headers: authHeader(),
        })
        .then((data) => {
            return Promise.resolve(data.data);
        })
        .catch((err) => Promise.reject(err));
    }
}

export default new CommentService()