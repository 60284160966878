import React, { useState } from 'react';
import Dashboard from '../Dashboard';
import { Link, useParams } from 'react-router-dom';
import Testcase from './Testcase';
import ComingSoonMain from '../../../Pages/ComingSoon/ComingSoonMain';



const reportPills = [
    { "name": "Insights" },
    { "name": "Testcase" },
    { "name": "Sprint" }
];

const NewDashboard = ({reportData}) => {
   
    const params=useParams();
 
    const renderComponent = () => {
        switch (params?.submodule?.toLowerCase()) {
            case "insights":
                return <Dashboard />;
            case "testcase":
                return <Testcase reportData={reportData}/>;
            case "sprint":
                return <ComingSoonMain/>;
            
        }
    };

    return (
        <div>
            <div className='mt-3'>
                {reportPills.map((pill) => (
                    <Link to={`/autolytics/overview/${pill.name?.toLowerCase()}`}>
                    <button
                        key={pill.name}
                        className={`me-2 my-3 reportPill ${params?.submodule === pill.name?.toLowerCase() ? 'activeReportPill' : ''} py-1 px-3`}
                    >
                        {pill.name}
                    </button>
                    </Link>
                ))}
            </div>
            <div>
                {renderComponent()}
            </div>
        </div>
    );
};

export default NewDashboard;