import { useEffect, useState, useRef } from "react";
import httpService from "../../../services/http.service";
import "./Extent.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faSquareArrowUpRight,
  faCopy,
  faInfoCircle,
  faClock,
  faCirclePlus,
  faPencil,
  faArrowUpRightFromSquare,
  faClipboardList,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";
import {
  copyToClipboard,
  createColumns,
  createFilters,
  ConvertTexttoChart,
  encodeParam,
  decodeParam,
  convertExpectedTime,
  createProgressBar,
  getErrorMessage,
  convertEpochtoDate,
  getToastMessage,
} from "../../Helper/Helper";
import { Tooltip } from "primereact/tooltip";
import { Splitter, SplitterPanel } from "primereact/splitter";
import TestStepRevamp from "./TestStepRevamp";
import { toast } from "react-toastify";
import NoAccess from "../../UI/NoAccess/NoAccess";
import FailedSuiteEdit from "./FailedSuiteEdit";
import { MultiSelect } from "primereact/multiselect";
import ComingSoonMain from "../../Pages/ComingSoon/ComingSoonMain";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import Timeline from "./Timeline";
import Tickets from "./Tickets";
import { handleToastErrorCatch } from "../../Helper/ToastHelper";
import { connectSocket } from "../../Helper/SocketHelper";
import KillExecution from "./KillExecution";
import NoData from "../../UI/NoData/NoData";
import Comments from "./Comments/Index";
import { useSelector } from "react-redux";

const Extent = (props) => {
  const [suiteStatus, setSuiteStatus] = useState(null);
  const [extentData, setExtentData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [isExe, setIsExe] = useState(false);
  const [filters, setFilters] = useState({});
  const [splitReport, setSplitReport] = useState(false);
  const firstUpdate = useRef(true);
  const firstUpdateApi = useRef(true);
  const [paramsData, setParamsData] = useState(null);
  const reportRef = useRef();
  const testcaseRef = useRef();
  const [noAccess, setNoAccess] = useState(false);
  const [inputSwitchValue, setInputSwitchValue] = useState(false);
  const [lastFiveRunsData, setLastFiveRunsData] = useState(null);
  const testDetailsRows = Array.from({ length: 6 });
  const [selectedExtraColumns, setSelectedExtraColumns] = useState([]);
  const [filterHeaders, setFilterHeaders] = useState([]);
  const [actualHeaders, setActualHeaders] = useState([]);
  const [errorLoading, setErrorLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [editBuildDialog, setEditBuildDialog] = useState(false);
  const [sprintName, setSprintName] = useState(null);
  const [buildId, setBuildId] = useState(null);
  const [editHeader, setEditHeader] = useState(null);
  const connection = useRef(null);
  const [manualReportLink, setManualReportLink] = useState("");
  const [s_id, setS_Id] = useState(null);
  const [showManualReportLink, setShowManualReportLink] = useState(false);

  
  useEffect(() => {
    if (props.s_run_id && s_id && props.p_id) {
      setManualReportLink(
        window.location.origin +
          "/#/testpad/manual-testcase-execution?s_run_id=" +
          props.s_run_id +
          "&s_id=" +
          s_id +
          "&p_id=" +
          props.p_id
      );
    }
  }, [props.s_run_id, s_id, props.p_id]);

  // to find if there are any manual testcase. If yes, show link
  useEffect(() => {
    if (s_id) {
      setShowManualReportLink(false);
      httpService.getAvailableSuiteTestCases(s_id).then((data) => {
        const testcasesArray = data?.data?.data;
        testcasesArray.map((item) => {
          if (item?.Framework?.value.trim().toLowerCase() === "manual") {
            setShowManualReportLink(true);
          }
        });
      });
    }
  }, [s_id]);

  const exeSkeletonTemplate = () => {
    return <Skeleton></Skeleton>;
  };

  const getReportData = () => {
    httpService
      .getExtentReportData(props?.s_run_id)
      .then((data) => {
        getErrorMessage(data);
        if (data?.["operation"]?.toLowerCase() === "failure") {
          setErrorLoading(true);
          setErrorMessage(
            data?.["message"] ? data?.["message"] : "Something went wrong!"
          );
          return;
        }
        setExtentData(data);
       
        setUserRole(data?.data?.["Project role"]);
        setBuildId(data?.data?.["Build Details"]?.["Build ID"]);
        setSprintName(data?.data?.["Build Details"]?.["Sprint Name"]);
        let details = data?.data?.TestCase_Details;
        setActualHeaders(details?.headers ? details?.headers : []);
        setFilterHeaders(details?.filterHeaders ? details?.filterHeaders : []);
        setSuiteStatus(data?.data?.status);
      })
      .catch((err) => {
        let errData = err?.response?.data;
        if (
          errData?.operation?.toLowerCase() === "info" &&
          errData?.suboperation
        ) {
          setNoAccess(true);
          return;
        } else if (errData?.operation?.toLowerCase() === "failure") {
          setErrorLoading(true);
          setErrorMessage(
            errData?.message ? errData.message : "Something went wrong!"
          );
          return;
        } else {
          handleToastErrorCatch(err);
        }
      });

    getLastFiveRunsData();
  };

  const getLastFiveRunsData = () => {
    let currentCategory = inputSwitchValue ? "criteria" : "all";
    httpService
      .getLastFiveRuns(props.s_run_id, currentCategory)
      .then((data) => {
        getErrorMessage(data);
        if (data?.["operation"]?.toLowerCase() === "failure") {
          setErrorLoading(true);
          setErrorMessage(
            data?.message ? data.message : "Something went wrong!"
          );
          return;
        }
        setLastFiveRunsData(data?.data);
      })
      .catch((err) => {
        if (
          err?.response?.data?.operation?.toLowerCase() === "info" &&
          err?.response?.data?.suboperation
        ) {
          setNoAccess(true);
          return;
        } else if (
          err?.response?.data?.operation?.toLowerCase() === "failure"
        ) {
          setErrorLoading(true);
          setErrorMessage(
            err?.response?.data?.message
              ? err.response.data.message
              : "Something went wrong!"
          );
          return;
        } else {
          handleToastErrorCatch(err);
        }
      });
  };

  const notifyClient = (e) => {
    if (e["body"]) {
      let ifRefresh = JSON.parse(e["body"]);
      if (
        ifRefresh[props.s_run_id] ||
        ifRefresh["VARIANCE"]?.toString() === props.p_id
      ) {
        getReportData();
      }
    }
  };

  useEffect(() => {
    if (firstUpdateApi.current) {
      firstUpdateApi.current = false;
      return;
    }
    setLastFiveRunsData(null);
    getLastFiveRunsData();
  }, [inputSwitchValue]);
  
  

  
  const expectedCompletion = (expectedTime) => {
    return convertExpectedTime(expectedTime);
    // return convertEpochtoDate(expectedTime, "datetime");
  };


  const createInfraSkeleton = () => {
    const infraSkeletonCards = Array.from({ length: 4 });
    const cards = infraSkeletonCards.map((i) => (
      <>
        <div className="d-flex align-items-stretch mt-3">
          <div className="py-auto px-2">
            <Skeleton width="34rem" height="9rem"></Skeleton>
          </div>
        </div>
      </>
    ));
    return cards;
  };

  const fillBuildDetails = (e, type) => {
    e?.preventDefault();
    if (type === "build") {
      httpService
        .editBuildDetails(props.s_run_id, buildId, null)
        .then((data) => {
          getToastMessage(data);
          if (
            data["operation"] &&
            data["operation"].toLowerCase() === "success"
          ) {
            setEditBuildDialog(false);
          }
        })
        .catch((err) => {
          handleToastErrorCatch(err);
        });
    } else {
      httpService
        .editBuildDetails(props.s_run_id, null, sprintName)
        .then((data) => {
          getToastMessage(data);
          if (
            data["operation"] &&
            data["operation"].toLowerCase() === "success"
          ) {
            setEditBuildDialog(false);
          }
        })
        .catch((err) => {
          handleToastErrorCatch(err);
        });
    }
  };

  const createTimeDetails = (data, type) => {
    const timeCard = data ? (
      type === "notExe" ? (
        <div className="row w-100">
          <div className="col-md-6 d-flex align-items-stretch">
            <div className="row w-100">
              <div className="col-md-6 d-flex align-items-stretch flex-column my-1">
                Clock Time
                <div className="cardHeadercontent my-1">
                  {data?.["Clock Time"] ? (
                    <>
                      {(data?.["Clock Time"]?.["Clock Start Time"]
                        ? convertEpochtoDate(
                            data?.["Clock Time"]?.["Clock Start Time"]
                          )
                        : "-") +
                        " - " +
                        (data?.["Clock Time"]?.["Clock End Time"]
                          ? convertEpochtoDate(
                              data?.["Clock Time"]?.["Clock End Time"]
                            )
                          : "-")}
                    </>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-stretch flex-column my-1">
                Total Duration
                <div className="cardHeadercontent my-1">
                  {data?.["Clock Time"]
                    ? data?.["Clock Time"]?.["Total Duration"]
                      ? data?.["Clock Time"]?.["Total Duration"]
                      : "-"
                    : "-"}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex align-items-stretch">
            <div className="row w-100">
              <div className="col-md-6 d-flex align-items-stretch flex-column my-1">
                Automation Time
                <div className="cardHeadercontent my-1">
                  {data?.["Automation Time"] ? (
                    <>
                      {(data?.["Automation Time"]?.["Automation Start Time"]
                        ? convertEpochtoDate(
                            data?.["Automation Time"]?.["Automation Start Time"]
                          )
                        : "-") +
                        " - " +
                        (data?.["Automation Time"]?.["Automation End Time"]
                          ? convertEpochtoDate(
                              data?.["Automation Time"]?.["Automation End Time"]
                            )
                          : "-")}
                    </>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-stretch flex-column my-1">
                Total Duration
                <div className="cardHeadercontent my-1">
                  {data?.["Automation Time"]
                    ? data?.["Automation Time"]?.["Total Duration"]
                      ? data?.["Automation Time"]?.["Total Duration"]
                      : "-"
                    : "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row d-flex">
          <div className="col-md-6 d-flex align-items-stretch flex-column my-1">
            Start Time
            <div className="cardHeadercontent my-1">
              {data?.["Start Time"]
                ? convertEpochtoDate(data?.["Start Time"])
                : "-"}
            </div>
          </div>
          <div className="col-md-6 d-flex align-items-stretch flex-column my-1">
            Duration
            <div className="cardHeadercontent my-1">
              {data?.["Duration"] ? data?.["Duration"] : "-"}
            </div>
          </div>
        </div>
      )
    ) : null;

    return timeCard;
  };

  const openBuildDetails = (e, header) => {
    e?.preventDefault();
    httpService
      .getBuildDetails(props.s_run_id)
      .then((data) => {
        getErrorMessage(data);
        if (
          data["operation"] &&
          data["operation"].toLowerCase() === "success"
        ) {
          setBuildId(data?.["data"]?.["Build ID"]);
          setSprintName(data?.["data"]?.["Sprint Name"]);
          setEditHeader(header);
          setEditBuildDialog(true);
        }
      })
      .catch((err) => {
        handleToastErrorCatch(err);
      });
  };
  const createInfraDetails = (data, width, type) => {
    let InfraData = data ? data : null;

    const cards = InfraData
      ? Object.keys(InfraData).map((header) => (
          <>
            <div
              className={`col-md-4 col-12 d-flex align-items-stretch mt-1 w-${
                width !== 0 && width
              } `}
            >
              <div className="">
                <div className="py-auto">
                  <span className="execution-headers">{header}</span>
                  <br />
                  <span
                    className={`${
                      type === "buildDetails"
                        ? "execution-headers"
                        : "cardHeadercontent"
                    }`}
                  >
                    {type === "buildDetails"
                      ? InfraData?.[header]
                        ? InfraData?.[header]
                        : "Add Here"
                      : InfraData?.[header]
                      ? InfraData?.[header]
                      : "-"}
                    {type === "buildDetails" ? (
                      <span className="ms-2">
                        <FontAwesomeIcon
                          className={`mx-2 ${
                            userRole?.toString().toLowerCase() === "admin"
                              ? "editBuildIcon"
                              : "disabledIcon"
                          }`}
                          onClick={(e) =>
                            userRole?.toString().toLowerCase() === "admin"
                              ? openBuildDetails(e, header)
                              : null
                          }
                          title={
                            userRole?.toString().toLowerCase() === "admin"
                              ? "Click to Add"
                              : "No Admin Access"
                          }
                          icon={faPencil}
                        />
                      </span>
                    ) : null}
                  </span>
                </div>
              </div>
            </div>
          </>
        ))
      : null;
    return cards;
  };

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (props.filters) {
      setFilters(() => JSON.parse(decodeParam(props.filters)));
      return;
    }

    if (Object.keys(filters).length === 0) {
      let filterData = extentData?.data?.TestCase_Details?.headers
        ? filterHeaders?.length > 0
          ? createFilters([...actualHeaders, ...filterHeaders])
          : createFilters(actualHeaders)
        : {};

      setFilters(() => filterData);
    }
  }, [extentData]);

  const onColumnToggle = (e) => {
    setSelectedExtraColumns(e.value);
  };

  const testcaseHeader = (
    <div style={{ textAlign: "left", minHeight: "2vh" }}>
      <MultiSelect
        placeholder="More Detail(s)"
        value={selectedExtraColumns}
        options={filterHeaders}
        onChange={(e) => onColumnToggle(e)}
        maxSelectedLabels={1}
        style={{ width: "20vw" }}
      />
    </div>
  );

  useEffect(() => {
    if (!suiteStatus) {
      getReportData();
    } else if (suiteStatus.toLowerCase() === "exe") {
      setIsExe(() => true);
    } else {
      setIsExe(false);
    }
  }, [suiteStatus]);

  useEffect(() => {
    if (props?.p_id) {
      connectSocket(
        "execution",
        props.p_id + "/private",
        connection,
        notifyClient
      );
    }
    document.getElementsByClassName(
      "p-splitter-gutter"
    )[0].style.backgroundColor = "transparent";
    document.getElementsByClassName(
      "p-splitter-gutter-handle"
    )[0].style.backgroundColor = "transparent";

    return () => {
      connection?.current?.unsubscribe();
    };
  }, []);

  const showTestStep = (param) => {
    setParamsData(null);
    setSplitReport(true);

    httpService
      .getTestStepReportData(param["TC_RUN_ID"]["value"])
      .then((steps) => {
        if (steps?.data) {
          setParamsData(steps.data);
        } else {
          getErrorMessage(steps?.data);
          // setSplitReport(false);
          document.getElementsByClassName(
            "p-splitter-gutter"
          )[0].style.backgroundColor = "transparent";
          document.getElementsByClassName(
            "p-splitter-gutter-handle"
          )[0].style.backgroundColor = "transparent";
        }
      })
      .catch((err) => {
        // setSplitReport(false);
        document.getElementsByClassName(
          "p-splitter-gutter"
        )[0].style.backgroundColor = "transparent";
        document.getElementsByClassName(
          "p-splitter-gutter-handle"
        )[0].style.backgroundColor = "transparent";
        handleToastErrorCatch(err);
      });

    document.getElementsByClassName(
      "p-splitter-gutter"
    )[0].style.backgroundColor = "#9e9e9e";
    document.getElementsByClassName(
      "p-splitter-gutter-handle"
    )[0].style.backgroundColor = "#9e9e9e";
  };

  const createChartLegends = (data) => {
    const legends = (
      <>
        {data.map((legend) => (
          <>
            {legend[Object.keys(legend)[0]] > 0 ? (
              <div className="mb-1">
                <strong
                  className={`execution-headers-legend align-items-center ms-1`}
                >
                  {Object.keys(legend)[0]}
                </strong>
                <br />
                <span
                  className={`${Object.keys(
                    legend
                  )[0].toLowerCase()}-btn-health report-status align-items-center mt-1 `}
                >
                  {legend[Object.keys(legend)[0]]}
                </span>
              </div>
            ) : null}
          </>
        ))}
      </>
    );

    return legends;
  };
  const mainReport = () => {
    const final = (
      <>
        <div className="mt-1 d-flex">
          <span className="exeReportHeading">
            Automated Test Execution Report
          </span>
          {props.showIcon ? (
            <span className="mt-2 ms-2">
              <label title="Copy Report Link">
                <div
                  className="btn-sm btn-success me-2 reportIcons"
                  onClick={() => {
                    copyToClipboard(
                      window.location.origin +
                        "/#/autolytics/execution-report?s_run_id=" +
                        props.s_run_id +
                        "&p_id=" +
                        props.p_id +
                        "&filters=" +
                        encodeParam(JSON.stringify(filters))
                    );
                  }}
                >
                  <FontAwesomeIcon className="mx-2 tabIcon" icon={faCopy} />
                </div>
              </label>
              <label title="Open in New Window">
                <div
                  className="btn-sm btn-success me-2 reportIcons"
                  onClick={() => {
                    window.open(
                      window.location.origin +
                        "/#/autolytics/execution-report?s_run_id=" +
                        props.s_run_id +
                        "&p_id=" +
                        props.p_id +
                        "&filters=" +
                        encodeParam(JSON.stringify(filters)),
                      "_blank"
                    );
                  }}
                >
                  <FontAwesomeIcon
                    className="mx-2 tabIcon"
                    icon={faSquareArrowUpRight}
                  />
                </div>
              </label>
            </span>
          ) : null}
          <span></span>
          {extentData?.data?.["Execution Info"]?.Status?.toLowerCase() ===
            "exe" && (
            <span className="ms-auto">
              <KillExecution
                button={true}
                s_run_id={props?.s_run_id}
                pid={props?.p_id}
              />
            </span>
          )}
        </div>
        {manualReportLink && s_id && showManualReportLink && (
          <span
            className="breadLink"
            onClick={() => window.open(manualReportLink)}
          >
            <FontAwesomeIcon
              className="tabIcon me-2"
              icon={faArrowUpRightFromSquare}
              title={"Open Suite Execution Report"}
            />
            <span className="mt-1">
              Suite ID: <i>{s_id}</i> | Execute Manual Testcase(s) (Click to
              continue
              <FontAwesomeIcon
                className="tabIcon mx-1"
                icon={faClipboardList}
              />
              )
            </span>
          </span>
        )}
        <ul
          className="nav nav-pills extentUl mb-3 mt-3"
          id={"pills-tab-" + props.id}
          role="tablist"
        >
          <li className="nav-item mr-1" role="presentation">
            <div
              className="fontSizeGeneral nav-link extentPill active"
              id={"pills-overview-tab-" + props.id}
              data-toggle="pill"
              data-target={"#pills-overview-" + props.id}
              type="button"
              role="tab"
              aria-selected="true"
            >
              Overview
            </div>
          </li>
          <li className="nav-item ms-1" role="presentation">
            <div
              className="fontSizeGeneral nav-link extentPill"
              id={"pills-testcase-tab-" + props.id}
              data-toggle="pill"
              data-target={"#pills-testcase-" + props.id}
              type="button"
              role="tab"
              aria-selected="false"
              ref={testcaseRef}
            >
              Testcases
            </div>
          </li>
          <li className="nav-item ms-1" role="presentation">
            <div
              className="fontSizeGeneral nav-link extentPill"
              id={"pills-tickets-tab-" + props.id}
              data-toggle="pill"
              data-target={"#pills-tickets-" + props.id}
              type="button"
              role="tab"
              aria-selected="false"
            >
              Tickets
            </div>
          </li>
          <li className="nav-item ms-1" role="presentation">
            <div
              className="fontSizeGeneral nav-link extentPill"
              id={"pills-timeline-tab-" + props.id}
              data-toggle="pill"
              data-target={"#pills-timeline-" + props.id}
              type="button"
              role="tab"
              aria-selected="false"
            >
              Timeline
            </div>
          </li>
          <li className="nav-item ms-1" role="presentation">
            <div
              className="fontSizeGeneral nav-link extentPill"
              id={"pills-logs-tab-" + props.id}
              data-toggle="pill"
              data-target={"#pills-logs-" + props.id}
              type="button"
              role="tab"
              aria-selected="false"
            >
              Logs
            </div>
          </li>
          <li className="nav-item ms-1" role="presentation">
            <div
              className="fontSizeGeneral nav-link extentPill"
              id={"pills-comments-tab-" + props.id}
              data-toggle="pill"
              data-target={"#pills-comments-" + props.id}
              type="button"
              role="tab"
              aria-selected="false"
            >
              Comments
            </div>
          </li>
        </ul>

        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id={"pills-overview-" + props.id}
            role="tabpanel"
            aria-labelledby={"pills-overview-tab-" + props.id}
          >
            {extentData?.["data"] ? (
              <>
                <div className="mt-2">
                  {extentData?.["data"]?.["Execution Info"] ? (
                    <div className="extentCard mt-4">
                      <div className="row d-flex align-items-center fontSizeGeneral">
                        <div className="col-md-4 col-sm-12 col-12 my-1">
                          <span className="execution-headers">
                            Project | Report
                          </span>
                          <div className="project-name mt-1">
                            {(extentData["data"]["Execution Info"][
                              "Project Name"
                            ]
                              ? extentData["data"]["Execution Info"][
                                  "Project Name"
                                ]
                              : "-") +
                              " | " +
                              (extentData["data"]["Execution Info"][
                                "Report Name"
                              ]
                                ? extentData["data"]["Execution Info"][
                                    "Report Name"
                                  ]
                                : "-"
                              )
                                .split("-")
                                .join(" ")}
                          </div>
                        </div>
                        {extentData["data"]["Execution Info"]["Env"] ? (
                          <div className="col-md-4 col-sm-12 col-12 my-1">
                            <span className="execution-headers">
                              Environment:{" "}
                            </span>
                            <span className="report-env">
                              {extentData["data"]["Execution Info"]["Env"]}
                            </span>
                          </div>
                        ) : null}
                        {extentData["data"]["Execution Info"]["Status"] ? (
                          <div className="col-md-4 col-sm-12 col-12 my-1 d-flex">
                            <span className="execution-headers align-items-center">
                              Status:
                            </span>
                            <span
                              className={`${extentData["data"][
                                "Execution Info"
                              ][
                                "Status"
                              ].toLowerCase()}-btn-health report-status align-items-center`}
                            >
                              {extentData["data"]["Execution Info"]["Status"]}
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                </div>

                {extentData && isExe ? (
                  <>
                    {extentData?.["data"]?.["exe_data"] ? (
                      <>
                        <div className="row mt-2 d-flex">
                          {extentData?.["data"]?.["exe_data"] ? (
                            <div class="col-md-4 py-2 d-flex align-items-stretch">
                              <div class="card extentCard w-100 justify-content-start">
                                <div className="extentHeading">
                                  <span className="card-grid-headers">
                                    Testcase Details
                                  </span>
                                </div>

                                <div className="row d-flex align-items-stretch flex-wrap justify-content-between progressSection">
                                  {extentData?.["data"]?.["exe_data"]?.[
                                    "testcase_info"
                                  ] ? (
                                    <div>
                                      <div className="d-flex justify-content-around align-items-stretch">
                                        {createChartLegends(
                                          extentData["data"]["exe_data"][
                                            "testcase_info"
                                          ]["legend"]
                                        )}
                                      </div>

                                      {ConvertTexttoChart(
                                        extentData["data"]["exe_data"][
                                          "testcase_info"
                                        ]["value"],
                                        "doughnut_chart",
                                        false,
                                        null
                                      )}
                                    </div>
                                  ) : (
                                    "-"
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {extentData?.["data"]?.["exe_data"] ? (
                            <div class="col-md-8 py-2 d-flex align-items-stretch">
                              <div class="card extentCard w-100 justify-content-start">
                                <div className="extentHeading">
                                  <span className="card-grid-headers">
                                    Execution Progress
                                  </span>
                                </div>

                                <div className="row d-flex align-items-center flex-wrap justify-content-between mt-3">
                                  <div className="col-md-6 col-12">
                                    {createProgressBar(
                                      extentData?.["data"]?.["exe_data"]?.[
                                        "testcase_progress"
                                      ]
                                    )}
                                  </div>
                                  <div className="col-md-6 col-12 ps-5">
                                    {extentData["data"]["exe_data"][
                                      "expected_completion"
                                    ] ? (
                                      <div className="mt-3 mb-4">
                                        <strong className="execution-headers">
                                          Expected Completion Time
                                          <Tooltip
                                            position="top"
                                            target=".info-expected"
                                          />
                                          <span
                                            className="info-expected"
                                            data-pr-tooltip="

                                                    This is purely based on
                                                    previous Suite Runs.

                                                    Completion time may vary.

                                                "
                                          >
                                            <FontAwesomeIcon
                                              className="mx-2 tabIcon"
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              icon={faInfoCircle}
                                            />
                                          </span>
                                        </strong>
                                        <div className="mt-1">
                                          <span className="py-1 px-2 exe-btn-health report-status">
                                            <FontAwesomeIcon
                                              className="me-2 clockIcon"
                                              icon={faClock}
                                            />
                                            {convertExpectedTime(
                                              extentData["data"]["exe_data"][
                                                "expected_completion"
                                              ]
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    ) : null}

                                    {extentData?.["data"]?.["exe_data"]?.[
                                      "expected_status"
                                    ]?.["current"] ? (
                                      <div className="my-4">
                                        <strong className="execution-headers">
                                          Current Status
                                        </strong>
                                        <div className="mt-1">
                                          <span
                                            className={`${extentData["data"][
                                              "exe_data"
                                            ]["expected_status"][
                                              "current"
                                            ].toLowerCase()}-btn-health report-status`}
                                          >
                                            {
                                              extentData["data"]["exe_data"][
                                                "expected_status"
                                              ]["current"]
                                            }
                                          </span>
                                        </div>
                                      </div>
                                    ) : null}

                                    {extentData?.["data"]?.["exe_data"]?.[
                                      "expected_status"
                                    ]?.["expected"] ? (
                                      <div className="my-4">
                                        <strong className="execution-headers">
                                          Expected Status
                                        </strong>
                                        <div className="mt-1">
                                          <span
                                            className={`${extentData["data"][
                                              "exe_data"
                                            ]["expected_status"][
                                              "expected"
                                            ].toLowerCase()}-btn-health report-status align-items-center`}
                                          >
                                            {
                                              extentData["data"]["exe_data"][
                                                "expected_status"
                                              ]["expected"]
                                            }
                                          </span>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </>
                    ) : null}
                  </>
                ) : null}

                {extentData && !isExe ? (
                  <>
                    <div className="p-3">
                      <div className="d-flex row justify-content-around">
                        {extentData["data"]["Testcase Info"] ? (
                          <div
                            className="extentCard chartExtent me-2 card my-2 col-lg-4 col-md-6 col-sm-12 col-12"
                            // style={{ marginRight: '-5%' }}
                          >
                            <div className="extentHeading">
                              Testcase Details
                            </div>
                            {ConvertTexttoChart(
                              extentData["data"]["Testcase Info"]["value"],
                              extentData["data"]["Testcase Info"]["subType"],
                              false,
                              extentData["data"]["Testcase Info"]["heading"]
                            )}
                          </div>
                        ) : (
                          <div
                            className="extentCard chartExtent me-2 card my-2 col-lg-4 col-md-6 col-sm-12 col-12"
                            // style={{ marginRight: '-5%' }}
                          >
                            <div className="extentHeading">
                              Testcase Details
                            </div>
                            <div className="h-100 d-flex justify-content-center align-items-center">
                              0 Testcase(s) found
                            </div>
                          </div>
                        )}
                        {lastFiveRunsData ? (
                          <div className="extentCard chartExtent mx-2 card my-2 col-lg-4 col-md-6 col-sm-12 col-12 ">
                            {ConvertTexttoChart(
                              lastFiveRunsData?.["value"],
                              lastFiveRunsData["subType"],
                              false,
                              lastFiveRunsData["heading"],
                              "",
                              "execution_report",
                              inputSwitchValue,
                              setInputSwitchValue
                            )}
                          </div>
                        ) : (
                          <div className="extentCard chartExtent mx-2 card my-2 col-lg-4 col-md-6 col-sm-12 col-12 ">
                            <div className="text-left extentHeading d-flex justify-content-between align-items-center fontSizeGeneral lexendFont extentHeading mb-3">
                              Last 5 Suite Runs
                            </div>
                            <Skeleton width="100%" height="10rem"></Skeleton>
                          </div>
                        )}

                        {extentData["data"]["Category_Bar_Chart"] ? (
                          <div className="extentCard chartExtent ms-2 card my-2 col-lg-4 col-md-6 col-sm-12 col-12">
                            {ConvertTexttoChart(
                              extentData["data"]["Category_Bar_Chart"]["value"],
                              extentData["data"]["Category_Bar_Chart"][
                                "subType"
                              ],
                              false,
                              extentData["data"]["Category_Bar_Chart"][
                                "heading"
                              ]
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : null}

                <div class="row d-flex">
                  {extentData?.["data"]?.["Execution details"] ? (
                    <div class="col-md-6 py-2 d-flex align-items-stretch">
                      <div class="card extentCard w-100 justify-content-start">
                        <div className="extentHeading">
                          <span className="card-grid-headers">
                            Execution Details
                          </span>
                        </div>

                        <div className="row d-flex align-items-stretch flex-wrap justify-content-between mt-3">
                          {createInfraDetails(
                            extentData["data"]["Execution details"],
                            0
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {extentData?.["data"]?.["Infra Headers"] ? (
                    <div class="col-md-6 py-2 d-flex align-items-stretch">
                      <div class="card extentCard w-100 justify-content-start">
                        <div className="extentHeading ">
                          <span className="card-grid-headers">
                            Infra Details
                          </span>
                        </div>

                        <div className="row d-flex w-100 align-items-stretch flex-wrap justify-content-between mt-3">
                          {createInfraDetails(
                            extentData["data"]["Infra Headers"],
                            0
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {!isExe && extentData?.["data"]?.["Build Details"] ? (
                    <>
                      <div class="col-md-6 py-2 d-flex align-items-stretch">
                        <div class="card extentCard w-100 justify-content-start">
                          <div className="extentHeading ">
                            <span className="card-grid-headers">
                              Build Details
                            </span>
                          </div>

                          <div className="row d-flex w-100 align-items-stretch flex-wrap justify-content-between mt-3">
                            {createInfraDetails(
                              extentData["data"]["Build Details"],
                              0,
                              "buildDetails"
                            )}
                          </div>
                        </div>
                      </div>
                      {
                        <Dialog
                          blockScroll={true}
                          draggable={false}
                          header={editHeader}
                          visible={editBuildDialog}
                          maximizable
                          onHide={() => {
                            setEditBuildDialog(false);
                            setEditHeader(null);
                          }}
                          breakpoints={{ "960px": "75vw" }}
                          style={{ width: "35vw" }}
                        >
                          <div className="reqFormInputs mt-1">
                            <InputText
                              type={"text"}
                              value={
                                editHeader?.toLowerCase() === "build id"
                                  ? buildId
                                  : sprintName
                              }
                              onChange={(e) =>
                                editHeader.toLowerCase() === "build id"
                                  ? setBuildId(e.target.value)
                                  : setSprintName(e.target.value)
                              }
                              className="w-100"
                              placeholder={editHeader}
                            />
                          </div>
                          <div className="text-center">
                            {editHeader?.toLowerCase() === "build id" ? (
                              <button
                                type="submit"
                                disabled={!buildId}
                                className="mt-3 btn btn-primary themeBtn text-center px-3"
                                onClick={(e) => fillBuildDetails(e, "build")}
                              >
                                Save Details
                              </button>
                            ) : (
                              <button
                                type="submit"
                                disabled={!sprintName}
                                className="mt-3 btn btn-primary themeBtn text-center px-3"
                                onClick={(e) => fillBuildDetails(e, "sprint")}
                              >
                                Save Details
                              </button>
                            )}
                          </div>
                        </Dialog>
                      }
                    </>
                  ) : null}

                  {isExe && extentData?.["data"]?.["Time Details"] ? (
                    <div class="col-md-6 py-2 d-flex align-items-stretch">
                      <div class="card extentCard w-100 justify-content-start">
                        <div className="extentHeading ">
                          <span className="card-grid-headers">
                            Time Details
                          </span>
                        </div>

                        <div className="row d-flex w-100 align-items-stretch flex-wrap justify-content-between mt-3">
                          {createTimeDetails(
                            extentData["data"]["Time Details"],
                            "exe"
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {extentData?.["data"]?.["User Details"] ? (
                    <div class="col-md-6 py-2 d-flex align-items-stretch">
                      <div class="card extentCard w-100 justify-content-start">
                        <div className="extentHeading ">
                          <span className="card-grid-headers">
                            User Details
                          </span>
                        </div>

                        <div className="row d-flex w-100 align-items-stretch flex-wrap justify-content-between mt-3">
                          {createInfraDetails(
                            extentData["data"]["User Details"],
                            0
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                {extentData && !isExe ? (
                  <div className="d-flex w-100 justify-content-center">
                    {extentData?.["data"]?.["Time Details"] ? (
                      <div className="my-2 col-md-12">
                        <div className="card extentCard">
                          <div className="extentHeading ">
                            <span className="card-grid-headers">
                              Time Details
                            </span>
                          </div>

                          <div className=" d-flex w-100 align-items-stretch flex-wrap justify-content-between mt-3">
                            {createTimeDetails(
                              extentData["data"]["Time Details"],
                              "notExe"
                            )}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </>
            ) : (
              <>
                <div className="mt-2">
                  <div className="pb-2 mt-4">
                    <Skeleton width="100%" height="8rem"></Skeleton>
                  </div>
                </div>
                <div className="card-body text-center">
                  <div className="mt-2 row justify-content-center">
                    <div className="my-1 col-lg-4 col-md-4 col-sm-6 col-12">
                      <Skeleton width="100%" height="12rem"></Skeleton>
                    </div>
                    <div className="my-1 col-lg-4 col-md-4 col-sm-6 col-12">
                      <Skeleton width="100%" height="12rem"></Skeleton>
                    </div>
                    <div className="my-1 col-lg-4 col-md-4 col-sm-6 col-12">
                      <Skeleton width="100%" height="12rem"></Skeleton>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="w-100 d-flex align-items-stretch flex-wrap justify-content-around mt-3">
                    {createInfraSkeleton()}
                  </div>
                  <div className="w-100 mt-3">
                    <Skeleton width="100%" height="9rem"></Skeleton>
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className="tab-pane fade"
            id={"pills-testcase-" + props.id}
            role="tabpanel"
            aria-labelledby={"pills-testcase-tab-" + props.id}
          >
            <div className="d-inline-block wrapperPills">
              <ul
                className="nav nav-pills"
                id={"pills-tab-" + props.id}
                role="tablist"
              >
                {/* <span className='d-flex wrapperPills'> */}
                <li className="nav-item mr-1" role="presentation">
                  <div
                    className="fontSizeGeneral nav-link tcDetailPill active"
                    id={"pills-tcDetails-tab-" + props.id}
                    data-toggle="pill"
                    data-target={"#pills-tcDetails-" + props.id}
                    type="button"
                    role="tab"
                    aria-selected="true"
                  >
                    Testcase Details
                  </div>
                </li>
                <li className="nav-item mr-1" role="presentation">
                  <div
                    className="fontSizeGeneral nav-link tcDetailPill fontSizeGeneral"
                    id={"pills-varianceDetails-tab-" + props.id}
                    data-toggle="pill"
                    data-target={"#pills-varianceDetails-" + props.id}
                    type="button"
                    role="tab"
                    aria-selected="false"
                  >
                    Variance Details
                  </div>
                </li>
                {/* </span> */}
              </ul>
            </div>

            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active mt-3 mb-2"
                id={"pills-tcDetails-" + props.id}
                role="tabpanel"
                aria-labelledby={"pills-tcDetails-tab-" + props.id}
              >
                {extentData?.["data"]?.["TestCase_Details"]?.["data"] ? (
                  <DataTable
                    header={filterHeaders?.length > 0 ? testcaseHeader : false}
                    dataKey="id"
                    reorderableColumns
                    onFilter={(e) => {
                      setFilters(e.filters);
                    }}
                    filters={filters}
                    removableSort
                    resizableColumns
                    stripedRows
                    columnResizeMode="expand"
                    value={
                      extentData["data"]
                        ? extentData["data"]["TestCase_Details"]["data"]
                        : null
                    }
                    paginator
                    rows={25}
                    filterDisplay="menu"
                    responsiveLayout="scroll"
                    emptyMessage={<NoData />}
                    currentPageReportTemplate="Total {totalRecords} Records Found"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown "
                    rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
                    scrollHeight={"55vh"}
                    showGridlines
                    className="test-stepTable"
                  >
                    <Column
                      headerClassName={`expanderheadercolumnsPrime`}
                      className={"expandercolumnsPrime"}
                      body={(e) => {
                        return (
                          <FontAwesomeIcon
                            title={e?.STATUS?.value}
                            className={`mx-2 tabIcon showTCIcon ${
                              e?.STATUS?.value.toLowerCase() + "-color"
                            } `}
                            icon={faCirclePlus}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              showTestStep(e);
                            }}
                          />
                        );
                      }}
                    />
                    {createColumns(
                      actualHeaders,
                      true,
                      true,
                      extentData["data"]["TestCase_Details"]["data"],
                      false,
                      false,
                      false,
                      { falseVariance: true },
                      {
                        falseVariance: FailedSuiteEdit,
                      },
                      {
                        falseVariance_props: {
                          level: "testcase",
                          s_run_id: props.s_run_id,
                        },
                      }
                    )}
                    {createColumns(
                      selectedExtraColumns,
                      true,
                      true,
                      extentData["data"]["TestCase_Details"]["data"]
                    )}
                  </DataTable>
                ) : (
                  <>
                    <div className="pb-4">
                      <DataTable
                        responsiveLayout="scroll"
                        value={testDetailsRows}
                        className="p-datatable-striped mt-3 test-stepTable"
                      >
                        <Column
                          header={
                            <Skeleton
                              width="5rem"
                              className="mx-auto"
                            ></Skeleton>
                          }
                          style={{ width: "5%" }}
                          body={exeSkeletonTemplate}
                        ></Column>
                        <Column
                          header={
                            <Skeleton
                              width="5rem"
                              className="mx-auto"
                            ></Skeleton>
                          }
                          style={{ width: "20%" }}
                          body={exeSkeletonTemplate}
                        ></Column>
                        <Column
                          header={
                            <Skeleton
                              width="5rem"
                              className="mx-auto"
                            ></Skeleton>
                          }
                          style={{ width: "5%" }}
                          body={exeSkeletonTemplate}
                        ></Column>
                        <Column
                          header={
                            <Skeleton
                              width="5rem"
                              className="mx-auto"
                            ></Skeleton>
                          }
                          style={{ width: "20%" }}
                          body={exeSkeletonTemplate}
                        ></Column>
                        <Column
                          header={
                            <Skeleton
                              width="5rem"
                              className="mx-auto"
                            ></Skeleton>
                          }
                          style={{ width: "25%" }}
                          body={exeSkeletonTemplate}
                        ></Column>
                        <Column
                          header={
                            <Skeleton
                              width="5rem"
                              className="mx-auto"
                            ></Skeleton>
                          }
                          style={{ width: "25%" }}
                          body={exeSkeletonTemplate}
                        ></Column>
                      </DataTable>
                    </div>
                  </>
                )}
              </div>
              <div
                className="tab-pane fade my-2"
                id={"pills-varianceDetails-" + props.id}
                role="tabpanel"
                aria-labelledby={"pills-varianceDetails-tab-" + props.id}
              >
                <div className="p-2">
                  <ComingSoonMain />
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id={"pills-tickets-" + props.id}
            role="tabpanel"
            aria-labelledby={"pills-tickets-tab-" + props.id}
          >
            <Tickets s_run_id={props.s_run_id} />
          </div>
          <div
            className="tab-pane fade"
            id={"pills-timeline-" + props.id}
            role="tabpanel"
            aria-labelledby={"pills-timeline-tab-" + props.id}
          >
            <Timeline
              s_run_id={props.s_run_id}
              p_id={props.p_id}
              setS_Id={setS_Id}
            />
          </div>
          <div
            className="tab-pane fade"
            id={"pills-logs-" + props.id}
            role="tabpanel"
            aria-labelledby={"pills-logs-tab-" + props.id}
          >
            <ComingSoonMain />
          </div>
          <div
            className="tab-pane fade"
            id={"pills-comments-" + props.id}
            role="tabpanel"
            aria-labelledby={"pills-comments-tab-" + props.id}
          >
            <Comments props={props}/>
           
          </div>
        </div>
      </>
    );
    return final;
  };
  return (
    <div>
      {noAccess || errorLoading ? (
        <NoAccess errorMessage={errorMessage} />
      ) : (
        <Splitter
          style={{
            height: "100% !important",
            // width: "100vh - calc(10vh + 10vw)",
            border: "none",
            background: "transparent",
          }}
        >
          <SplitterPanel className="parent" size={50}>
            <div className="child px-4"> {mainReport()}</div>
          </SplitterPanel>

          <SplitterPanel
            style={{ display: splitReport ? "inherit" : "none" }}
            className="secondparent"
          >
            <div className="secondchild px-2" ref={reportRef}>
              {paramsData ? (
                <TestStepRevamp
                  key={paramsData["tc_run_id"]}
                  setSplitReport={setSplitReport}
                  reportSplit
                  data={paramsData}
                  s_run_id={props.s_run_id}
                  p_id={props.p_id}
                />
              ) : (
                <TestStepRevamp
                  key={null}
                  setSplitReport={setSplitReport}
                  reportSplit
                  data={null}
                  s_run_id={props.s_run_id}
                  p_id={props.p_id}
                />
              )}
            </div>
          </SplitterPanel>
        </Splitter>
      )}
    </div>
  );
};

export default Extent;
