import "./SideBarRevamp.css";
import jewellogo from "../../../assets/Jewel.svg";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logout } from "../../../actions/auth";
import notificationSound from "../../../assets/notification-sound.mp3";

import {
  faCalendarDays,
  faChalkboardUser,
  faCircleChevronLeft,
  faCircleChevronRight,
  faCirclePlay,
  faFolderTree,
  faSignOut,
  faTrashCanArrowUp,
  faUser,
  faUserCircle,
  faUserGear,
  faUsersGear,
} from "@fortawesome/free-solid-svg-icons";
import {
  faHouse,
  faChartColumn,
  faUserLock,
  faToolbox,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";
import { expand } from "../../../actions/sideBar";
import { connect, useSelector } from "react-redux";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import SideBarItem from "./SideBarItem";
import CustomDialog from "../CustomDialog/CustomDialog";
import CustomDialogFooter from "../CustomDialog/CustomDialogFooter";
import httpService from "../../../services/http.service";
import NotificationDialog from "../Notification/NotificationDialog";
import { connectSocket } from "../../Helper/SocketHelper";
import { faBell } from "@fortawesome/free-regular-svg-icons";

const SideBar = (props) => {
  const role = useSelector((state) => state.auth.userProfile.role);
  const username = useSelector((state) => state.auth.userProfile.username);
  const [superAdmin, setSuperAdmin] = useState(false);
  const [openNotificationBar, setOpenNotificationBar] = useState(false);
  const [notificationsData, setNotificationsData] = useState(null);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const connectionNotification = useRef(null);
  const [markAllAsReadDisabled, setMarkAllAsReadDisabled] = useState(true);
  const params=useParams();

  const [access, setAccess] = useState([
    {
      icon: faHouse,
      module: "Home",
      route: "/home",
      matchModule: "/home",
      def_close: "Home",
      def_open: null,
    },
    {
      icon: faChartColumn,
      module: "Autolytics",
      route: "/autolytics",
      matchModule: "/autolytics",
      def_close: "Autolytics",
      def_open: null,
    },
    {
      icon: faToolbox,
      module: "TestPad",
      route: "/testpad/automation",
      matchModule: "/automation",
      children: [
        {
          icon: faCirclePlay,
          module: "ELab",
          route: "/testpad/ELab",
          matchModule: "/ELab",
          def_close: "ELab: Execution Lab for suites creation and execution",
          def_open: "Execution Lab for suites creation and execution",
        },
        {
          icon: faChalkboardUser,
          module: "ALab",
          route: "/testpad/ALab",
          matchModule: "/ALab",
          def_close:
            "ALab: Automation Lab for Automated tests creation and management",
          def_open:
            "Automation Lab for Automated tests creation and management",
        },
        {
          icon: faClipboardList,
          module: "MLab",
          route: "/testpad/MLab",
          matchModule: "/MLab",
          def_close: "MLab: Repository to create and mark manual testcases",
          def_open: "Repository to create and mark manual testcases",
        },
      ],
    },
    {
      icon: faTrashCanArrowUp,
      module: "Recycle Bin",
      route: "/recycle-bin",
      matchModule: "/recycle-bin",
      def_close: "Recycle Bin",
      def_open: null,
    },
    {
      icon: faUserGear,
      module: "Admin",
      route: "/admin",
      matchModule: "/admin",
      def_close: "Admin",
      def_open: null,
    },
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    if (role?.toString().toUpperCase() === "SUPER_ADMIN" && !superAdmin) {
      setAccess([
        ...access,
        {
          icon: faUsersGear,
          module: "Super Admin",
          route: "/super-admin",
          matchModule: "/super-admin",
          def_close: "Super Admin",
          def_open: null,
        },
      ]);
      setSuperAdmin(true);
    }
  }, [role]);

  const renderTooltip = (props, mod) => (
    <Tooltip id="info-tooltip" {...props}>
      {mod}
    </Tooltip>
  );

  const isAutolyticsNewReport=()=>{
    if(window.location.href.includes('autolytics/execution-report') || window.location.href.includes('autolytics/security-report') || window.location.href.includes('autolytics/teststep-report') || window.location.href.includes('autolytics/shared-report')){
      return true;
    }else{
      return false;
    }
   
  }

  const closeDialogHandler = () => {
    setOpenNotificationBar(false);
  };
  const createSideBarElement = (data, className, sidebarOpen) => {
    const tooltipContent = props.isOpen ? data.def_open : data.def_close;
    const shouldShowTooltip = !!tooltipContent;

    const isTabActive = window.location.hash
      .split("#")[1]
      .includes(data.matchModule);

    return (
      <div
        key={data.module}
        className={`iconDiv my-2 py-2 ${isTabActive ? "isActive" : ""} ${
          className ? className : ""
        }`}
      >
        <NavLink
          onClick={(e) => {
            (isTabActive && (!isAutolyticsNewReport())) && e.preventDefault();
          }}
          to={data.route}
          className={` ${isTabActive ? "isActive" : ""}`}
        >
          <div className="row  text-center ms-1 me-2 d-flex justify-content-center">
            <div className={`icon ${props.isOpen ? "col-3" : "col-12"}`}>
              {shouldShowTooltip ? (
                <OverlayTrigger
                  placement="right"
                  overlay={renderTooltip(props, tooltipContent)}
                >
                  <FontAwesomeIcon icon={data.icon} />
                </OverlayTrigger>
              ) : (
                <FontAwesomeIcon icon={data.icon} />
              )}
            </div>
            <div
              className={`icon-text ${
                props.isOpen
                  ? "col-md-9 col-sm-12 text-md-start text-center"
                  : "col-9 collapseSideBar"
              }`}
            >
              {shouldShowTooltip ? (
                <OverlayTrigger
                  placement="right"
                  overlay={renderTooltip(props, tooltipContent)}
                >
                  <div>{data.module}</div>
                </OverlayTrigger>
              ) : (
                <div>{data.module}</div>
              )}
            </div>
          </div>
        </NavLink>
      </div>
    );
  };

  const createSideBar = (array, className) => {
    const final = array.map((data, index) =>
      createSideBarElement(data, className)
    );
    return final;
  };
  const getNotifications = (username, action, type) => {
    httpService
      .getNotifications(username)
      .then((data) => {
        setMarkAllAsReadDisabled(false);
        setNotificationsData(data?.data);
        setNotificationsCount(data?.data?.unreadCount);
        if (action === "ws" && type === "new") {
          playNotificationSound();
        }
      })
      .catch(() => {
        setMarkAllAsReadDisabled(false);
      });
  };
  const playNotificationSound = () => {
    const audio = new Audio(notificationSound);
    audio.play();
  };
  const notifyClient = (e) => {
    if (e["body"]) {
      let wsBody = JSON.parse(e["body"]);
      let users = wsBody?.users;
      let type = wsBody?.type;
      let notificationType = wsBody?.notificationType;
      if (notificationType.toUpperCase() !== "WORKLOG") {
        notificationType.toUpperCase() !== "AGILECRAFTER" &&
          users?.some((user) => user === username) &&
          getNotifications(username, "ws", type);
      }
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getNotifications(username);
    connectSocket(
      "notification",
      "notification" + "/private",
      connectionNotification,
      notifyClient
    );
    return () => {
      connectionNotification?.current?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (props.isOpen) {
      document
        .getElementById("sideBar")
        .style.setProperty("width", "calc(9vh + 9vw)");

      document
        .getElementById("mainData")
        .style.setProperty("margin-left", "calc(9vh + 9vw)");
    } else {
      document
        .getElementById("sideBar")
        .style.setProperty("width", "calc(3.5vh + 3.5vw)");

      document
        .getElementById("mainData")
        .style.setProperty("margin-left", "calc(3.5vh + 3.5vw)");
    }
  }, [props.isOpen]);

  const expandCollapse = () => {
    props.dispatch(expand(!props.isOpen));
  };
  return (
    <>
      <div className="revamp-sideBar" id="sideBar">
        <div
          className="revamp-sideBarOpenClose"
          onClick={() => expandCollapse()}
        >
          <FontAwesomeIcon
            className="revamp-sideBarOpenCloseIcon"
            icon={props.isOpen ? faCircleChevronLeft : faCircleChevronRight}
          />
        </div>
        <div className="logodiv mt-2">
          <div className="row mx-auto d-flex justify-content-center">
            <div
              className={`${
                props.isOpen ? "col-3" : "col-12 px-0 text-center"
              }`}
            >
              <img
                className={`jewel-logo ${
                  props.isOpen ? "" : "d-block mx-auto"
                }`}
                src={jewellogo}
              />
            </div>
            <div
              className={`jewel-text col-9 ${
                props.isOpen ? "" : "collapseSideBar"
              }`}
            >
              Jewel
            </div>
          </div>
        </div>
        <hr className="mx-2 mb-0" style={{ color: "#A6A6A6" }} />
        <div
          style={{ cursor: "default" }}
          className={`mx-2 my-2 px-1 py-1 ${
            props.isOpen ? "card profileCard" : ""
          }`}
          // onClick={(e) => {
          //   setOpen(!open);
          // }}
        >
          {props.userProfile ? (
            <>
              <div>
                <Link
                  to="/profile"
                  className="d-flex userProfile flex-wrap justify-content-center"
                >
                  <div
                    className={`my-auto text-center ${
                      props.isOpen ? "mx-auto" : ""
                    }`}
                  >

                    <img src={props.userProfile.avatar?`${props.userProfile.avatar}&token=${localStorage.getItem("usertoken")}`:`https://api.dicebear.com/7.x/initials/svg?seed=${props.userProfile.firstName} ${props.userProfile.lastName}`} className="userSidebarAvatar" />

                  </div>
                  {props.isOpen ? (
                    <div className="profileDetails my-auto mx-auto py-2">
                      <div className="profileName text-center">
                        {props.userProfile.firstName}{" "}
                        {props.userProfile.lastName}
                      </div>
                      <div className="profileUsername px-1 text-center">
                        {props.userProfile.username}
                      </div>
                    </div>
                  ) : null}
                </Link>
                <div
                  className={`d-flex ${
                    !props.isOpen
                      ? "flex-column"
                      : "flex-row-reverse px-0 px-lg-2 justify-content-around"
                  }`}
                >
                  <div
                    className="logout my-2 "
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      props.dispatch(logout());
                    }}
                  >
                    <div key={"Logout"} className={`iconDiv  my-2 py-2`}>
                      <div className="row d-flex justify-content-center">
                        <div
                          className={`icon ${
                            props.isOpen ? "col-3" : "col-12"
                          }`}
                        >
                          {!props.isOpen ? (
                            <OverlayTrigger
                              placement="right"
                              overlay={renderTooltip(props, "Logout")}
                            >
                              <FontAwesomeIcon
                                icon={faSignOut}
                                className="d-flex  mt-2 notificationIcon"
                              />
                            </OverlayTrigger>
                          ) : (
                            <FontAwesomeIcon
                              icon={faSignOut}
                              className="notificationIcon"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="notification my-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setOpenNotificationBar(!openNotificationBar);
                    }}
                  >
                    <div className={`iconDiv  my-2 py-2`}>
                      <div className="row d-flex justify-content-center">
                        <div
                          className={`icon ${
                            props.isOpen ? "col-3" : "col-12"
                          }`}
                        >
                          <div style={{ position: "relative" }}>
                            <FontAwesomeIcon
                              icon={faBell}
                              className="notificationIcon"
                            />
                            {notificationsCount > 0 && (
                              <div className="notification-badge">
                                {notificationsCount}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="notification my-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/profile");
                    }}
                  >
                    <div className={`iconDiv  my-2 py-2`}>
                      <div className="row d-flex justify-content-center">
                        <div
                          className={`icon ${
                            props.isOpen ? "col-3" : "col-12"
                          }`}
                        >
                          <div style={{ position: "relative" }}>
                            <FontAwesomeIcon
                              icon={faUser}
                              className="notificationIcon"
                            />
                            {/* {notificationsCount > 0 && (
                              <div className="notification-badge">
                                {notificationsCount}
                              </div>
                            )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
        {/* {open ? (
          <div
            className="logout my-2 ms-2"
            onClick={() => {
              props.dispatch(logout());
            }}
          >
            <div key={"Logout"} className={`iconDiv  my-2 py-2`}>
              <div className="row ms-1 me-2 d-flex justify-content-center">
                <div className={`icon ${props.isOpen ? "col-3" : "col-12"}`}>
                  {!props.isOpen ? (
                    <OverlayTrigger
                      placement="right"
                      overlay={renderTooltip(props, "Logout")}
                    >
                      <FontAwesomeIcon icon={faSignOut} />
                    </OverlayTrigger>
                  ) : (
                    <FontAwesomeIcon icon={faSignOut} />
                  )}
                </div>
                <div
                  className={`icon-text ${
                    props.isOpen
                      ? "col-md-9 col-sm-12 text-md-start text-center"
                      : "col-9 collapseSideBar"
                  }`}
                >
                  Logout
                </div>
              </div>
            </div>
          </div>
        ) : null} */}
        <hr className="mx-2 mt-0" style={{ color: "#A6A6A6" }} />

        {/* <div className="sideBarElements">{createSideBar()}</div> */}
        <div className="parentSideBar">
          <div className="childSideBar">
            <div className="sideBarElements pb-5">
              <SideBarItem
                item={access}
                sideBar={createSideBar}
                sideBarElements={createSideBarElement}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mainData" id="mainData">
        <div>{props.children}</div>
      </div>
      <CustomDialog
        heading={"Notifications"}
        showDialog={openNotificationBar}
        align="right"
        showBackdrop={true}
        onClose={closeDialogHandler}
        width="40%"
        footer={
          <CustomDialogFooter
            onClose={closeDialogHandler}
            onlyCloseBtn={true}
          ></CustomDialogFooter>
        }
      >
        <NotificationDialog
          notificationsData={notificationsData}
          setOpenNotificationBar={setOpenNotificationBar}
          getNotifications={getNotifications}
          markAllAsReadDisabled={markAllAsReadDisabled}
        ></NotificationDialog>
      </CustomDialog>
    </>
  );
};

function mapStateToProps(state) {
  const { isOpen } = state.sideBar;
  const { userProfile } = state.auth;
  return {
    isOpen,
    userProfile,
  };
}

export default connect(mapStateToProps)(SideBar);
