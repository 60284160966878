import gempyp from "../../../../../src/assets/gempyp-logo.svg";
import gemjar from "../../../../../src/assets/gemjar-logo.svg";
import gemprf from "../../../../../src/assets/gemprf-logo.svg";

export const availableframeworks = [
    {
        logo: gempyp,
        name: "GemPYP",
        content: `Python based Test Automation Framework equipped with Rich Libraries for Reporting and multiple test executions.`,
    },
    {
        logo: gemjar,
        name: "GemJAR",
        content: `Java based Test Automation Framework equipped with Rich Libraries for Reporting and multiple test executions.`,
    },
    {
        logo: gemprf,
        name: "GemPRF",
        content: `Performance Related Test Framework equipped with Rich Libraries for Reporting and multiple test executions.`,
    },
];

export const availabletype = ["Scenario", "Scenario Outline"];
export const availablePypTypes = ["GEMPYP", "PYPREST", "DV"];
export const availableTestcaseKeys = {
    pyprest: [
        "api",
        "method",
        "body",
        "headers",
        "pre_variables",
        "post_variables",
        "expected_status_code",
        "legacy_expected_status_code",
        "key_check",
        "post_assertion",
        "poll_and_wait",
        "legacy_api",
        "legacy_method",
        "legacy_body",
        "legacy_headers",
        "subtestcases",
        "dependency",
        "authentication",
        "request_file",
        "before_file",
        "after_file",
    ],
    gempyp: ["dependency", "set", "path", "extra_args", "method"],
    dv: [
        "db_config_path",
        "source_conn",
        "target_conn",
        "source_sql",
        "target_sql",
        "tolerance",
        "keys",
        "dependency",
        "source_csv",
        "target_csv",
        "before_file",
        "after_file",
        "match_case",
        "column_map",
        "skip_column",
        "source_db",
        "target_db",
        "cut_out",
    ],
};
