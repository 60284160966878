import noTableData from "../../../assets/noTableData.svg";
const NoData = () => {
  return (
    <div className="emptyTable">
      <img
        style={{ height: "25rem" }}
        src={noTableData}
        alt="No Notification(s)"
      />
    </div>
  );
};

export default NoData;
