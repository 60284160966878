import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  faArrowUpRightFromSquare,
  faXmark,
  faTimes,
  faFolderOpen,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import { Column } from "primereact/column";
import { toast } from "react-toastify";
import * as DOMPurify from "dompurify";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useRef, useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { FilterMatchMode } from "primereact/api";
import { Knob } from "primereact/knob";
import knobPic from "../../assets/knobIcon.svg"
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  RadialLinearScale,
} from "chart.js";
import { Tooltip as Hover } from "primereact/tooltip";
import { ProgressBar } from "primereact/progressbar";
import { Timeline } from "primereact/timeline";
import { InputText } from "primereact/inputtext";
import { Doughnut, Bar, PolarArea } from "react-chartjs-2";
import { Col } from "react-bootstrap";
import Image from "../UI/Image/Image";
import _ from "lodash";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import { InputSwitch } from "primereact/inputswitch";
import ConvertTextToTimeDetails from "./TableConversions/ConvertTextToTimeDetails";
import { useNavigate } from "react-router-dom";



ChartJS.register(
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  Title,
  ChartDataLabels
);

export const ConvertTextToSeeMore = (
  header,
  val,
  showSeeMore,
  setShowSeeMore,
  maxValLength = 40
) => {
  val = typeof val === "string" ? val.trim() : val;
  let sanitizedVal =
    val || val === 0 ? (val === 0 ? 0 : DOMPurify.sanitize(val)) : null;
  if (val || val === 0) {
    if (val.length > maxValLength) {
      if (sanitizedVal || sanitizedVal === 0) {
        return (
          <>
            <Dialog
              blockScroll={true}
              draggable={false}
              header={header}
              visible={showSeeMore}
              onHide={(e) => {
                setShowSeeMore(false);
              }}
              onClick={(e) => e.stopPropagation()}
              onMaskClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              breakpoints={{ "960px": "75vw" }}
              style={{ width: "50vw" }}
              headerClassName="seeMoreHeader"
              dismissableMask
              footer={() => {
                return (
                  <>
                    <div
                      className="btn btn-danger mt-3 themeBtn"
                      onClick={() => {
                        setShowSeeMore(false);
                      }}
                    >
                      <FontAwesomeIcon
                        className="mx-2 tabIcon"
                        icon={faXmark}
                      />
                      Close
                    </div>
                  </>
                );
              }}
            >
              <div className="container seeMoreDialogContent">
                <span dangerouslySetInnerHTML={{ __html: sanitizedVal }} />
              </div>
            </Dialog>
            <div className="seeMoreWidth">
              <span
                dangerouslySetInnerHTML={{
                  __html: sanitizedVal.substring(0, maxValLength),
                }}
              />
              <b>...</b>
              <div className="d-flex justify-content-end seeMoreLink">
                <br />
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowSeeMore(true);
                  }}
                >
                  See More
                </span>
              </div>
            </div>
          </>
        );
      }
    } else {
      return (
        <>
          <div className="seeMoreWidth">
            {Array.isArray(val) && val?.length === 0 ? (
              "-"
            ) : (
              <span
                className={`${sanitizedVal === "VERIFIED" ? "pass-color boldText" : ""
                  } ${sanitizedVal === "UNVERIFIED" ? "fail-color boldText" : ""
                  }`}
                dangerouslySetInnerHTML={{
                  __html: sanitizedVal,
                }}
              />
            )}
          </div>
        </>
      );
    }
  } else {
    return "-";
  }
};

export const convertTextToFramework = (val, type) => {
  const name = val ? val.toLowerCase() : null;
  let image = null;
  try {
    image = name ? require(`../../assets/${name + "-logo.svg"}`) : null;
  } catch (err) {
    image = null;
  }
  const finalImage = image ? (
    <>
      <div className="my-2">
        <img src={image} style={{ width: "80px" }} alt={val} />
      </div>
    </>
  ) : null;

  return type === "table" ? (finalImage ? finalImage : val) : val;
};

export const convertExpectedTime = (expectedTime) => {
  let hr = Math.floor(expectedTime / 3600);
  let min = Math.floor((expectedTime % 3600) / 60);
  let sec = Math.ceil((expectedTime % 3600) % 60);
  let hrDisplay = hr > 0 ? hr + "h " : "";
  let minDisplay = min > 0 ? min + "m " : "";
  let secDisplay = sec > 0 ? sec + "s " : "";

  if (expectedTime > 0 && expectedTime < 60) {
    return secDisplay;
  } else if (expectedTime >= 60 && expectedTime < 3600) {
    return minDisplay + " " + secDisplay;
  } else if (expectedTime >= 3600) {
    return hrDisplay + " " + minDisplay;
  } else {
    return expectedTime + " sec(s)";
  }
};
// TO CONVERT EPOCH TIME TO DATE/DATE TIME/TIME FORMAT WHERE PARAMETERS ARE VALUE AND FORMAT TYPE (DATETIME/TIME/DATE)
export const convertEpochtoDate = (val, type) => {
  let convertedDate = null;
  if (type !== "duration") {
    convertedDate = val !== "-" && val ? new Date(val) : null;
  }
  let timeOptions = { hour: "numeric", minute: "numeric" };
  let abbrTZ = convertedDate
    ? "(" +
    convertedDate
      .toString()
      .match(/\(([A-Za-z\s].*)\)/)[1]
      .split(" ")
      .map(function (item) {
        return item[0];
      })
      .join("") +
    ")"
    : "-";
  switch (type) {
    case "datetime":
      // data.toLocaleString('en-us', { month: 'long', day: 'numeric', year: 'numeric' })
      return convertedDate
        ? convertedDate.toLocaleString("en-us", {
          month: "long",
          day: "numeric",
          year: "numeric",
        }) +
        " " +
        convertedDate.toLocaleTimeString() +
        " " +
        abbrTZ
        : "-";
    case "time":
      return convertedDate
        ? convertedDate.toLocaleTimeString() + " " + abbrTZ
        : "-";
    case "date":
      return convertedDate
        ? convertedDate.toLocaleDateString("en-us", {
          month: "long",
          day: "numeric",
          year: "numeric",
        }) +
        " " +
        abbrTZ
        : "-";
    case "timezone":
      return abbrTZ;
    case "timeHHMM":
      return convertedDate.toLocaleTimeString(undefined, timeOptions);
    case "date|time":
      let dateOptions = { day: "2-digit", month: "short", year: "numeric" };
      let timeHHMMOptions = {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      };
      let date = convertedDate?.toLocaleDateString(undefined, dateOptions);
      let time = convertedDate?.toLocaleTimeString(undefined, timeHHMMOptions);
      return date + " | " + time;
    case "duration":
      if (val !== "-") {
        let convertedStart = new Date(val["start_time"]);
        let convertedEnd = new Date(val["end_time"]);
        let duration = fancyTimeFormat2(
          (val["end_time"] - val["start_time"]) / 1000
        );
        let abbrTZ = convertedStart
          ? "(" +
          convertedStart
            .toString()
            .match(/\(([A-Za-z\s].*)\)/)[1]
            .split(" ")
            .map(function (item) {
              return item[0];
            })
            .join("") +
          ")"
          : "-";
        return (
          <>
            <div className="text-center card shadow-sm p-2">
              {/* <div className="p-2"> */}
              <span>
                <strong></strong>
                <i className="fontSizeGeneral">Start Time</i>
              </span>
              <hr className="m-1 p-0" />
              <div>
                <strong className="fontSizeGeneral">
                  {convertedStart.toLocaleString() + " " + abbrTZ}
                </strong>
              </div>

              <div className="d-flex justify-content-center">
                <div className="verticalDuration my-2 ms-3 me-2"></div>

                <div className=" my-auto fontSizeGeneral">{duration}</div>
              </div>

              <div>
                <strong className="fontSizeGeneral">
                  {convertedEnd.toLocaleString() + " " + abbrTZ}
                </strong>
              </div>
              <hr className="m-1 p-0" />
              <div>
                {" "}
                <strong>
                  <i className="fontSizeGeneral">End Time</i>
                </strong>{" "}
              </div>
              {/* </div> */}
            </div>
          </>
        );
      } else {
        return val;
      }
    default:
      return convertedDate
        ? convertedDate.toLocaleString("en-us", {
          month: "long",
          day: "numeric",
          year: "numeric",
        }) +
        " " +
        convertedDate.toLocaleTimeString() +
        " " +
        abbrTZ
        : "-";
  }
};

export const convertToBadge = (val) => {
  const badge = (
    <>

      <span className={`badgePill ${val?.toLowerCase()}-color px-3 py-1`}>
        <span className={`secureDast-${val?.toLowerCase()}-btn secure-doughnut-legend-span`}></span>
        {val}
      </span>
    </>
  );
  return badge;
};

// TO CONVERT TEXT TO DESCRIPTION
export const convertTextToDescription = (sentences,showSeeMore,setShowSeeMore) => {
  
  const maxWords = 5;

  const getTruncatedText = () => {
    const words = sentences?.join(' ').split(' ');
    if (words?.length > maxWords) {
      return words?.slice(0, maxWords)?.join(' ') + '...';
    }
    return words?.join(' ');
  };

  const sanitizedText = DOMPurify.sanitize(getTruncatedText());

  return (
    sentences?(
      <>
      <div>
        <span dangerouslySetInnerHTML={{ __html: sanitizedText }} />
        {sentences?.join(' ')?.split(' ')?.length > maxWords && (
          <span
            onClick={(e) => {
              e.stopPropagation();
              setShowSeeMore(true);
            }}
            style={{ color: 'blue', cursor: 'pointer', marginLeft: '5px' }}
          >
            See More
          </span>
        )}
      </div>

      <Dialog
        header="Description"
        visible={showSeeMore}
        onHide={() => setShowSeeMore(false)}
        style={{ width: '50vw' }}
      >
        <div className="seeMoreDialogContent">
          {sentences?.map((sentence, index) => (
            <p key={index}>{DOMPurify.sanitize(sentence)}</p>
          ))}
        </div>
      </Dialog>
    </>
    ):(
      <span>-</span>
    )
  );
};
// TO CONVERT TEXT TO STATUS PILLS
export const convertTexttoScore = (val) => {
  const scoreKnob = (
    <>
      <Hover position="top" target={`.knob-${val}`} />

      <Knob
        strokeWidth={8}
        size={80}
        value={val}
        max={999}
        className={`knob-${val}`}
        data-pr-tooltip={
          val < 600
            ? `Bad 😔`
            : val >= 600 && val < 750
              ? `Poor 🙁`
              : val >= 750 && val < 850
                ? `Fair 😐`
                : val >= 850 && val < 900
                  ? `Good 😄`
                  : val >= 900
                    ? `Excellent 🤩`
                    : null
        }
        // valueTemplate={`${

        // }`}
        valueColor={
          val < 600
            ? "#C21010"
            : val >= 600 && val < 750
              ? "#E64848"
              : val >= 750 && val < 850
                ? "#FD841F"
                : val >= 850 && val < 900
                  ? "#82CD47"
                  : val >= 900
                    ? "#379237"
                    : null
        }
        readOnly
      />
    </>
  );
  return scoreKnob;
};

export const ConvertTextToKnob=(val)=>{
  const scoreKnob = (
    <div className="knob-container" style={{ position: 'relative', display: 'inline-block' }}>
      <Knob
        strokeWidth={8}
        size={100}
        value={val}
        max={999}
        className={`knob-${val}`}
        data-pr-tooltip={val}
        showValue={false}
        valueColor={
          val < 600
            ? "#C21010"
            : val >= 600 && val < 750
              ? "#E64848"
              : val >= 750 && val < 850
                ? "#FD841F"
                : val >= 850 && val < 900
                  ? "#82CD47"
                  : val >= 900
                    ? "#379237"
                    : null
        }
        readOnly
      />
      <div
        className="knob-icon"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '24px', // Adjust the icon size as needed
          pointerEvents: 'none', // Allow clicks to pass through to the knob
        }}
      >
        <img src={knobPic}/>
      </div>
    </div>

  );
  return scoreKnob
}
// TO CONVERT TEXT TO PIECHART SCORE RADAR

export const ConvertTexttoChart = (
  val,
  type,
  ifTable,
  heading,
  Action,
  path,
  inputSwitchValue,
  setInputSwitchValue,
  chartRef,
  callback
) => {
   
  // const navigate=useNavigate();
  switch (type) {
    case "polar_chart":
      let option = {
        scales: {
          r: {
            ticks: {
              callback: function (value, index, ticks) {
                return !Number.isNaN(value) ? value : "";
              },
              display: false, // Remove vertical numbers
              maxTicksLimit: 5,
            },
            grid: {
              display: true, // Removes the circulair lines
            },
          },
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      };
      return (
        <>
          <div className="text-center fontSizeGeneral mb-3">
            <strong>{heading}</strong>
          </div>
          <div className="wrapper">
            <PolarArea data={val} options={option} />
          </div>
        </>
      );
    case "stacked_bar_chart":
      let option1 = {
        onClick: function (evt, element) {
          if (element.length > 0) {
            if (val && val["click"]) {
              Action["execution_report"](val["ids"][element[0].index]);
            }
          }
        },
        plugins: {
          title: {
            display: false,
            text: "Chart.js Bar Chart - Stacked",
          },
          datalabels: {
            display: false,
          },
          legend: {
            display: false,
            position: "top",
          },
        },
        maxBarThickness: ifTable ? 10 : 30,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
            ticks: {
              display: false,
            },
          },
          y: {
            stacked: true,
            ticks: {
              display: true,
            },
          },
        },
      };
      if (
        ["last 5 suite runs", "last 5 runs"].includes(heading.toLowerCase())
      ) {
        val["labels"]?.forEach((data, i) => {
          val["labels"][i] = convertEpochtoDate(data, "dateTime");
        })
      }




      return (
        <>
          <div
            className={`${path === "execution_report"
              ? "text-left extentHeading d-flex justify-content-between align-items-center"
              : "text-center"
              } fontSizeGeneral lexendFont extentHeading mb-3`}
          >
            {heading}
            {path === "execution_report" && (
              <div className="input-switch-execution">
                All{" "}
                <InputSwitch
                  className="mx-1"
                  style={{ transform: "scale(0.7)" }}
                  checked={inputSwitchValue}
                  onChange={(e) => {
                    setInputSwitchValue(e.value);
                  }}
                />{" "}
                Criteria
              </div>
            )}
          </div>
          <div className="wrapper">
            <Bar
              height={ifTable ? "80" : "0"}
              width={ifTable ? "110" : "0"}
              data={{
                datasets: [...val["datasets"]],
                labels: [...val["labels"]],
              }}
              options={option1}
            />
          </div>
        </>
      );
    case "horizontal_bar_chart":
      let option2 = {
        indexAxis: "y",
        maxBarThickness: 4,
        barPercentage: 0.3,
        categoryPercentage: 0.5,
        borderRadius: 2,
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
            border: {
              display: false,
            },
            ticks: {
              font: { size: 10.5, weight: "bold" },
              callback: function (val, index) {
                return this.getLabelForValue(val) + "%";
              },
            },
          },
          y: {
            offset: true,
            ticks: {
              font: { size: 10.5, weight: "bold" },
              callback: function (val, index) {
                if (
                  this.getLabelForValue(val).length >= 50 &&
                  this.getLabelForValue(val).length < 100
                ) {
                  return [
                    this.getLabelForValue(val).substring(0, 50),
                    this.getLabelForValue(val).substring(50),
                  ];
                } else if (this.getLabelForValue(val).length >= 97) {
                  return [
                    this.getLabelForValue(val).substring(0, 50),
                    this.getLabelForValue(val).substring(50, 97) + "...",
                  ];
                } else {
                  return this.getLabelForValue(val);
                }
              },
              display: true,
              crossAlign: "near",
              labelOffset: -14,
              mirror: true,
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
        },
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            yAlign: "top",
            xAlign: "left",
            callbacks: {
              label: function (val, index) {
                return val["formattedValue"] + "%";
              },
              title: function () { },
            },
          },
          datalabels: {
            display: false,
          },
        },
      };
      return (
        <>
          <div className="text-center fontSizeGeneral mb-3">
            <strong>{heading}</strong>
          </div>
          <div>
            <Bar height={230} data={val} options={option2} />
          </div>
        </>
      );
    case "doughnut_chart":
      let totaltc = 0;
      const value =
        val["datasets"] && val["datasets"][0] && val["datasets"][0]["data"]
          ? val["datasets"][0]["data"].forEach((data) => {
            totaltc += data;
          })
          : null;

      const finalchart = (
        <>
          <div className="text-center mb-3 fontSizeGeneral">
            <strong>
              {heading} {heading ? ":" + totaltc : null}
            </strong>
          </div>
          <div className={path === "dashboard" ? "doughnut-div-padding" : ""}>
            <Doughnut
              // redraw={true}
              // updateMode='resize'
              height={ifTable ? "80" : "0"}
              width={"0px"}
              legend={{ display: false }}
              options={{
                centerText: {
                  display: true,
                  text: `90%`,
                },
                responsive: true,
                maintainAspectRatio: path === "dashboard" ? true : false,
                plugins: {
                  legend: {
                    display: false,
                  },
                  datalabels: {
                    display: false,
                  },
                },
              }}
              data={val}
            />
            {path === "dashboard" && (
              <table className="doughnut-legend d-flex flex-column align-items-center justify-content-center">
                {val?.labels.map((label, index) => {
                  return (
                    <tr key={index} className="w-100 py-2 px-3 legend">
                      <td>
                        <span
                          className={`${label.toLowerCase()}-btn doughnut-legend-span`}
                        />
                      </td>
                      <td className="w-50 legend-label">
                        {_.capitalize(label)}
                      </td>
                      <td className="w-50 text-end doughnut-legend-val">
                        {val?.datasets[0]?.data[index]}
                      </td>
                    </tr>
                  );
                })}
              </table>
            )}
          </div>
          {/* <span className="text-center">        <strong>Total Testcases:</strong>      </span> */}
        </>
      );
      if (totaltc) {
        return finalchart;
      } else {
        return (
          <div className="card-subtitle mt-4 text-center text-muted">
            <h6>No Suite(s) Found</h6>
          </div>
        );
      }
    
      case "half_doughnut_chart":
        
      let totalhalftc = 0;
      const halfValue =
        val?.["datasets"]?.[0]?.["data"]
         && val?.["datasets"]?.[0]?.["data"].forEach((data) => {
            totalhalftc += data;
          })
         
          

      const finalHalfchart = (
        <>
          <div className="text-center mb-3 fontSizeGeneral">
            {/* <strong>
              {heading} {heading ? ":" + totalhalftc : null}
            </strong> */}
          </div>
          <div>
             <Doughnut
        ref={chartRef}
        height={ifTable ? 80 : 160}
        width={0}
        options={{
          rotation: -90,
          circumference: 180,
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: 'bottom',
              labels: {
                boxWidth: 10,
                padding:40,
                usePointStyle: true,
              },
            },
            datalabels: {
              display: false,
            },
          },
        }}
        onClick={(e)=>{
          const chart = chartRef.current;
  const elements = chart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, false);
  let label='';
if (elements.length) {
  const firstPoint = elements[0];
  label = chart.data.labels[firstPoint.index];
  


}   
callback(label,elements,heading)
        }}
        data={val}
      />
           
          </div>
          {/* <span className="text-center">        <strong>Total Testcases:</strong>      </span> */}
        </>
      );
      if (totalhalftc) {
        return finalHalfchart;
      } else {
        return (
          <div className="card-subtitle mt-4 text-center text-muted">
            <h6>No Data Found</h6>
          </div>
        );
      }
      
    default:
      break;
  }
};

//TO CONVERT TEXT TO SCREENSHOT
export const convertTexttoImage = (val, header) => {
  return <Image src={val} header={header} />;
};

export const convertToVulnerability = (value) => {
  if (value) {
    return <div>{value}</div>;
  }else{
    return "-"
  }
}

export const convertToTC = (value) => {
  if (!value) {
    return "-";
  }

  

  const data = Object.keys(value).map((key) => (
    <div>
      {(value[key] !== 0 && key.toLowerCase() !== "total") ||
        key.toLowerCase() === "pass" ||
        key.toLowerCase() === "fail" ? (
        <>
          <div className="row d-flex my-1">
            <div className="col-8 badgeStatusTimelineCol pe-0">
              <span
                className={`me-2 badgeStatusTimeline ${key.toLowerCase()}-btn`}
              ></span>
              <span className="tcKey">
                {key.at(0).toUpperCase() +
                  key.substring(1, key.length).toLowerCase()}
              </span>
            </div>
            <div className="col-4 badgeStatusTimelineCol tcValue">
              {value?.[key]}
            </div>
          </div>
        </>
      ) : null}
    </div>
  ));

  return data;
};
// TO CONVERT TEXT TO STATUS SCORE RADAR
export const convertTexttoStatus = (val, className, testcaseCount) => {
  const status = (
    <>
      {testcaseCount?.count && Object.keys(testcaseCount?.count).length > 0 && (
        <Hover target={`.btn-${testcaseCount?.id ? testcaseCount?.id : ""}`}>
          <div className="d-flex align-items-center">
            {Object.keys(testcaseCount?.count).map((testcase, index) => {
              return (
                <div key={index} className="d-flex align-items-center">
                  <span
                    className={`${testcase.toLowerCase()}-btn mx-2 btn-span`}
                    style={{ whiteSpace: "nowrap" }}
                  ></span>
                  <span style={{ whiteSpace: "nowrap" }}>
                    {_.capitalize(testcase)}: {testcaseCount?.count[testcase]}
                  </span>
                </div>
              );
            })}
          </div>
        </Hover>
      )}
      <div
        className={`btn btn-sm statusBtn btn-success ${val ? val?.toLowerCase() + "-btn" : ""
          } ${testcaseCount?.id ? "btn-" + testcaseCount?.id : ""} ${className ? className : ""
          } `}
      >
        {val}
      </div>
    </>
  );
  return val?status:(
    <span>-</span>
  )
};


export const convertTexttoLink = (val) => {
  return (
    val ? (
      <span>
        <Hover target=".reference" className="schedulerTooltip">
          View more
        </Hover>
        <a href={val} className="reference" target="_blank"><FontAwesomeIcon
          className="mx-2 tabIcon fs-6"
          icon={faArrowUpRightFromSquare}
        /></a>
      </span>
    ) : (
      <span>-</span>
    )
  )
}

export const convertTextToFolder = (val, subType) => {
  return (
    <span title={val.length > 50 && `${val}`}>
      {subType.toString().toLowerCase() === "folder" ||
        subType.toString().toLowerCase() === "crud_automationfolder" ? (
        <FontAwesomeIcon className="me-2 ms-1 tabIcon" icon={faFolderOpen} />
      ) : (
        <FontAwesomeIcon className="me-2 ms-1 tabIcon" icon={faFile} />
      )}
      {val.length > 50 ? <>{val.substring(0, 50) + " ..."}</> : <>{val}</>}
    </span>
  );
};
export const convertTexttoPills = (val) => {
  if (Array.isArray(val)) {
    const pills = val
      ? val.map((pill) => (
        <>
          <span className="pillBg mx-2 px-2 py-1">{pill}</span>
        </>
      ))
      : null;
    return pills;
  }

  const pills = <>
    <span className={`${val && 'pillBg'} mx-2 px-2 py-1`}>{`${val ? val : "-"}`}</span>
  </>

  return pills;
};
// TO CONVERT TEXT VALUE TO ACTION SO THAT IT CAN PERFORM SOME ACTION WHERE PARAMETERS ARE VALUE AND FUNCTION TO BE PERFORMED
export const convertTexttoAction = (val, type, fn, rowData, header, security) => {
  let pid = rowData && rowData["P ID"] ? rowData["P ID"]["value"] : null;
  const action = fn["customAction"] ? (
    <>{fn["customAction"](val, rowData)}</>
  ) : fn["createNewTab"] ? (
    <>
      <label title="View Report">
        <div
          className={`actionBtn`}
          onClick={() =>
            fn
              ? fn["createNewTab"](type,val, pid, rowData)
              : console.error(
                "No Function Passed For Action Button.Please pass and continue"
              )
          }
        >
          <FontAwesomeIcon
            className="mx-2 tabIcon"
            icon={faArrowUpRightFromSquare}
          />
        </div>
      </label>
    </>
  ) : (
    <>
      <label title="View Report">
        <div
          className={`actionBtn`}
          onClick={() =>
            fn
              ? fn[type](val, pid, rowData)
              : console.error(
                "No Function Passed For Action Button.Please pass and continue"
              )
          }
        >
          <FontAwesomeIcon
            className="mx-2 tabIcon"
            icon={faArrowUpRightFromSquare}
          />
        </div>
      </label>
    </>
  );
  return action;
};


// TO COPY DATA PASSED AS ARGUMENT FOR EVERY OS
export const copyToClipboard = (textToCopy) => {
  if (navigator.userAgent.match(/ipad|iphone/i)) {
    let textArea = document.createElement("textArea");
    textArea.value = textToCopy;
    document.body.appendChild(textArea);

    let range, selection;
    range = document.createRange();
    range.selectNodeContents(textArea);
    selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    textArea.setSelectionRange(0, 999999);
    document.execCommand("copy");
    document.body.removeChild(textArea);
    toast.info("Copied !", {
      position: "top-right",

      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  } else {
    navigator.clipboard.writeText(textToCopy).then(() =>
      toast.info("Copied !", {
        position: "top-right",

        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
    );
  }
};

// TO ENCODE PARAMS IN SHARED URL
export const encodeParam = (str) => {
  try {
    return window.btoa(unescape(encodeURIComponent(str)));
  } catch {
    return str;
  }
};

// TO DECODE THE PARAMS IN URL
export const decodeParam = (str) => {
  try {
    return decodeURIComponent(escape(window.atob(str)));
  } catch {
    return str;
  }
};

// TO CONVERT DATE FORMAT TO GENERIC FORMAT i.e. MM/DD/YYYY
export const convertToMMDDYYYY = (date) => {
  return date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
};

// TO CHECK IF JSON IS VALID OR NOT ARGUMENT SHOULD BE STRING
export const isValidJSON = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getInfoMessage = (data) => {
  if (data["operation"] && data["operation"].toLowerCase() === "success") {
    toast.info(data["message"], {
      position: "top-right",

      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }
};
//GET THE SUCCESS/FAILURE MESSAGE FROM API
export const getToastMessage = (data) => {
  const message =
    data["operation"] && data["operation"].toLowerCase() === "success"
      ? toast.success(data["message"], {
        position: "top-right",

        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
      : toast.error(data["message"], {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
  return message;
};

//GET THE ERROR MESSAGE FROM API IF OPERATION IS FAILED
export const getErrorMessage = (data) => {
  const message =
    data["operation"] && data["operation"].toLowerCase() === "failure"
      ? toast.error(data["message"], {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
      : null;
  return message;
};
export const getSuccessMessage = (data) => {
  const message =
    data["operation"] && data["operation"].toLowerCase() === "success"
      ? toast.success(data["message"], {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
      : null;
  return message;
};
// TO FIND DISTANCE BETWEEN TWO ELEMENTS WHERE X IS ELEMENT ONE AND Y IS ELEMENT TWO
export const distanceBetweenElements = (x, y) => {
  const distX = y.offsetLeft - x.offsetLeft;
  const distY = y.offsetTop - x.offsetTop;
  let distance = Math.sqrt(distX * distX + distY * distY);
  return distance;
};

// TO RENDER VALUE INSIDE DATATABLE AND THIS FUNCTION WILL BE CALLED INSIDE CREATECOLUMN FUNCTION WHERE PARAMETERS ARE ROW DATA,HEADER,ACTION IF ANY
export const RenderTable = (
  rowData,
  header,
  Action,
  ifcomponent,
  component,
  componentProps,
  rowClassName,
) => {
  const [showSeeMore, setShowSeeMore] = useState(false);
  const [showSeeMoreDescription, setShowSeeMoreDescription] = useState(false);
  const [openUnstableTcDialog, setOpenUnstableTcDialog] = useState(false);

  return (
    <>
      <div
        className={`${
          // ["status", "report type"].includes(header.toLowerCase())
          //   ? "text-center"
          //   : null

          rowData[header] &&
            rowData[header]["align"] &&
            rowData[header]["value"] !== "-"
            ? "text-" + rowData[header]["align"]
            : "text-left"
          } ${rowClassName ? rowClassName : ""}`}
      >
        {rowData[header] ? (
          rowData[header]["type"] === "date" ? (
            convertEpochtoDate(
              rowData[header]["value"],
              rowData[header]["subType"]
            )
          ) : rowData[header]["type"] === "status" ? (
            convertTexttoStatus(rowData[header]["value"])
          ): rowData[header]["type"] === "description" ? (
            convertTextToDescription(rowData[header]["value"],showSeeMoreDescription,setShowSeeMoreDescription)
          ) : rowData[header]["type"] === "link" ? (
            convertTexttoLink(rowData[header]["value"])
          ) : rowData[header]["type"] === "badge" ? (
            convertToBadge(rowData[header]["value"])
          ) : rowData[header]["type"] === "falsePositiveVariance" ? (
            rowData[header]["value"] ? (
              rowData[header]["value"][0]["classification"]
            ) : (
              "-"
            )
          ) : rowData[header]["type"] === "pills" ? (
            convertTexttoPills(rowData[header]["value"])
          ) : rowData[header]["type"] === "image" ? (
            convertTexttoImage(rowData[header]["value"], header)
          ) : rowData[header]["type"] === "logo" ? (
            convertTextToFramework(rowData[header]["value"], "table")
          ) : rowData[header]["type"] === "action" ? (
            convertTexttoAction(
              rowData[header]["value"],
              rowData[header]["subType"],
              Action,
              rowData,
              header,
            )
          ) : rowData[header]["type"] === "score" ? (
            convertTexttoScore(rowData[header]["value"])
          ) : rowData[header]["type"] === "tabs" ? (
            ConvertTexttoTabs(
              rowData[header]["value"],
              "Testcase(s)",
              openUnstableTcDialog,
              setOpenUnstableTcDialog
            )
          ) : rowData[header]["type"] === "timeDetails" ? (
            ConvertTextToTimeDetails(
              rowData[header]["value"]
            )
          ) : rowData[header]["type"] === "crud" ? (
            convertTexttoCrud(
              rowData[header]["value"],
              rowData[header]["subType"],
              Action,
              ifcomponent,
              component,
              componentProps,
              rowData
            )
          ) : rowData[header]["type"] === "chart" ? (
            <div className=" col-lg-12">
              {ConvertTexttoChart(
                rowData[header]["value"],
                rowData[header]["subType"],
                true,
                rowData[header]["heading"],
                Action
              )}
            </div>
          ) : (
            <span>
              {rowData[header]["value"] || rowData[header]["value"] === 0
                ? ConvertTextToSeeMore(
                  header,
                  rowData[header]["value"],
                  showSeeMore,
                  setShowSeeMore
                )
                : "-"}
            </span>
          )
        ) : (
          "-"
        )}
      </div>
    </>
  );
};
export const createFilters = (availableHeaders) => {
 
  let filters = {};
  availableHeaders.map((header) => {
    filters[header + ".value"] = { value: null, matchMode: FilterMatchMode.IN };
  });
  return filters;
};

export const createGlobalFilters = () => {
  let filters = {};
  filters["global"] = { value: null, matchMode: FilterMatchMode.CONTAINS };
  return filters;
};
// TO CREATE COLUMN INSIDE DATATABLE WHERE PARAMETERS ARE HEADERS,SORTABLE(BOOLEAN),REPORT DATA,CLASSNAME IF ANY, ACTION IF ANY
export const createColumns = (
  headers,
  sortable,
  filter,
  reportData,
  showSNo,
  className,
  Action,
  ifcomponent,
  component,
  componentProps,
  rowClassName
) => {
  let headersToShow = headers ? [...headers] : [];
  if (reportData?.length > 0 && showSNo && !headersToShow.includes("S No")) {
    headersToShow.unshift("S No");
    reportData.map((data, index) => {
      if (!data["S No"]) {
        data["S No"] = {
          sortValue: index + 1,
          type: "text",
          value: index + 1,
          align: "center",
        };
      }
    });
  }
  const columns = headersToShow.map((header, i) => {
    return (
      <Column
        filter={
          [
            "s no",
            "action",
            "reference",
            "execute",
            "testcase(s)",
            "culprit",
            "edit",
            "duration",
            "version control",
            "share via",
            "screenshot",
            "actions",
            "access",
            "scheduler",
            "edit step",
            "link testcase name",
            "flaky testcase(s)",
            "analysis",
            "instances"
          ].includes(header ? header.toLowerCase() : null)
            ? false
            : filter
        }
        sortable={sortable}
        filterField={header + ".value"}
        sortField={header + ".sortValue"}
        filterElement={(e) =>
          representativeFilterTemplate(e, reportData, header)
        }
        showFilterMatchModes={false}
        body={(e) =>
          RenderTable(
            e,
            header,
            Action,
            ifcomponent,
            component,
            componentProps,
            rowClassName,
          )
        }
        field={header}
        header={header}
        // filter
        headerClassName={`headercolumnsPrime px-2 text-center ${!["s.no", "status", "environment"].includes(header.toLowerCase())
          ? "minWidth"
          : null
          }`}
        className={`columnsPrime  ${className ? className : ""} `}
      // editor={(options) => textEditor(options)}
      />
    );
  });
  return columns;
};

const textEditor = (options) => {
  return (
    <InputText
      type="text"
      value={options.value}
      onChange={(e) => options.editorCallback(e.target.value)}
    />
  );
};

const filterTCSteps = (key, val, setCurrentFilter) => {
  setCurrentFilter(key);
  let allEle = document.getElementsByClassName("acc-header");
  let arr = Array.from(allEle);

  if (val > 0) {
    arr.map((acc) => {
      if (key !== "total") {
        if (!acc.classList.contains(key + "-status")) {
          acc.style.display = "none";
        } else {
          acc.style.display = "block";
        }
      } else {
        acc.style.display = "block";
      }
    });
  }
};
export const createTestCaseInfoCardsRevamp = (
  TestCaseData,
  setCurrentFilter,
  currentFilter
) => {
  const statusInfo = TestCaseData ? (
    <div className="d-flex justify-content-center align-items-center fontSizeGeneral">
      <div className="fw-bold me-2">Status : </div>
      {Object.entries(TestCaseData).map(([key, val]) => {
        return (
          <>
            <Hover position="bottom" target=".tc_status" />
            <div
              className={`${key.toLowerCase()}-btn mx-2 px-2 py-1 tc_status d-flex align-items-center justify-content-center ${currentFilter === key.toLowerCase() ? "highlightFilter" : ""
                }`}
              data-pr-tooltip={key}
              key={key.toLowerCase()}
              onClick={() =>
                filterTCSteps(key.toLowerCase(), val, setCurrentFilter)
              }
            >
              <span className="tc_status_span">{val}</span>
            </div>
          </>
        );
      })}
    </div>
  ) : null;

  return statusInfo;
};
export const createTestCaseInfoCards = (TestCaseData) => {
  const info = TestCaseData
    ? Object.keys(TestCaseData).map((testcase) => (
      <>
        <div className="col-lg-3 col-md-3 col-sm-4 col-6 mx-1 my-2">
          <div className={`card shadow-sm`}>
            <div className="my-3 d-flex">
              <div className={`cardHeader ms-3 `}>{testcase}</div>
              <div
                className={`${testcase.toLowerCase() + "-color "
                  } ms-auto me-3`}
              >
                {TestCaseData[testcase]}
              </div>
            </div>
          </div>
        </div>
      </>
    ))
    : null;
  return info;
};

export const itemTemplate = (data) => {
  return (
    <div>
      {Object.keys(data).map((key) => (
        <div className="px-2 text-center my-4 mx-1 fontSizeGeneral ">
          <b> {key ? key : null}</b>
          <ProgressBar
            className="mx-auto"
            color="rgba(255,126,124)"
            value={data && data[key] ? data[key] : 0}
          ></ProgressBar>
        </div>
      ))}
    </div>
  );
};

export const ConvertTexttoTabs = (
  val,
  heading,
  openUnstableTcDialog,
  setOpenUnstableTcDialog
) => {
  const finalReturn = (
    <>
      <div>
        <Dialog
          blockScroll={true}
          draggable={false}
          header="Unstable Testcase(s)"
          visible={openUnstableTcDialog}
          onHide={() => {
            setOpenUnstableTcDialog(false);
          }}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "25vw" }}
        >
          {itemTemplate(val)}
        </Dialog>
        <button
          className="btn-success btn themeBtn mx-2 p-2 mt-1"
          onClick={(e) => {
            setOpenUnstableTcDialog(true);
          }}
        >
          Show Unstable Testcase(s)
        </button>
      </div>
    </>
  );
  return finalReturn;
};

const createMultipleAction = (data) => {
  // const actions =
};
export const convertTexttoCrud = (
  val,
  type,
  fn,
  ifcomponent,
  component,
  componentProps,
  rowData
) => {
  //value (932) , type (edit_suite) , fn(action) : {edit_suite : addTC() , edit_suite_icon : faSquarePlus} , git_add : true ,rowData
  let p_id = rowData?.["P ID"]?.["value"];
  let frameworkVal = rowData?.["Framework"]?.["value"];
  

  if (ifcomponent === undefined) {
    ifcomponent = {};
  }
  if (ifcomponent[type]) {
    var MainComponent = component[type];
    var props = componentProps?.[type + "_props"]
      ? componentProps?.[type + "_props"]
      : {};
  }



  return (
    <>
      {ifcomponent && !ifcomponent[type] ? (
        fn && fn[type] ? (
          // <FontAwesomeIcon
          //   className="mx-2 tabIcon actionBtn"
          //   icon={fn[type + "_icon"]}
          //   onClick={(e) => fn[type](e, val, p_id, rowData, frameworkVal)}
          // />
          <>
            {fn[type + "_icon"]["iconName"] === "circle-play" ? (
              rowData["Executable"] && rowData["Executable"]["value"] ? (
                <FontAwesomeIcon
                  className="mx-2 tabIcon actionBtn"
                  icon={fn[type + "_icon"]}
                  onClick={(e) => fn[type](e, val, p_id, rowData, frameworkVal)}
                />
              ) : (
                <FontAwesomeIcon
                  className="mx-2 tabIcon disabledIcon"
                  title="Jar Creation in Progress"
                  icon={fn[type + "_icon"]}
                />
              )
            ) : (
              <FontAwesomeIcon
                className="mx-2 tabIcon actionBtn"
                icon={fn[type + "_icon"]}
                onClick={(e) => fn[type](e, val, p_id, rowData, frameworkVal)}
              />
            )}
          </>
        ) : (
          "-"
        )
      ) : (
        <>
          <MainComponent
            props={{
              ...props,
              val: val,
              s_id: rowData["Suite ID"] ? rowData["Suite ID"]["value"] : null,
              p_id: p_id,
              rowData: rowData,
            }}
          // edit={props.edit.editTestcase}
          // editIcon={props.editIcon}
          // val={val}
          />
        </>
      )}

      {/* </button> */}
    </>
  );

  // return val;
};
export const representativesItemTemplate = (data) => {
  let val =
    data?.value && typeof data["value"] === "string"
      ? data["value"].trim()
      : data?.value;
  let sanitizedVal =
    val || val === 0 ? (val === 0 ? 0 : DOMPurify.sanitize(val)) : null;

  const optionValue = data ? (
    data["type"] === "date" ? (
      convertEpochtoDate(data["value"], data["subType"])
    ) : data["type"] === "status" ? (
      convertTexttoStatus(data["value"])
    ) : data["type"] === "badge" ? (
      convertToBadge(data["value"])
    ) : data["type"] === "image" ? (
      convertTexttoImage(data["value"])
    ) : data["type"] === "score" ? (
      convertTexttoScore(data["value"])
    ) : data["type"] === "crud" ? (
      data["subType"] === "falseVariance" ? (
        convertTexttoStatus(data["value"], null)
      ) : data["subType"] === "timeline_tc" ? (
        convertToTC(data["value"])
      ): data["subType"] === "vulnerability" ||  data["subType"] === "TestcaseList" ? (
        convertToVulnerability(data["value"])
      ) : data["subType"] === "crud_automationTc" ? (
        convertTextToFolder(data["value"], data["subType"])
      ) : data["subType"] === "crud_automationFolder" ? (
        convertTextToFolder(data["value"], data["subType"])
      ) : (
        convertTexttoCrud(data["value"], data["subType"], null, null, null)
      )
    ) : data["type"] === "logo" ? (
      convertTextToFramework(data["value"], "filter")
    ) : data["type"] === "tabs" ? (
      ConvertTexttoTabs(data["value"], "Testcase(s)")
    ) : data["type"] === "timeDetails" ? (
      ConvertTextToTimeDetails(data["value"])
    ) : data["type"] === "pills" ? (
      convertTexttoPills(data["value"])
    ) : data["subType"] === "folder" ||
      data["subType"] === "testcase" ||
      data["subType"] === "requirement" ? (
      convertTextToFolder(data["value"], data["subType"])
    ) : data["type"] === "chart" ? (
      ConvertTexttoChart(data["value"], data["subType"], true, data["heading"]) // data["value"]["datasets"][0]["data"].toString()
    ) : data["type"] === "action" &&
      !(data["subType"] === "folder" || data["subType"] === "testcase") ? (
      <>
        <FontAwesomeIcon
          className="mx-2 tabIcon actionBtn"
          icon={faArrowUpRightFromSquare}
        />{" "}
        <span>{data["value"]}</span>
      </>
    ) : (
      <span>
        {data["value"] || data["value"] == 0 ? (
          data["value"].length > 50 ? (
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: sanitizedVal.substring(0, 50),
                }}
              />{" "}
              {data["value"].length > 50 ? "..." : null}
            </>
          ) : data["value"].toString().length > 0 ? (
            data["value"]
          ) : (
            "-"
          )
        ) : (
          "-"
        )}
      </span>
    )
  ) : null;

  return (
    <div className="p-multiselect-representative-option">
      <span className="image-text">{optionValue}</span>
    </div>
  );
};

let tempOptions = [
  {
    sortValue: 1,
    value: "Ioni Bowcher",
    type: "text",
  },
  {
    sortValue: 2,
    value: "Amy Elsnerr",
    type: "text",
  },
  {
    sortValue: 3,
    value: "Asiya Javayant",
    type: "text",
  },
  {
    sortValue: 4,
    value: "Xuxue Feng",
    type: "text",
  },
];
export const representativeFilterTemplate = (options, reportData, header) => {
  let availableOptions = [];
  let optionMap = {};


  reportData.map((data) => {
    if (data && data[header]) {
      availableOptions.push(data[header]);
    } else {
      availableOptions.push({
        value: null,
        sortValue: null,
        type: "text",
      });
    }
  });

  availableOptions.forEach((option) => {
    if (typeof option["value"] !== "object" && !optionMap[option["value"]]) {
      optionMap[option["value"]] = option;
    } else if (
      typeof option["value"] === "object" &&
      !optionMap[option["value"]]
    ) {
      optionMap[JSON.stringify(option["value"])] = option;
    }
  });

  let finalOptions = Object.values(optionMap);

  return (
    <>
      <div className="mb-3 text-center">
        <strong>{header} Filter(s): </strong>
      </div>
      <hr />
      {finalOptions?.length > 0 ? (
        <MultiSelect
          filter
          value={options.value}
          options={finalOptions}
          itemTemplate={representativesItemTemplate}
          onChange={(e) => {
            options.filterApplyCallback(e.value);
          }}
          optionLabel="value"
          placeholder="Select Option(s)"
          className="p-column-filter mb-2"
          maxSelectedLabels={0}
        />
      ) : (
        <div className="text-center">No Option(s) Found</div>
      )}
    </>
  );
};
export const customFunction = (value, filter) => {
  let mainValue =
    value && value["value"]
      ? value["subType"] === "datetime" ||
        value["subType"] === "time" ||
        value["subType"] === "date"
        ? convertEpochtoDate(value["value"], value["subType"])
        : value["value"]
      : " ";
  return filter && filter.length !== 0 ? filter.includes(mainValue) : true;
};

// export function useBlocker(blocker, when = true) {
//   const { navigator } = React.useContext(NavigationContext);
//   React.useEffect(() => {
//     if (!when) return;

//     const unblock = navigator.block((tx) => {
//       const autoUnblockingTx = {
//         ...tx,
//         retry() {
//           unblock();
//           tx.retry();
//         },
//       };

//       blocker(autoUnblockingTx);
//     });

//     return unblock;
//   }, [navigator, blocker, when]);
// }

export function useCustomPrompt(message, when = true) {
  // const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  // const [openConfirm, setOpenConfirm] = useState(false);

  // <Dialog
  //      blockScroll={true}

  //   header="Edit Tab Name"
  //   visible={openConfirmDialog}
  //   closable={false}
  //   breakpoints={{ "960px": "75vw" }}
  //   style={{ width: "36vw" }}
  // >
  //   <div className="btn btn-success themeBtn"
  //     onClick={() => {
  //       setOpenConfirmDialog(false);
  //       setOpenConfirm(false);
  //     }}>
  //     Stay
  //   </div>

  //   <div className="btn btn-outline-success themeBtn"
  //     onClick={() => {
  //       setOpenConfirmDialog(false);
  //       setOpenConfirm(true);
  //     }}>
  //     Leave
  //   </div>
  // </Dialog>

  const blocker = React.useCallback(
    (tx) => {
      let refreshButton = document.getElementsByClassName(
        "p-highlight autoRefresh"
      );

      if (window.confirm(message)) {
        if (refreshButton && refreshButton.length) {
          Array.from(refreshButton).forEach((ele) => {
            ele.click();
          });
        }

        tx.retry();
      }
    },
    [message]
  );
}
window.addEventListener("resize", () => {
  let obj = ChartJS.instances;
  Object.keys(obj).forEach((chart) => {
    obj[chart].update();
  });
});

// FANCY TIME FORMAT
export const fancyTimeFormat = (duration) => {
  // Hours, minutes and seconds
  let hrs = ~~(duration / 3600);
  let mins = ~~((duration % 3600) / 60);
  let secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
};

export const OBJtoXML = (obj) => {
  let xml = "";
  for (let prop in obj) {
    xml += obj[prop] instanceof Array ? "" : "<" + prop + ">";
    if (obj[prop] instanceof Array) {
      xml += "<" + prop + ">";

      for (let array in obj[prop]) {
        xml += "<" + prop.substring(0, prop.length - 1) + ">";
        xml += OBJtoXML(new Object(obj[prop][array]));
        xml += "</" + prop.substring(0, prop.length - 1) + ">";
      }
      xml += "</" + prop + ">";
    } else if (typeof obj[prop] == "object") {
      xml += OBJtoXML(new Object(obj[prop]));
    } else {
      xml += obj[prop];
    }
    xml += obj[prop] instanceof Array ? "" : "</" + prop + ">";
  }
  xml = xml.replace(/<\/?[0-9]{1,}>/g, "");
  return xml;
};
export const createKeyValueInputs = (
  key,
  index,
  type,
  keyHeaders,
  setKeyHeaders,
  testcaseOptions
) => {
  return (
    <div className={` ${index ? "mt-2" : ""}`}>
      <div className="row align-items-center">
        <Col md={3} sm={3}>
          <Dropdown
            filter
            className="inputHeight w-100"
            value={key.key}
            name="key"
            options={testcaseOptions}
            onChange={(e) =>
              handleKeyValueChange(e, index, keyHeaders, setKeyHeaders)
            }
            placeholder="Select Key"
          // editable
          />
        </Col>
        <Col md={8} sm={8}>
          <div className="form-floating mt-2 mt-md-0">
            <InputText
              type="text"
              name="value"
              className="form-control inputHeight"
              value={key.value}
              placeholder="Value"
              onPaste={(e) =>
                handleKeyValueChange(e, index, keyHeaders, setKeyHeaders)
              }
              onChange={(e) =>
                handleKeyValueChange(e, index, keyHeaders, setKeyHeaders)
              }
            />
            <label>Value</label>
          </div>
        </Col>
        <Col md={1} sm={1} className="text-center">
          <FontAwesomeIcon
            icon={faTimes}
            className="removeItem"
            onClick={(e) => removeField(index, keyHeaders, setKeyHeaders)}
          />
        </Col>
      </div>
    </div>
  );
};
export const removeField = (index, keyHeaders, setKeyHeaders) => {
  let values = [];
  values = keyHeaders;
  values.splice(index, 1);
  setKeyHeaders([...values]);
};
export const addFormField = (type, keyHeaders, setKeyHeaders) => {
  setKeyHeaders([...keyHeaders, { key: "", value: "" }]);
};

export const mapKeyHeaders = (config) => {
  const head = Object.keys(config)
    .filter(key => key !== "environment" && key !== "mode")
    .map(key => ({
      key: key?.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" "),
      value: config?.[key]
    }));

  return head;
}

export const createHeadersKeyValueInputs = (
  keyHeaders,
  setKeyHeaders,
  testcaseOptions
) => {
  const headers = keyHeaders.map((key, idx) =>
    createKeyValueInputs(
      key,
      idx,
      "headers",
      keyHeaders,
      setKeyHeaders,
      testcaseOptions
    )
  );
  return headers;
};
export const handleKeyValueChange = (
  event,
  index,
  keyHeaders,
  setKeyHeaders
) => {
  let values = [];
  values = keyHeaders;

  values[index][event.target.name] = event.target.value;

  setKeyHeaders([...values]);
};

export const logPageTitleForGA = (pageTitle) => {
  if (document.location.href.includes("https://jewel.gemecosystem.com")) {
    window.dataLayer = window.dataLayer || [];

    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("event", "page_view", {
      page_title: pageTitle,
    });
  }
};

export const displayValueTemplate = (valueexpect, valueexecute, percent) => {
  return (
    <React.Fragment>
      {/* <span className="ms-1"> {valueexecute}</span>/<b>{valueexpect}</b>{" "} */}
      {/* <b className="ms-1">({percent}%)</b> */}
    </React.Fragment>
  );
};
export const createProgressBar = (val) => {
  const mainVal = Math.round((val["executed"] / val["expected"]) * 100);

  return (
    <div className="p-3">
      <div className="text-center mb-4">
        {/* <span className="ms-1">
          {" "}
          <b>{val["executed"]}</b>
        </span> */}
        {/* /<b>{val["expected"]}</b>  */}
        <b className="ms-1">Status : </b>
        <span>{mainVal}%</span>
      </div>
      <div className="custom-progress-bar">
        <ProgressBar
          value={mainVal}
          showValue="false"
          unit="%"
          className="custom-progressbar shiny-animation"
          color="#ff7e7c"
          displayValueTemplate={() =>
            displayValueTemplate(val["expected"], val["executed"], mainVal)
          }
        ></ProgressBar>
      </div>
      <div className="d-flex mt-4 justify-content-between">
        <div>
          <strong className="execution-headers">Executed</strong>
          <div className="mt-2">{val["executed"]}</div>
        </div>
        <div>
          <strong className="execution-headers">Expected</strong>
          <div className="mt-2">{val["expected"]}</div>
        </div>
      </div>
    </div>
  );
};
export const customizedMarker = (item) => {
  return <span className={`custom-marker  `}>{convertTexttoStatus(item)}</span>;
};
export const createexpectedStatus = (val) => {
  const finalres = (
    <>
      <Timeline
        value={[val["current"], val["expected"]]}
        layout="horizontal"
        align="bottom"
        marker={customizedMarker}
        content={(item, i) => {
          if (i === 0)
            return (
              <>
                <div className="ms-2 progressText">
                  <strong>Current</strong>
                </div>
              </>
            );
          return (
            <>
              <div className="ms-2 progressText">
                <strong>Expected</strong>
              </div>
            </>
          );
        }}
      />
    </>
  );
  return finalres;
};
export const fancyTimeFormat2 = (d) => {
  d = Number(d);
  let h = Math.floor(d / 3600);
  let m = Math.floor((d % 3600) / 60);
  let s = Math.ceil((d % 3600) % 60);
  let hDisplay = h > 0 ? h + "h " : "";
  let mDisplay = m > 0 ? m + "m " : "";
  let sDisplay = s > 0 ? s + "s " : "";

  return hDisplay + mDisplay + sDisplay;
};
export const loadingData = (data) => {
  return (
    <>
      <div className="w-100 text-center">
        <table className="w-100 ">
          <tbody>
            <tr>
              <td className="align-middle">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <hr />
                <div className="typewriter">{data}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
export const convertEpochtoYYYYMMDD = (epoch) => {
  let date = new Date(epoch);

  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  return year + "-" + month + "-" + day;
};
export const toCamelCase = (str) => {
  return (" " + str)
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, function (match, chr) {
      return chr.toUpperCase();
    });
};
export const convert24hrto12hrformat = (time) => {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
};
export const toCapitalizeFirstChar = (str) => {
  const words = str.split(" ");

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }

  return words.join(" ");
};

export const createExpandedRowData = (data) => {
  const finalTable = data["headers"].map((head, i) => (
    <>
      <tr className="testcaseTablerow">
        <td className="testcaseTableheader ps-4 w-25 table-column ">{head}</td>

        <td className="w-50 text-center table-column">
          {data?.["data"]?.[0]?.[head]?.["value"]
            ? data?.["data"]?.[0]?.[head]?.["type"] === "date"
              ? new Date(data["data"][0][head]["value"]).toDateString()
              : data["data"][0][head]["value"]
            : "-"}
        </td>
      </tr>
    </>
  ));

  return finalTable;
};

export const milisecondToTime = (ms) => {
  const days = Math.floor(ms / (24 * 60 * 60 * 1000));
  const daysms = ms % (24 * 60 * 60 * 1000);
  const hours = Math.floor(daysms / (60 * 60 * 1000));
  const hoursms = ms % (60 * 60 * 1000);
  const minutes = Math.floor(hoursms / (60 * 1000));
  const minutesms = ms % (60 * 1000);
  const sec = Math.floor(minutesms / 1000);

  return days
    ? days + (days > 1 ? " days" : "day")
    : hours
      ? hours + (hours > 1 ? " hours" : " hour")
      : minutes
        ? minutes + (minutes > 1 ? " minutes" : " minute")
        : sec
          ? sec + (sec > 1 ? "secs" : "sec")
          : " few";
};

