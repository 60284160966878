import {
  copyToClipboard,
  createTestCaseInfoCardsRevamp,
  getErrorMessage,
  convertTexttoImage,
} from "../../Helper/Helper";
import { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionTab } from "primereact/accordion";
import {
  faSquareArrowUpRight,
  faCopy,
  faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import cross from "../../../assets/cross.svg";
import "./TestStep.css";
import { useEffect } from "react";
import httpService from "../../../services/http.service";
import NoAccess from "../../UI/NoAccess/NoAccess";
import SeeMoreText from "../../UI/SeeMoreText/SeeMoreText";
import { Galleria } from "primereact/galleria";
import FailedSuiteEdit from "./FailedSuiteEdit";
import TCSubSteps from "./TCSubSteps";
import { Skeleton } from "primereact/skeleton";
import { handleToastErrorCatch } from "../../Helper/ToastHelper";
import { connectSocket } from "../../Helper/SocketHelper";
const TestStepRevamp = ({
  data,
  shared,
  tc_run_id,
  newTab,
  filterMain,
  reportSplit,
  setSplitReport,
  s_run_id,
  p_id,
}) => {
  const [filters, setFilters] = useState({});
  const [dataMain, setDataMain] = useState(data ? data : null);
  const [noAccess, setNoAccess] = useState(false);
  const [activeIndex, setActiveIndex] = useState([]);
  const galleria = useRef(null);
  const [images, setImages] = useState(null);
  const [loadThumbnail, setLoadThumbnail] = useState(true);
  const [currentTc_run_id, setCurrentTc_run_id] = useState(
    data ? data["tc_run_id"] : null
  );
  const connection = useRef(null);
  const [currentFilter, setCurrentFilter] = useState(null);
  const responsiveOptions = [
    {
      breakpoint: "1500px",
      numVisible: 5,
    },
    {
      breakpoint: "1024px",
      numVisible: 3,
    },
    {
      breakpoint: "768px",
      numVisible: 2,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
    },
  ];

  useEffect(() => {
    if (data) {
      connectSocket("execution", p_id + "/private", connection, notifyClient);
    }
    return () => {
      connection?.current?.unsubscribe();
    };
  }, []);

  const notifyClient = (e) => {
    if (e["body"]) {
      let ifRefresh = JSON.parse(e["body"]);
      if (
        ifRefresh[currentTc_run_id] ||
        ifRefresh["VARIANCE"]?.toString() === p_id ||
        ifRefresh[s_run_id]
      ) {
        httpService
          .getTestStepReportData(currentTc_run_id)
          .then((mainData) => {
            getErrorMessage(mainData);
            setDataMain(mainData?.data);
          })
          .catch((err) => {
            if (
              err?.response?.data?.operation?.toLowerCase() === "info" &&
              err?.response?.data?.suboperation
            ) {
              setNoAccess(true);
            } else {
              handleToastErrorCatch(err);
            }
          });
      }
    }
  };

  useEffect(() => {
    let temp = [];
    dataMain?.data?.forEach((ele, i) => {
      temp[i] = i;
    });
    setActiveIndex(temp);
    let imagesGallery = [];
    dataMain?.gallery.forEach((ele, i) => {
      let obj = {};
      obj["image"] = Object.values(ele).toString();
      obj["imageName"] = Object.keys(ele).toString();
      imagesGallery[i] = obj;
    });

    setImages(imagesGallery);
  }, [dataMain]);

  useEffect(() => {
    if (tc_run_id) {
      httpService
        .getTestStepReportData(tc_run_id)
        .then((mainData) => {
          getErrorMessage(mainData);
          setDataMain(mainData?.data);
          setCurrentTc_run_id(tc_run_id);
        })
        .catch((err) => {
          if (
            err?.response?.data?.operation?.toLowerCase() === "info" &&
            err?.response?.data?.suboperation
          ) {
            setNoAccess(true);
          } else {
            handleToastErrorCatch(err);
          }
        });
    }
  }, [tc_run_id]);

  const itemTemplate = (item) => {
    let authUrl = item.image + "&token=" + localStorage.getItem("usertoken");

    return (
      <img
        src={authUrl}
        alt={item.imageName}
        style={{ width: "100%", display: "block" }}
      />
    );
  };
  const caption = (item) => {
    return (
      <>
        <div className=" text-center boldText ">
          <i> {item.imageName}</i>
        </div>
      </>
    );
  };

  const accordHeader = (step) => {
    return (
      <div className="row w-100 align-items-center d-flex">
        <div
          className="col-lg-8 col-12 acc-heading acc-headingName"
          style={{ userSelect: "all" }}
        >
          {step["STEP NAME"]["value"]}
        </div>
        <div className={`acc-heading col-lg-4 py-1 col-12`}>
          <FailedSuiteEdit
            props={{
              val: step?.STATUS?.value?.toUpperCase(),
              rowData: step,
              level: "teststep",
              s_run_id: s_run_id,
              tc_run_id: newTab ? tc_run_id : data?.["tc_run_id"],
            }}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!currentFilter) {
      return;
    }
    if (currentFilter === "total") {
      const allIndexes = Array.from(
        { length: dataMain?.data?.length },
        (_, i) => i
      );
      setActiveIndex(allIndexes);
      return;
    }

    var elements = document.querySelectorAll(".acc-header.p-accordion-header");
    var elementsArray = Array.from(elements);

    let filterArr = [];
    elementsArray.forEach(function (element, i) {
      if (element.classList.contains(currentFilter + "-status")) {
        filterArr.push(i);
      }
    });

    setActiveIndex(filterArr);
  }, [currentFilter]);

  const expandAll = () => {
    var elements = document.querySelectorAll(".acc-header.p-accordion-header");
    var elementsArray = Array.from(elements);
    let excludeNonFilteredAcc = [];

    if (currentFilter === "total" || !currentFilter) {
      const allIndexes = Array.from(
        { length: dataMain?.data?.length },
        (_, i) => i
      );
      setActiveIndex(allIndexes);
      return;
    }

    elementsArray.forEach(function (element, i) {
      if (!element.classList.contains(currentFilter + "-status")) {
        excludeNonFilteredAcc.push(i);
      }
    });

    const allIndexes = Array.from(
      { length: dataMain?.data?.length },
      (_, i) => i
    );
    let removedArray = allIndexes.filter(function (ele) {
      return !excludeNonFilteredAcc.includes(ele);
    });
    setActiveIndex(removedArray);
  };

  const collapseAll = () => {
    setActiveIndex([]);
  };

  const createAccordion = (header, data) => {
    const acc = data.map((step, i) => (
      <AccordionTab
        header={accordHeader(step)}
        className={`acc-header ${step?.STATUS?.value?.toLowerCase()}-status filter-accordion`}
        tabIndex={i}
      >
        <div className="acc-steps">
          <div className="row d-flex">
            {step?.["STEP DESCRIPTION"]?.["value"] && (
              <div className="col-lg-8 col-12">
                <div className="boldText mb-2 acc-desc-heading">
                  STEP DESCRIPTION
                </div>
                <div className="acc-desc">
                  {step["STEP DESCRIPTION"]["value"] &&
                    step["STEP DESCRIPTION"]["value"].length > 0 ? (
                    <SeeMoreText
                      text={step["STEP DESCRIPTION"]["value"]}
                      maxLimit={50}
                    />
                  ) : ("-")}
                </div>
              </div>
            )}
            {step?.SCREENSHOT?.value ? (
              <div
                className={`${step?.["STEP DESCRIPTION"]?.["value"]
                  ? "col-lg-4 justify-content-center col-12"
                  : "ms-2"
                  }`}
              >
                {convertTexttoImage(
                  step?.["SCREENSHOT"]?.["value"],
                  "SCREENSHOT"
                )}
              </div>
            ) : null}
            {step?.["ATTACHMENT"]?.["value"] && (
              <div className="col-md-8 col-12 mt-2">
                <div className="boldText mb-2 acc-desc-heading">ATTACHMENT</div>
                <button
                  title="Open file in new tab"
                  className="btn btn-success themeBtnOutline"
                  onClick={(e) => {
                    window.open(
                      window.location.origin +
                      "/#/file-viewer?url=" +
                      step["ATTACHMENT"]["value"],
                      "_blank"
                    );
                  }}
                >
                  Result File
                  <FontAwesomeIcon
                    className="ms-2"
                    icon={faArrowUpRightFromSquare}
                  />
                </button>
              </div>)}
          </div>
          {Object.keys(step).map((stepData) => {
            return (
              <div className="row d-flex">
                {![
                  "S No",
                  "STATUS",
                  "STEP NAME",
                  "STEP DESCRIPTION",
                  "EDIT_ICON",
                  "ICON",
                  "ISCLICKABLE",
                  "VARIANCEID",
                  "PRODUCT TYPE",
                  "REASON",
                  "ID",
                  "ATTACHMENT",
                ].includes(stepData) && (
                    <>
                      {step?.[stepData]?.["type"] === "text" &&
                        step?.[stepData]?.["value"] ? (
                        <div className="col-md-8 col-12 mt-2">
                          <div className="boldText mb-2 acc-desc-heading">
                            {stepData}
                          </div>
                          <div>
                            <SeeMoreText
                              text={step[stepData]["value"]}
                              maxLimit={100}
                            />
                          </div>
                        </div>
                      ) : null}
                    </>)}
              </div>
            );
          })}
          <div className="py-2">
            {step?.["SUB_STEPS"] && (
              <TCSubSteps s_run_id={s_run_id} step={step} />
            )}
          </div>
        </div>
      </AccordionTab>
    ));

    return acc;
  };

  return (
    <>
      {noAccess ? (
        <NoAccess />
      ) : dataMain ? (
        <div className={`container ms-0`}>
          <div className={`row ${shared || newTab ? "" : "mb-5"}`}>
            <div className="col-12">
              {reportSplit && (
                <div>
                  {" "}
                  <img
                    alt="Close"
                    onClick={() => {
                      document.getElementsByClassName(
                        "p-splitter-gutter"
                      )[0].style.backgroundColor = "transparent";
                      document.getElementsByClassName(
                        "p-splitter-gutter-handle"
                      )[0].style.backgroundColor = "transparent";
                      setSplitReport(false);
                      connection?.current?.unsubscribe();
                    }}
                    src={cross}
                    className="xmark"
                  />
                </div>)}
            </div>
          </div>
          {reportSplit || newTab ? (
            <div>
              <div
                className={` reportHead d-flex align-items-center ${shared || newTab ? "" : "ms-3 w-100 mt-3"
                  }`}
              >
                {!shared && (
                  <>
                    <div className={`d-flex ${newTab ? " " : ""} mt-2`}>
                      <label title="Copy Report Link">
                        <div
                          className={`btn-sm${newTab ? " " : "ms-3"
                            } btn-success me-2 reportIcons teststep`}
                          onClick={() => {
                            copyToClipboard(
                              window.location.origin +
                              "/#/autolytics/teststep-report?tc_run_id=" +
                              dataMain["tc_run_id"] +
                              "&p_id=" +
                              p_id +
                              "&filters=" +
                              JSON.stringify(filters)
                            );
                          }}
                        >
                          <FontAwesomeIcon
                            className="mx-2 tabIcon"
                            icon={faCopy}
                          />
                        </div>
                      </label>
                      <label title="Open in New Window">
                        <div
                          className="btn-sm btn-success me-2 reportIcons teststep"
                          onClick={() => {
                            window.open(
                              window.location.origin +
                              "/#/autolytics/teststep-report?tc_run_id=" +
                              dataMain["tc_run_id"] +
                              "&p_id=" +
                              p_id +
                              "&filters=" +
                              JSON.stringify(filters),
                              "_blank"
                            );
                          }}
                        >
                          <FontAwesomeIcon
                            className="mx-2 tabIcon"
                            icon={faSquareArrowUpRight}
                          />
                        </div>
                      </label>
                    </div>
                    <div className="ms-auto me-5">
                      {dataMain?.["metaData"]
                        && createTestCaseInfoCardsRevamp(
                          dataMain?.["metaData"]?.[2],
                          setCurrentFilter,
                          currentFilter
                        )}
                    </div>
                  </>)}
              </div>
              <div
                className={`reportHead d-flex ${shared || newTab ? "" : "w-100 mt-3"
                  }`}
              >
                <div className={newTab ? `mt-3` : `ms-2 ps-2 my-2`}>
                  <strong
                    className={
                      newTab
                        ? `ms-2 fontSizeHeadGeneral`
                        : `fontSizeHeadGeneral`
                    }
                  >
                    Test Step Report :{" "}
                    {dataMain?.["metaData"]?.[0]?.["TESTCASE NAME"]}
                  </strong>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div
                className={`reportHead d-flex mt-4 mb-4 expandFullDataTable`}
              >
                <div className="ms-2">
                  <strong
                    className={
                      newTab
                        ? `ms-5 fontSizeHeadGeneral`
                        : `fontSizeHeadGeneral`
                    }
                  >
                    Test Step Report :{" "}
                    {dataMain?.["metaData"]?.[0]?.["TESTCASE NAME"]}
                  </strong>
                </div>
                <div className="ms-auto">
                  {dataMain?.["metaData"]
                    && createTestCaseInfoCardsRevamp(
                      dataMain?.["metaData"]?.[2],
                      setCurrentFilter,
                      currentFilter
                    )}
                </div>
              </div>
            </div>
          )}

          {dataMain?.gallery?.length > 0 || dataMain?.data?.length > 0 ? (
            <div className={`d-flex ${newTab || reportSplit ? "ms-2" : ""}`}>
              {dataMain?.gallery?.length > 0 ? (
                <div
                  className="btn btn-primary mt-2 ms-2 themeBtn justify-content-start"
                  onClick={() => {
                    galleria.current.show();
                  }}
                >
                  <i className="pi pi-images"></i> Screenshots
                </div>
              ) : (
                <div></div>
              )}
              <div className="d-flex ms-auto me-4">
                <div
                  className="btn btn-primary mt-2 ms-2 themeBtn d-flex align-items-center"
                  onClick={() => {
                    expandAll();
                  }}
                >
                  <span>Expand All</span> <i className="pi pi-plus ms-1"></i>
                </div>
                <div
                  className="btn btn-primary mt-2 ms-2 themeBtn d-flex align-items-center"
                  onClick={() => {
                    collapseAll();
                  }}
                >
                  <span>Collapse All</span> <i className="pi pi-minus ms-1"></i>
                </div>
              </div>
            </div>
          ) : null}
          <Galleria
            ref={galleria}
            value={images}
            responsiveOptions={responsiveOptions}
            numVisible={9}
            style={{ maxWidth: "70%" }}
            circular
            fullScreen
            showItemNavigators
            showThumbnailNavigators={false}
            item={itemTemplate}
            caption={caption}
          />
          <div
            className={`mt-4 ${shared || newTab
              ? "expandFullDataTable mb-4"
              : "pb-4 ps-2 ms-2 expandDataTable"
              }`}
          >
            {dataMain && (
              <div className="accordionSteps w-100">
                <Accordion
                  multiple
                  activeIndex={activeIndex}
                  onTabChange={(e) => setActiveIndex(e.index)}
                >
                  {createAccordion(dataMain["headers"], dataMain["data"])}
                </Accordion>
              </div>)}
          </div>
        </div>
      ) : (
        <div className="container ms-0">
          <div className={`row ${shared || newTab ? "" : "mb-5"}`}>
            <div className="col-12">
              {reportSplit && (
                <div>
                  {" "}
                  <img
                    alt="Close"
                    onClick={() => {
                      document.getElementsByClassName(
                        "p-splitter-gutter"
                      )[0].style.backgroundColor = "transparent";
                      document.getElementsByClassName(
                        "p-splitter-gutter-handle"
                      )[0].style.backgroundColor = "transparent";
                      setSplitReport(false);
                      connection?.current?.unsubscribe();
                    }}
                    src={cross}
                    className="xmark"
                  />
                </div>)}
            </div>
          </div>
          {reportSplit || newTab ? (
            <div className="my-2 ms-2">
              <Skeleton width="100%" height="3rem"></Skeleton>
              {reportSplit && (
                <div className="mt-3">
                  <Skeleton width="40%" height="3rem"></Skeleton>
                </div>)}
            </div>
          ) : (
            <div>
              <div
                className={`reportHead d-flex mt-4 mb-4 expandFullDataTable`}
              >
                <div className="ms-2">
                  <div
                    className={
                      newTab
                        ? `ms-5 fontSizeHeadGeneral`
                        : `fontSizeHeadGeneral`
                    }
                  >
                    <Skeleton width="20rem" height="3rem"></Skeleton>
                  </div>
                </div>
                <div className="ms-auto">
                  <Skeleton width="8rem" height="3rem"></Skeleton>
                </div>
              </div>
            </div>
          )}
          <div className={`d-flex ms-2`}>
            <div className="mt-2 justify-content-start">
              <Skeleton width="8rem" height="2.5rem"></Skeleton>
            </div>
            <div className="d-flex ms-auto">
              <div className="mt-2">
                <Skeleton width="8rem" height="2.5rem"></Skeleton>
              </div>
              <div className="mt-2 ms-2">
                <Skeleton width="8rem" height="2.5rem"></Skeleton>
              </div>
            </div>
          </div>
          <div className="mt-4">
            {[1, 2, 3, 4, 5].map((item) => {
              return (
                <div className={`${newTab || reportSplit ? "" : "ms-2"} my-3`}>
                  <Skeleton width="95%" height="10rem"></Skeleton>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default TestStepRevamp;
