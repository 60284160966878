import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import BreadCrumb from "../../UI/BreadCrumb/BreadCrumb";
import "./ManualTC.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolderOpen,
  faCopy,
  faClipboardList,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import httpService from "../../../services/http.service";
import {
  ConvertTextToSeeMore,
  copyToClipboard,
  createColumns,
  createFilters,
  decodeParam,
  encodeParam,
  getErrorMessage,
  getToastMessage,
  logPageTitleForGA,
} from "../../Helper/Helper";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import ManualTCFile from "./ManualTCFile";
import ManualForm from "./ManualForm";
import { Column } from "primereact/column";
import { useSearchParams } from "react-router-dom";
import NoAccess from "../../UI/NoAccess/NoAccess";
import {
  CLEAR_PROJECT_DATA_MANUALTC,
  PROJECT_DATA_MANUALTC,
} from "../../../actions/type";
import { Button } from "primereact/button";
import { connectSocket } from "../../Helper/SocketHelper";
import { MultiSelect } from "primereact/multiselect";
import { EditDeleteAction } from "../TestTool/ALab/FolderActions";
import {
  handleToastErrorCatch,
  statementError,
} from "../../Helper/ToastHelper";
import DeleteDialog from "../TestTool/ALab/DeleteDialog";
import NoData from "../../UI/NoData/NoData";
import SkeletonUtility from "../../UI/Skeleton/SkeletonUtility";
import ImportExcel from "./ImportExcel";

const ManualTC = () => {
  const [breadList, setBreadList] = useState([
    { name: "Manual Testcase", f_id: null, p_id: null, p_name: null },
  ]);
  const [errorMessages, setErrorMessages] = useState(null);
  const currentP_ID = useRef(null);
  const currentP_Name = useRef(null);
  const [allManualTC, setAllManualTC] = useState(null);
  const currentFolderID = useRef(null);
  const currentFolderName = useRef(null);
  const [currentManualId, setCurrentManualId] = useState(null);
  const [currentTCName, setCurrentTCName] = useState("");
  const [newFolderDialog, setNewFolderDialog] = useState(false);
  const [projectsWithAccess, setProjectsWithAccess] = useState([]);
  const [newFolderName, setNewFolderName] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const company = useSelector((state) => state.auth.userProfile.socket);
  const [sameCompanyUsers, setSameCompanyUsers] = useState([]);
  const [manualDialog, setManualDialog] = useState(false);
  const [manualFormData, setManualFormData] = useState(null);
  const [suites, setSuites] = useState(null);
  const [suiteLinkDialog, setSuiteLinkDialog] = useState(null);
  const [selectedSuites, setSelectedSuites] = useState(null);
  const [manualFilters, setManualFilters] = useState({});
  const ref = useRef();
  const buttonRef = useRef(null);
  const optionsRef = useRef(null);
  const [showCreateNew, setShowCreateNew] = useState(false);
  const [searchParams] = useSearchParams();
  const connectionManual = useRef(null);
  const connectionTestpad = useRef(null);
  const projectData = useSelector(
    (state) => state.manualTC.project_data_manualtc
  );
  const [noAccessFile, setNoAccessFile] = useState(false);
  const pidArray = useRef(null);
  const dispatch = useDispatch();
  const [createNewFolderButtonLoading, setCreateNewFolderButtonLoading] =
    useState(false);
  const [
    createNewFolderModalButtonLoading,
    setCreateNewFolderModalButtonLoading,
  ] = useState(false);
  const [testcaseButtonLoading, setTestcaseButtonLoading] = useState(false);
  const [linkSuiteButtonLoading, setLinkSuiteButtonLoading] = useState(false);
  const [linkButtonLoading, setLinkButtonLoading] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [editTcId, setEditTcId] = useState(null);
  const [editFolderData, setEditFolderData] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const toDeleteName = useRef(null);
  const toDeleteId = useRef(null);
  const toDeleteType = useRef(null);

  useEffect(()=>{
  
  },[selectedSuites])

  useEffect(() => {
    //setting the page title to log in GA
    let title = "Jewel | MLab";
    document.title = title;
    logPageTitleForGA(title);

    //connecting the websocket
    connectSocket(
      "testpad",
      company.toString().toUpperCase() + "_MANUAL_FOLDER/private",
      connectionTestpad,
      notifyClient
    );
    connectSocket(
      "execution",
      company.toString().toUpperCase() + "_MANUAL_FOLDER/private",
      connectionManual,
      notifyClient
    );
    //it will turn true if projectData dependency useEffect shows any change in projectData
    setNoAccessFile(false);

    //get ProjectData in redux
    httpService
      .getProjectRole()
      .then((data) => {
        let pids = [];
        data?.data?.map((roles) => pids.push(roles["pid"]));
        getErrorMessage(data);
        return Promise.resolve(pids);
      })
      .then((data) => {
        if (data && data.length > 0) {
          httpService.getProjectData(data).then((res) => {
            dispatch({
              type: PROJECT_DATA_MANUALTC,
              payload: res,
            });
            return Promise.resolve(res);
          });
        }
      })
      .catch((err) => {
        if (
          err?.response?.data?.operation?.toLowerCase() === "info" &&
          err?.response?.data?.suboperation
        ) {
          dispatch({
            type: PROJECT_DATA_MANUALTC,
            payload: err.response.data,
          });
        }
      });

    const handleClickOutside = (event) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target) &&
        optionsRef.current &&
        !optionsRef.current.contains(event.target)
      ) {
        setShowCreateNew(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    //clear project data redux and unsubscribe from websocket
    return () => {
      //unsubscribe from websocket
      connectionManual?.current?.unsubscribe();
      connectionTestpad?.current?.unsubscribe();

      document.removeEventListener('mousedown', handleClickOutside);
      //clear project data from redux
      dispatch({
        type: CLEAR_PROJECT_DATA_MANUALTC,
      });
    };
  }, []);

  useEffect(() => {
    if (projectData && projectData.length > 0) {
      let pids = projectData.map((data) => data.pid);
      pidArray.current = pids;
    } else if (
      projectData?.operation?.toLowerCase() === "info" &&
      projectData?.suboperation
    ) {
      setNoAccessFile(true);
    }
  }, [projectData]);

  const notifyClient = (e) => {
    if (e["body"]) {
      let wsBody = JSON.parse(e["body"]);
      if (pidArray.current && pidArray.current.includes(wsBody["pid"])) {
        getAllTC();
      }
    }
  };

  useEffect(() => {
    setNoAccessFile(false);
    if (searchParams.get("f_id")) {
      let tempList = [...JSON.parse(decodeParam(searchParams.get("list")))];
      setBreadList(tempList);
      setCurrentManualId(null);
      currentFolderID.current = searchParams.get("f_id");
      currentP_ID.current = searchParams.get("p_id");
      currentP_Name.current = tempList?.[tempList.length - 1]?.["p_name"];
      getAllTC();
    } else if (searchParams.get("mtc_id")) {
      let tempList = searchParams.get("list")
        ? [...JSON.parse(decodeParam(searchParams.get("list")))]
        : [];
      setBreadList(tempList);
      setCurrentManualId(searchParams.get("mtc_id"));
      currentP_ID.current = searchParams.get("p_id");
      currentP_Name.current = tempList?.[tempList.length - 1]?.["p_name"];
    } else {
      setBreadList([
        { name: "Manual Testcase", f_id: null, p_id: null, p_name: null },
      ]);
      setCurrentManualId(null);
      currentFolderID.current = null;
      currentP_ID.current = null;
      currentP_Name.current = null;
      getAllTC();
    }
  }, [searchParams]);

  useEffect(() => {
    if (allManualTC) {
      let finalHeaders = [];
      finalHeaders = [...allManualTC?.headers, ...allManualTC?.optionalHeaders];
      if (Object.keys(manualFilters)?.length === 0) {
        const filterData = allManualTC?.headers
          ? createFilters(finalHeaders)
          : {};
        setManualFilters(filterData);
      }
    }
  }, [allManualTC]);


  const onTestcaseClick = (data, type, list) => {
    setShowCreateNew(false);
    if (type === "manualtc") {
      if (data["f_id"]) {
        setAllManualTC(null);
        setCurrentManualId(null);
        currentFolderID.current = data["f_id"];
        currentP_ID.current = data["p_id"];
        currentP_Name.current = data["p_name"];
        getAllTC();
        window.history.replaceState(
          null,
          "",
          "/#/testpad/MLab?f_id=" +
          data["f_id"] +
          "&p_id=" +
          data["p_id"] +
          "&list=" +
          encodeParam(JSON.stringify(list))
          // + "&filters=" +
          // encodeParam(JSON.stringify(manualFilters))
        );
      } else if (data["mtc_id"]) {
        currentFolderID.current = null;
        currentP_ID.current = data["p_id"];
        currentP_Name.current = data["p_name"];
        setCurrentManualId(data["mtc_id"]);
      } else {
        if (data["name"].toLowerCase() === "manual testcase") {
          setAllManualTC(null);
          currentFolderID.current = null;
          setCurrentManualId(null);
          currentP_ID.current = null;
          currentP_Name.current = null;
          getAllTC();
          window.history.replaceState(null, "", "/#/testpad/MLab");
        }
      }
    }
  };

  const getAllTC = () => {
    //required for breadcrumb click title change
    let title = "Jewel | MLab";
    document.title = title;
    logPageTitleForGA(title);

    let payload = {
      folderId: currentFolderID.current,
      companyName: company,
    };
    httpService
      .getAllFolderTC(payload)
      .then((data) => {
        getErrorMessage(data);
        setAllManualTC(data?.data);
      })
      .catch((err) => {
        if (
          err?.response?.data?.operation?.toLowerCase() === "info" &&
          err?.response?.data?.suboperation
        ) {
          setNoAccessFile(true);
          connectionManual?.current?.unsubscribe();
          connectionTestpad?.current?.unsubscribe();
          return;
        }
        handleToastErrorCatch(err);
      });
  };

  const openFolder = (f_id, f_name, p_id, p_name) => {
    setShowCreateNew(false);
    setAllManualTC(null);
    setManualFilters({});
    currentFolderID.current = f_id;
    currentFolderName.current = f_name;
    currentP_ID.current = p_id;
    currentP_Name.current = p_name;
    let newLink = {
      name: f_name,
      f_id: f_id,
      p_id: p_id,
      p_name: p_name,
    };
    setBreadList([...breadList, newLink]);
    getAllTC();
    let list = [...breadList, newLink];
    window.history.replaceState(
      null,
      "",
      "/#/testpad/MLab?f_id=" +
      f_id +
      "&p_id=" +
      p_id +
      "&list=" +
      encodeParam(JSON.stringify(list))
      // + "&filters=" +
      // encodeParam(JSON.stringify(manualFilters))
    );
  };

  const openFile = (mtc_id, tc_name, p_id, p_name) => {
    setShowCreateNew(false);
    setCurrentManualId(mtc_id);
    setCurrentTCName(tc_name);
    currentP_ID.current = p_id;
    currentP_Name.current = p_name;

    let newLink = {
      name: tc_name,
      mtc_id: mtc_id,
      p_id: p_id,
      p_name: p_name,
    };
    setBreadList([...breadList, newLink]);

    let list = [...breadList, newLink];
    window.history.replaceState(
      null,
      "",
      "/#/testpad/MLab?mtc_id=" +
      mtc_id +
      "&p_id=" +
      p_id +
      "&list=" +
      encodeParam(JSON.stringify(list))
      // + "&filters=" +
      // encodeParam(JSON.stringify(manualFilters))
    );
  };

  const FolderAction = (val, data) => {
    const [showSeeMoreFolder, setShowSeeMoreFolder] = useState(false);
    const [showSeeMoreFile, setShowSeeMoreFile] = useState(false);
    return (
      <div>
        {data?.[currentFolderID.current?"Folder/Testcase":"Folder"]?.subType?.toLowerCase() === "folder" ? (
          <div
            className="reqLinks boldText ms-2 d-inline-flex"
            onClick={(e) =>
              openFolder(
                data["Id"]["value"],
                data[currentFolderID.current?"Folder/Testcase":"Folder"]["value"],
                data["P ID"]["value"],
                data["Project Name"]["value"]
              )
            }
          >
            <FontAwesomeIcon icon={faFolderOpen} className="reqIcons me-2" />{" "}
            <span>
              {ConvertTextToSeeMore(
                "Folder Name",
                val,
                showSeeMoreFolder,
                setShowSeeMoreFolder,
                20
              )}
            </span>
          </div>
        ) : (
          <div
            className="reqLinks boldText ms-2 d-inline-flex"
            onClick={(e) =>
              openFile(
                data["Id"]["value"],
                data[currentFolderID.current?"Folder/Testcase":"Folder"]["value"],
                data["P ID"]["value"],
                data["Project Name"]["value"]
              )
            }
          >
            <FontAwesomeIcon icon={faFile} className="reqIcons me-2" />{" "}
            <span>
              {ConvertTextToSeeMore(
                "Testcase Name",
                val,
                showSeeMoreFile,
                setShowSeeMoreFile,
                20
              )}
            </span>
          </div>
        )}
      </div>
    );
  };

  const getDropdownWidth = () => {
    if (buttonRef.current) {
      const buttonWidth = buttonRef.current.offsetWidth;
      return Math.max(buttonWidth, 100); // Ensure a minimum width of 100px
    }
    return "auto";
  };


  const createNewFolderAPI = (payload) => {
    setCreateNewFolderModalButtonLoading(true);
    httpService
      .createNewFolderTestpad(payload, "TESTCASE")
      .then((data) => {
        getToastMessage(data);
        setNewFolderDialog(false);
        setNewFolderName("");
        setSelectedProject(null);
        setCreateNewFolderModalButtonLoading(false);
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => {
          setNewFolderDialog(false);
          setNewFolderName("");
          setSelectedProject(null);
          setCreateNewFolderModalButtonLoading(false);
        });
      });
  };

  const createNewFolder = () => {
    if (!currentFolderID.current) {
      if (selectedProject && newFolderName?.length > 0) {
        let payload = {
          name: newFolderName,
          pid: selectedProject,
          folderId: currentFolderID.current,
        };
        createNewFolderAPI(payload);
      } else {
        statementError("All Fields are required !");
      }
    } else {
      if (newFolderName?.length > 0) {
        let payload = {
          name: newFolderName,
          pid: currentP_ID.current,
          folderId: currentFolderID.current,
        };
        createNewFolderAPI(payload);
      } else {
        statementError("All Fields are required !");
      }
    }
  };

  const editFolderFn = () => {
    if (selectedProject && newFolderName?.length > 0) {
      let payload = {
        name: newFolderName,
        pid: selectedProject,
        id: editFolderData?.["Id"]?.value,
        folderType: "TESTCASE",
      };
      setCreateNewFolderModalButtonLoading(true);
      httpService
        .editFolderTestpad(payload)
        .then((data) => {
          getToastMessage(data);
          setNewFolderDialog(false);
          setNewFolderName("");
          setSelectedProject(null);
          setEditFolderData(null);
          setCreateNewFolderModalButtonLoading(false);
        })
        .catch((err) => {
          handleToastErrorCatch(err, () => {
            setNewFolderDialog(false);
            setNewFolderName("");
            setSelectedProject(null);
            setEditFolderData(null);
            setCreateNewFolderModalButtonLoading(false);
          });
        });
    } else {
      statementError("All Fields are required !");
    }
  };

  const getUsers = (pid) => {
    httpService
      .getSameCompanyUsers(pid)
      .then((data) => {
        setSameCompanyUsers(data?.data?.data ? data.data.data : []);
        getErrorMessage(data);
      })
      .catch((err) => {
        handleToastErrorCatch(err);
      });
  };

  const getProjects = () => {
    setCreateNewFolderButtonLoading(true);
    httpService
      .getAllProjectsWithAccess()
      .then((data) => {
        setProjectsWithAccess(data?.data);
        getErrorMessage(data);
        setCreateNewFolderButtonLoading(false);
        setNewFolderDialog(true);
        setShowCreateNew(false);
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => {
          setCreateNewFolderButtonLoading(false);
          setShowCreateNew(false);
        });
      });
  };

  const openFolderDialog = () => {
    setNewFolderDialog(true);
    setShowCreateNew(false);

  }
  const deleteAction = (val, rowData) => {
    setDeleteDialog(true);
    toDeleteId.current = val;
    toDeleteName.current = rowData?.["Folder/Testcase"]?.value;
    toDeleteType.current =
      rowData?.[currentFolderID.current?"Folder/Testcase":"Folder"]?.subType?.toLowerCase() === "folder"
        ? "folder"
        : "file";
  };

  const deleteFolder = (id, setLoading) => {
    setLoading(true);
    httpService
      .deleteFolderTestpad(id)
      .then((data) => {
        getToastMessage(data);
        setLoading(false);
        onHideDeleteDialog();
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => onHideDeleteDialog());
        setLoading(false);
      });
  };

  const onHideDeleteDialog = () => {
    setDeleteDialog(false);
    toDeleteId.current = null;
    toDeleteName.current = null;
    toDeleteType.current = null;
  };

  const deleteTestcase = (id, setLoading) => {
    setLoading(true);
    httpService
      .deleteAlabTestcase(id)
      .then((data) => {
        getErrorMessage(data);
        setLoading(false);
        onHideDeleteDialog();
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => onHideDeleteDialog());
        setLoading(false);
      });
  };

  const editFileAction = (val, rowData) => {
    const type = rowData?.[currentFolderID.current?"Folder/Testcase":"Folder"]?.subType?.toLowerCase();
    if (type === "testcase") {
      httpService
        .getManualData(val)
        .then((data) => {
          getErrorMessage(data);
          setManualFormData(data?.data);
          setEditTcId(val);
          if (data?.operation?.toLowerCase() === "success") {
            getUsers(data?.data?.pid);
            setManualDialog(true);
          }
        })
        .catch((err) => {
          handleToastErrorCatch(err);
        });
    } else {
      setNewFolderName(rowData?.[currentFolderID.current?"Folder/Testcase":"Folder"]?.value);
      setSelectedProject(rowData?.["P ID"]?.value);
      setEditFolderData(rowData);
      setNewFolderDialog(true);
    }
  };

  const editManualData = () => {
    // getUsers(currentP_ID.current);
    setTestcaseButtonLoading(true);
    httpService
      .getManualData(currentManualId)
      .then((data) => {
        getErrorMessage(data);
        setManualFormData(data?.data);
        if (data?.operation?.toLowerCase() === "success") {
          getUsers(data?.data?.pid);
          setManualDialog(true);
        }
        setTestcaseButtonLoading(false);
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => setTestcaseButtonLoading(false));
      });
  };

  const linkTCToSuite = (e) => {
    setLinkButtonLoading(true);
    let payload = {
      id: currentManualId,
    };

    const s_id = selectedSuites && selectedSuites?.length > 0
      ? Object.keys(selectedSuites)?.map((data) => {
        return selectedSuites?.[data]?.["Suite ID"]?.value;
      })
      : null;
    payload["s_id"] = s_id;

    if (!s_id) {
      statementError("No suite selected !")
      return;
    }
    httpService
      .linkToSuite(payload)
      .then((data) => {
        getToastMessage(data);
        setSuiteLinkDialog(false);
        setSelectedSuites(null);
        setSuites(null);
        setLinkButtonLoading(false);
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => setLinkButtonLoading(false))
      });
  };

  const getSuites = () => {
    setLinkSuiteButtonLoading(true);
    httpService
      .getSuitesToLink(currentManualId)
      .then((data) => {
        setSuites(data?.data);
        getErrorMessage(data);
        setSuiteLinkDialog(true);
        setLinkSuiteButtonLoading(false);
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => setLinkSuiteButtonLoading(false))
      });
  };

  const newManual = () => {
    setTestcaseButtonLoading(true);
    httpService
      .getAllProjectsWithAccess()
      .then((data) => {
        setProjectsWithAccess(data?.data);
        getErrorMessage(data);
        setManualDialog(true);
        setTestcaseButtonLoading(false);
        setShowCreateNew(false);
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => {
          setTestcaseButtonLoading(false)
          setShowCreateNew(false);
        });
      });
  };

  const createFolder = () => {
    return (
      <>
        <div className="row mt-3 mx-2">
          <div className="col-md-6 col-12">
            <InputText
              type={"text"}
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              className="w-100"
              placeholder={"Folder Name"}
              disabled={createNewFolderModalButtonLoading}
            />
          </div>
          {!currentFolderID.current ? (
            <div className="col-md-6 col-12">
              {!editFolderData ? (
                <Dropdown
                  className="dialog-dropdown px-3 w-100"
                  options={projectsWithAccess}
                  optionValue="P ID"
                  optionLabel="Project Name"
                  value={selectedProject}
                  onChange={(e) => {
                    setSelectedProject(e.value);
                  }}
                  filter
                  placeholder="Select Project"
                  maxSelectedLabels={1}
                  disabled={createNewFolderModalButtonLoading}
                />
              ) : (
                <InputText
                  type={"text"}
                  value={editFolderData?.["Project Name"]?.value}
                  className="w-100"
                  disabled={true}
                />
              )}
            </div>
          ) : (
            <div className="col-md-6 col-12">
              <InputText
                type={"text"}
                value={currentP_Name.current}
                className="w-100"
                disabled={true}
              />
            </div>
          )}
        </div>
      </>
    );
  };

  //check if this has a common method NR
  const copyLink = (e) => {
    copyToClipboard(window.location.origin + "/" + window.location.hash);
  };

  const onColumnToggle = (e) => {
    setSelectedColumns(e.value);
  };
  const header = allManualTC?.optionalHeaders && (
    <div style={{ textAlign: "left", minHeight: "2vh" }}>
      <MultiSelect
        placeholder="More Detail(s)"
        value={selectedColumns}
        options={allManualTC?.optionalHeaders}
        onChange={(e) => onColumnToggle(e)}
        maxSelectedLabels={1}
        style={{ width: "15vw" }}
      />
    </div>
  );

  return !noAccessFile ? (
    <div className="container-fluid reqSection mx-2">
      <div className="d-flex flex-wrap align-items-center pt-2 mx-2 mb-2 ">
        <div className=" pb-1">
          <div className="headerTest">
            MLab
            <span className="ms-3">
              {breadList?.length > 0 && (
                <FontAwesomeIcon
                  className="actionBtn"
                  icon={faCopy}
                  title="Copy Link"
                  onClick={(e) => copyLink(e)}
                />
              )}
            </span>
          </div>
        </div>
        <div className="ms-sm-auto d-flex align-items-center">
          {(!currentManualId && currentFolderID.current) &&
            <ImportExcel
              currentP_Name={currentP_Name}
              currentP_ID={currentP_ID}
              currentFolderID={currentFolderID}
            />
          }

          <div className="createNew">
            {!currentManualId &&
              <button
                ref={buttonRef}
                className="btn themeBtn mx-2 py-2 px-3 mt-1 createNewBtn"
                onClick={(e) => { setShowCreateNew(!showCreateNew) }}
              >
                <span className="me-2">
                  <FontAwesomeIcon
                    icon={faPlus}
                  />
                </span>
                Create New
              </button>
            }
            {(showCreateNew && !currentManualId) &&
              <div className="createNewDiv mx-2 py-2 mt-2" style={{ width: `${getDropdownWidth()}px` }} ref={optionsRef}>
                <div
                  className={`${createNewFolderButtonLoading ? "createNewDivElementsLoading" : "createNewDivElements"} py-2 ps-3`}
                  onClick={(e) => (!createNewFolderButtonLoading && !currentFolderID.current) ? getProjects() : openFolderDialog()}
                >
                  Folder
                  {createNewFolderButtonLoading && <i className="pi pi-spin pi-spinner ms-2"></i>}
                </div>
                <div
                  className={`${currentFolderID.current ? `${testcaseButtonLoading ? "createNewDivElementsLoading" : "createNewDivElements"}` : "createNewDivElementsDisabled"} py-2 ps-3`}
                  onClick={(e) => (!testcaseButtonLoading && currentFolderID.current) && newManual()}
                >
                  Testcase
                  {testcaseButtonLoading && <i className="pi pi-spin pi-spinner ms-2"></i>}
                </div>
              </div>
            }
          </div>
          {currentManualId &&
            <div className="align-items-center">
              <Button
                label="Link Suite"
                className="btn-success btn themeBtnOutline mx-2 py-2 px-3 mt-1"
                onClick={(e) => {
                  getSuites();
                }}
                icon={linkSuiteButtonLoading ? "pi pi-spin pi-spinner me-2" : "pi pi-link"}
                loading={linkSuiteButtonLoading}
                iconPos="left"
              />
              <Button
                label={"Edit Testcase"}
                className="btn-success btn themeBtn mx-2 py-2 px-3 mt-1"
                onClick={(e) => {
                  editManualData()
                }}
                icon={
                  testcaseButtonLoading ? "pi pi-spin pi-spinner me-2" : "pi pi-pencil me-2"
                }
                iconPos="left"
                loading={testcaseButtonLoading}
              />
            </div>}
        </div>
      </div>
      <div className="mx-2">
        {breadList?.length > 0 ? (
          <BreadCrumb
            breadList={breadList}
            setBreadList={setBreadList}
            component={"ManualTC"}
            onClickEvent={onTestcaseClick}
          />
        ) : (
          <div>
            <span>
              <FontAwesomeIcon
                className="me-2 staticIcon"
                icon={faClipboardList}
              />
            </span>
            MLab
          </div>
        )}
      </div>
      {currentManualId ? (
        <ManualTCFile
          mtc_id={currentManualId}
          tc_name={currentTCName}
          noAccessFile={noAccessFile}
          setNoAccessFile={setNoAccessFile}
        />
      ) : (
        <div className="mt-3 mx-2">
          {allManualTC ? (
            <div>
              <DataTable
                reorderableColumns
                onFilter={(e) => {
                  setManualFilters(e.filters);
                }}
                header={header}
                resizableColumns
                stripedRows
                columnResizeMode="expand"
                value={allManualTC ? allManualTC["data"] : null}
                paginator
                rows={25}
                dataKey="id"
                filters={manualFilters}
                rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                scrollHeight={"55vh"}
                filterDisplay="menu"
                responsiveLayout="scroll"
                emptyMessage={<NoData />}
                currentPageReportTemplate="Total {totalRecords} Record(s) Found"
                removableSort
                className="test-stepTable"
              >
                {createColumns(
                  allManualTC["headers"],
                  true,
                  true,
                  allManualTC["data"],
                  true,
                  null,
                  { customAction: FolderAction },
                  {
                    crud_delete: true,
                  },
                  {
                    crud_delete: EditDeleteAction,
                  },
                  {
                    crud_delete_props: {
                      editType: "editFromMlab",
                      editFileAction: editFileAction,
                      // setAction: setAction,
                      setEditTcId: setEditTcId,
                      setEditFolderData: setEditFolderData,
                      deleteAction: deleteAction,
                    },
                  }
                )}
                {allManualTC?.optionalHeaders &&
                  createColumns(selectedColumns, true, true, allManualTC?.data)}
              </DataTable>
            </div>
          ) : (
            <SkeletonUtility
              paginator={false}
              totalRows={8}
            />
          )}
        </div>
      )}
      <Dialog
        blockScroll={true}
        draggable={false}
        header={!editFolderData ? "Create New Folder" : "Edit Folder Details"}
        visible={newFolderDialog}
        onHide={() => {
          setNewFolderDialog(false);
          setNewFolderName("");
          setSelectedProject(null);
          setEditFolderData(null);
        }}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "50vw" }}
        footer={
          <div className="text-center">
            <Button
              label={!editFolderData ? "Create" : "Save"}
              className="btn-success btn themeBtn"
              onClick={(e) =>
                !editFolderData ? createNewFolder() : editFolderFn()
              }
              loadingIcon={"pi pi-spin pi-spinner me-2"}
              loading={createNewFolderModalButtonLoading}
              iconPos="right"
              disabled={newFolderName?.length===0 || editFolderData?.Folder?.value===newFolderName}
            />
          </div>
        }
      >
        {createFolder()}
      </Dialog>
      <Dialog
        blockScroll={true}
        draggable={false}
        header={currentManualId ? "Edit Testcase" : "Create New Testcase"}
        visible={manualDialog}
        maximizable
        onHide={() => {
          setManualDialog(false);
          setSameCompanyUsers([]);
          ref.current.clearManualFormData();
        }}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "75vw" }}
      >
        <ManualForm
          type={currentManualId || editTcId ? "edit" : "create"}
          f_id={currentFolderID.current}
          mtc_id={currentManualId}
          users={sameCompanyUsers}
          projectsWithAccess={projectsWithAccess}
          ref={ref}
          editData={manualFormData}
          setEditData={setManualFormData}
          setManualDialog={setManualDialog}
          p_id={currentP_ID.current}
          p_name={currentP_Name.current}
          getAllTC={getAllTC}
          breadList={breadList}
          setBreadList={setBreadList}
          getUsers={getUsers}
          editTcId={editTcId}
          setEditTcId={setEditTcId}
        />
      </Dialog>
      <Dialog
        blockScroll={true}
        header={`Link Suite`}
        visible={suiteLinkDialog}
        onHide={() => {
          setSuiteLinkDialog(false);
          setSuites(null);
          setSelectedSuites(null);
        }}
        draggable={false}
        breakpoints={{ "960px": "75vw" }}
        style={{
          width: "60vw",
        }}
        footer={
          <div>
            <Button
              label="Link"
              className="btn-success btn themeBtn mx-2 px-3 py-2 mt-1"
              onClick={(e) => linkTCToSuite(e)}
              loadingIcon={"pi pi-spin pi-spinner"}
              loading={linkButtonLoading}
              icon={linkButtonLoading ? "pi pi-spin pi-spinner me-2" : "pi pi-link"}
              iconPos="right"
              disabled={!selectedSuites?.length>0}
            />
          </div>
        }
      >
        <DataTable
          reorderableColumns
          collapsedRowIcon="pi pi-arrow-circle-right"
          expandedRowIcon="pi pi-arrow-circle-down"
          selection={selectedSuites}
          onSelectionChange={(e) => setSelectedSuites(e.value)}
          removableSort
          resizableColumns
          stripedRows
          columnResizeMode="expand"
          value={suites && suites["data"] ? suites["data"] : null}
          dragSelection
          paginator
          rows={25}
          filterDisplay="menu"
          responsiveLayout="scroll"
          emptyMessage={<NoData />}
          currentPageReportTemplate="Total {totalRecords} Records Found"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown "
          rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
          scrollHeight={"55vh"}
          className="test-stepTable"
        >
          <Column
            selectionMode="multiple"
            headerClassName={`headercolumnsPrime`}
            className={"columnsPrime"}
            headerStyle={{ width: "3em" }}
          ></Column>

          {suites && suites["data"] ? (
            createColumns(suites["headers"], true, false, suites["data"])
          ) : (
            <></>
          )}
        </DataTable>
      </Dialog>

      <DeleteDialog
        header="Delete Folder/Testcase"
        deleteDialog={deleteDialog}
        onHideDeleteDialog={onHideDeleteDialog}
        toDeleteName={toDeleteName}
        toDeleteId={toDeleteId}
        toDeleteType={toDeleteType}
        deleteFolder={deleteFolder}
        deleteFile={deleteTestcase}
      />
    </div>
  ) : (
    <NoAccess />
  );
};

export default ManualTC;
