import "./Home.css";
import "react-toastify/dist/ReactToastify.css";
import { loadFull } from "tsparticles";
import { useCallback } from "react";
import Particles from "react-tsparticles";
import logo from "../../../assets/Jewel.svg";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import {
  faChalkboardUser,
  faChartColumn,
  faClipboardList,
  faFolderTree,
  faPlayCircle,
  faTrashArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logPageTitleForGA } from "../../Helper/Helper";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.hash]);

  useEffect(() => {
    let title = "Jewel | Home";
    document.title = title;
    logPageTitleForGA(title);
  }, []);

  let cards = [
    {
      name: "ELab",
      icon: faPlayCircle,
      content:
        "A module that caters to the requirements of suite creation, on-cloud execution, and real-time UI monitoring. Additionally provides the ability to acquire suites from Github repositories, send emails and manage tests.",
      route: "/info/testpad",
    },
    {
      name: "ALab",
      icon: faChalkboardUser,
      content:
        "Creation and management of automated test cases through an intuitive UI using existing frameworks in a folder structure hierarchy. Elevating the efficiency of test automation by making it simpler and codeless.",
      route: "/info/testpad",
    },
    {
      name: "MLab",
      icon: faClipboardList,
      content:
        "Offering services to create repository for manual testcases with an enhanced folder structure hierarchy.Allow QAs to mark the status of manuals while concurrently monitoring live changes and modifications in run reports.",
      route: "/info/testpad",
    },
    {
      name: "Autolytics",
      icon: faChartColumn,
      content:
        "Autolytics allows users to generate detailed reports that allow them to extensively analyse Testcase and Suite information. Additionally, it facilitates effortless sharing of these reports within the team.",
      route: "/info/autolytics",
    },
    {
      name: "TaskBoard",
      icon: faFolderTree,
      content:
        "With Jewel's TaskBoard module users can plan, manage and track requirements in an application at any step of the SDLC. Hence, making it a very powerful tool to undertake better project analysis and management.",
      route: "/info/taskboard",
    },
    {
      name: "Recycle Bin",
      icon: faTrashArrowUp,
      content:
        "The Recycle Bin serves as a temporary storage for deleted automation/manual test cases both folders and files, allowing restoration to their original locations within a 30-day window before permanent deletion.",
      route: "/info/work-minutes",
    },
  ];
  const createInfoCards = (jsonData) => {
    const finalCards = jsonData.map((data, i) => (
      <div
        key={`${i}+${Math.random()}`}
        className="col-lg-4 col-md-6 col-sm-12 col-12"
      >
        <Link className="homecards" to={data["route"]}>
          <div className="card appCard shadow me-2 mt-2 mb-2">
            <div className="card-body ms-2">
              <span className="cardIconDiv">
                {/* <img src={data["icon"]}></img> */}

                <FontAwesomeIcon
                  className="cardIcon mt-2"
                  icon={data["icon"]}
                />
              </span>
              <div className="mt-3 cardName">{data["name"]}</div>
              <div className="mt-1 appCardContent">{data["content"]}</div>
            </div>
          </div>
        </Link>
      </div>
    ));
    return finalCards;
  };
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  return (
    <>
      {/* <IdleTimerContainer /> */}

      <div className="dataHome">
        {/* <div className="text-center mt-5 headerHomeText">
            Report Analysis and Test Monitoring
          </div> */}
        <div className="homeText card mx-auto my-5">
          <div className="py-4 ">
            <div className="ms-5 headerHome">Jewel Applications</div>
            <div className="headerHomeContent ms-5 mt-2 me-4">
              Jewel is your one stop Dashboard to analyze and visualize data in
              a unique and efficient way. Analytical representation of data
              enables the user to visualize and provide insights into their test
              runs so that they can be optimized efficiently. Also, data
              comparisons can be easily achieved due to DB integrations with the
              dashboard which enhances the overall User experience.
            </div>
            <div className="row  mt-3  mx-4 mb-3">{createInfoCards(cards)}</div>
          </div>
        </div>
      </div>
      <Particles
        id="tsparticles"
        init={particlesInit}
        options={{
          autoPlay: true,

          background: {
            color: {
              value: "#f6f7fc",
            },
          },
          fullScreen: { enable: true },

          fpsLimit: 120,
          interactivity: {
            events: {
              // onClick: {
              //   enable: true,
              //   mode: "push",
              // },
              onHover: {
                enable: false,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: "#ff7e7c",
            },
            links: {
              color: "#000000",
              distance: 100,
              enable: true,
              opacity: 0.15,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              directions: "none",
              enable: true,
              outModes: {
                default: "out",
              },
              random: true,
              speed: 2,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 900,
              },
              value: 150,
            },
            opacity: {
              value: 1,
            },
            shape: {
              type: ["image"],

              image: [
                {
                  src: logo,
                  height: 100,
                  width: 100,
                },
              ],
            },
            size: {
              value: { min: 6, max: 6 },
            },
          },
          detectRetina: true,
        }}
      />
    </>
  );
};

export default Home;
