import "./Autolytics.css";
import { useState } from "react";
import NewTab from "../../Layouts/Autolytics/NewTab";
import { connect, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  clearProjectData,
  clearReportData,
  getProjectData,
  getReportData,
} from "../../../actions/autolytics";
import { logPageTitleForGA } from "../../Helper/Helper";
import Reporting from "../../Layouts/Autolytics/Reporting";
import Dashboard from "../../Layouts/Autolytics/Dashboard";
import NoAccess from "../../UI/NoAccess/NoAccess";
import { Link, useLocation, useParams } from "react-router-dom";
import Overview from "../../Layouts/Autolytics/SecurityReport/Overview";
import NewDashboard from "../../Layouts/Autolytics/NewDashboard";
import NewTabRevamp from "../../Layouts/NewTab/NewTabRevamp";


const Autolytics = (props) => {
  // const [showDialog, setShowDialog] = useState(false);
  // const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);
  const params=useParams();
  const [currentDashboardTab,setCurrentDashboardTab]=useState("insights");
  const role = useSelector((state) => state.auth.userProfile.role);
  const firstName = useSelector((state) => state.auth.userProfile.firstName);
  const lastName = useSelector((state) => state.auth.userProfile.lastName);
  const imgUrl= useSelector((state)=>state.auth.userProfile.avatar);
  const username= useSelector((state)=>state.auth.userProfile.username);
  const reportData=useSelector((state)=>state.autolytics.projectData)

  const userDetail={
    firstName,
    lastName,
    imgUrl,
    username
  };

  useEffect(() => {

    let title = "Jewel | Autolytics";
    document.title = title;
    logPageTitleForGA(title);

    props.dispatch(getReportData());
    props.dispatch(getProjectData());

    return () => {
      props.dispatch(clearReportData());
      props.dispatch(clearProjectData());
    };
  }, []);

  useEffect(()=>{
    if(params?.submodule){
      setCurrentDashboardTab(params?.submodule)
    }
  },[params])



  // useEffect(() => {
  //    const setPrompt = props.reportOpen ? setShowDialog(true) : setShowDialog(false) ;
  // },[props.reportOpen]);

  // useCustomPrompt(
  //   "It seems that you have Report(s) opened. Are you sure you want to leave ?" +
  //     "\n" +
  //     "\n" +
  //     "If Okay, Your progress will be lost.",
  //   props.reportOpen
  // );
  const projectData = useSelector((state) => state.autolytics.projectData);
  const [noAccess, setNoAccess] = useState(false);

  useEffect(() => {
    if (
      projectData["operation"] &&
      projectData["operation"].toLowerCase() === "info" &&
      projectData["suboperation"]
    ) {
      setNoAccess(true);
    }
  }, [projectData]);

  return (
    <>
      {noAccess ? (
        <NoAccess />
      ) : (
        <div className="container-fluid">
          <div className="autolytics">
            <ul
              className="nav nav-pills ms-2 pt-3"
              id={"pills-tab"}
              role="tablist"
            >
              <li className="nav-item mr-1" role="presentation">
                <Link to={`/autolytics/overview/${currentDashboardTab}`} className="linkTab">
                <div
                  className={`fontSizeGeneral nav-link extentPill ${params?.module==='overview'?'active':''}`} 
                  id={"pills-dashboard-tab"}
                  data-toggle="pill"
                  data-target={"#pills-dashboard"}
                  type="button"
                  role="tab"
                  // onClick={()=>handleTabSelection('overview')}
                >
                  Overview
                </div>
                </Link>
              </li>

              <li className="nav-item ms-1" role="presentation">
              <Link to="/autolytics/reporting" className="linkTab">

                <div
                  className={`fontSizeGeneral nav-link extentPill ${params?.module==='reporting'?'active':''}`}
                  id={"pills-reporting-tab"}
                  data-toggle="pill"
                  data-target={"#pills-reporting"}
                  type="button"
                  role="tab"
                  // onClick={()=>handleTabSelection('reporting')}
                >
                  Reporting
                </div>
</Link>
              </li>
            </ul>
            <div className="tab-content mx-4 pb-3" id="pills-tabContent">
              <div
                className={`tab-pane fade show  ${params?.module==='overview'?'active':''}`}
                id={"pills-dashboard"}
                role="tabpanel"
                aria-labelledby={"pills-dashboard-tab"}
              >
                <div className="mt-1">
                  <NewDashboard reportData={reportData} currentDashboardTab={currentDashboardTab} setCurrentDashboardTab={setCurrentDashboardTab}/>
                </div>
              </div>
              <div
                className={`tab-pane fade show  ${params?.module==='reporting'?'active':''}`}
                id={"pills-reporting"}
                role="tabpanel"
                aria-labelledby={"pills-reporting-tab"}
              >
                {/* <NewTab
                  component={Reporting}
                  defaultTabName="New Report"
                  role={role}
                  userDetail={userDetail}
                /> */}
                <NewTabRevamp role={role}
                  userDetail={userDetail}/>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  const { reportData } = state.autolytics;
  const { projectData } = state.autolytics;
  const { reportOpen } = state.autolytics;

  return {
    reportData,
    projectData,
    reportOpen,
  };
}
export default connect(mapStateToProps)(Autolytics);
