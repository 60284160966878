import { faInfoCircle, faChartLine } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConvertTextToKnob, ConvertTexttoChart } from "../../../Helper/Helper";
import { Skeleton } from "primereact/skeleton";
import { Dropdown } from "primereact/dropdown";
import { ProgressBar } from "primereact/progressbar";
import { useState } from "react";
import { Dialog } from "primereact/dialog";

export const TotalTestcaseCard = ({
  totalTestcaseDataLoading,
  totalTestcaseData,
  chartRefTotal,
  handleClickChart,
}) => {
  const infoText="Displays the overall proportion of test cases covered by automation, including counts of automated and manual test cases."
  return (
    <>
      {!totalTestcaseDataLoading ? (
        <div className="filterCard col-10 col-md-5 mt-2">
          <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center gap-2 my-2">
            <p className="m-0 ps-3 fs-5">
              Total Testcases
            </p>
            <CustomTooltip text={infoText} key="TotalTestcases" name="Total Testcases"/>
            </div>
          </div>
          <hr />
          {totalTestcaseData && (
            <div className="ps-3">
              <b className="fs-4">
                {totalTestcaseData?.total}{" "}
                <FontAwesomeIcon icon={faChartLine} className="mx-2 fs-5" />
              </b>
              <p>
                Automation Coverage :{" "}
                <b className="mx-1 fs-5">{totalTestcaseData?.coverage} %</b>
              </p>
            </div>
          )}
          <div className="row gy-1 gx-2">
            {ConvertTexttoChart(
              totalTestcaseData?.chart,
              "half_doughnut_chart",
              false,
              "TotalTestcase",
              "",
              "newDashboard",
              "",
              "",
              chartRefTotal,
              handleClickChart
            )}
          </div>
        </div>
      ) : (
        <Skeleton className="col-5" width="48%" height="25rem"></Skeleton>
      )}
    </>
  );
};

export const ApplicationStabilityCard = ({
  stabilityData,
  stabilityDataLoading,
  duration,
}) => {

  const infoText="This score reflects the overall health and reliability of your application. A higher score indicates a more stable application with fewer functionality issues."
  return (
    <>
    
      {!stabilityDataLoading ? (
        <div className="stabilityCard col-10 col-md-5 mt-2">
          <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center gap-2">
            <p className="m-0 ps-3 fs-5">
              Application Stablity
            </p>
           <CustomTooltip text={infoText} key="ApplicationStability" name="Application Stablity"/>
            </div>
            <p className="mt-auto mb-0 pe-2 durationText">based on past {duration} days</p>
          </div>
          <hr />
          {stabilityData ? (
            <div className="d-flex px-3 justify-content-between align-items-center">
              <div>
                <p className="my-1">Application Stability Score</p>
                <p>
                  <b className="fs-4 mx-1">
                    {stabilityData?.[0]?.["Application stability score"]}
                  </b>
                  of 1000
                </p>
              </div>
              <div className="pe-3">
                {ConvertTextToKnob(
                  stabilityData?.[0]?.["Application stability score"]
                )}
              </div>
            </div>
          ) : (
            <div className="fadeText">No Data Found</div>
          )}
        </div>
      ) : (
        <Skeleton className="col-5" width="48%" height="13rem"></Skeleton>
      )}
    </>
  );
};

export const AutomationCoverageCard = ({
  automationCoverageData,
  automationCoverageDataLoading,
  chartRefAutomation,
  handleClickChart,
  classificationType,
  setClassificationType,
}) => {

  const infoText="Displays automation coverage and test case counts for either regression or smoke tests, including both automated and manual test cases."
  return (
    <>
    
      {!automationCoverageDataLoading ? (
        <div className="filterCard col-10 col-md-5 mt-2">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center gap-2">
            <p className="m-0 ps-3 fs-5">
              Automation Coverage{" "}
            </p>
            <CustomTooltip text={infoText} key="AutomationCoverage" name="Automation Coverage"/>
            </div>
            <Dropdown
              className="dashboard-dialog-dropdown ms-3"
              style={{ minWidth: "10vw", maxWidth: "10vw" }}
              options={["Regression", "Smoke"]}
              value={classificationType}
              onChange={(e) => setClassificationType(e.value)}
              placeholder="Select"
              maxSelectedLabels={1}
              // disabled={addUserButtonLoading}
            />
          </div>
          <hr />
          {automationCoverageData && (
            <div className="ps-3">
              <b className="fs-4">
                {automationCoverageData?.coverage} %{" "}
                <FontAwesomeIcon icon={faChartLine} className="mx-2 fs-5" />
              </b>
            </div>
          )}
          <div className="row gy-1 gx-2 mt-4">
            {ConvertTexttoChart(
              automationCoverageData?.chart,
              "half_doughnut_chart",
              false,
              "AutomationCoverage",
              "",
              "newDashboard",
              "",
              "",
              chartRefAutomation,
              handleClickChart
            )}
          </div>
        </div>
      ) : (
        <Skeleton className="col-5" width="48%" height="25rem"></Skeleton>
      )}
    </>
  );
};

export const AutomationStabilityCard = ({
  stabilityData,
  stabilityDataLoading,
  duration,
}) => {

  const infoText="This score specifically focuses on the reliability and effectiveness of your automated testing suites. A high score indicates that your tests are consistently identifying and reporting issues without unexpected automation errors."
  return (
    <>
      {!stabilityDataLoading ? (
        <div className="stabilityCard col-10 col-md-5 mt-2">
          <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center gap-2">
            <p className="m-0 ps-3 fs-5">
              Automation Stability
            </p>
            <CustomTooltip text={infoText} key="AutomationStability" name="Automation Stability"/>
            </div>
            <p className="mt-auto mb-0 pe-2 durationText">based on past {duration} days</p>
          </div>
          <hr />
          {stabilityData ? (
            <div className="d-flex px-3 justify-content-between align-items-center">
              <div>
                <p className="my-1">Automation Stability Score</p>
                <p>
                  <b className="fs-4 mx-1">
                    {stabilityData?.[1]?.["Automation stability score"]}
                  </b>
                  of 1000
                </p>
              </div>
              <div className="pe-3">
                {ConvertTextToKnob(
                  stabilityData?.[1]?.["Automation stability score"]
                )}
              </div>
            </div>
          ) : (
            <div className="fadeText">No Data Found</div>
          )}
        </div>
      ) : (
        <Skeleton className="col-5" width="48%" height="13rem"></Skeleton>
      )}
    </>
  );
};

export const StabilityIndexCard = ({
  stabilityData,
  stabilityDataLoading,
  duration,
}) => {

  const infoText="This value represents the historical stability of test suites based on their past execution results."
  return (
    <>
      {!stabilityDataLoading ? (
        <div className="stabilityCard col-10 col-md-5 mt-2">
          <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center gap-2">
            <p className="m-0 ps-3 fs-5">
              Stability Index
            </p>
           <CustomTooltip text={infoText} key="StabilityIndex" name="Stability Index"/>
            </div>
            <p className="mt-auto mb-0 pe-2 durationText">based on past {duration} days</p>
          </div>
          <hr />
          {stabilityData ? (
            <div className="ps-3">
              <p className="my-3 ps-2">Total Percentage</p>
              <div className="d-flex align-items-center">
                <ProgressBar
                  className="dashboard-progress-bar w-75 mx-1"
                  color="green"
                  value={stabilityData?.[2]?.["Stability index"]}
                  showValue={false}
                ></ProgressBar>
                <b className="fs-4 ms-1">{`${stabilityData?.[2]?.["Stability index"]} %`}</b>
              </div>
            </div>
          ) : (
            <div className="fadeText">No Data Found</div>
          )}
        </div>
      ) : (
        <Skeleton className="col-5" width="48%" height="13rem"></Skeleton>
      )}
    </>
  );
};

export const TestcaseExecutedCard = ({
  executionCount,
  duration,
  executionCountLoading,
}) => {

  const infoText="Percentage of unique test cases executed over a specific period relative to the total executed till now."
  return (
    <>
      {!executionCountLoading ? (
        <div className="stabilityCard col-10 col-md-5 mt-2">
          <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center gap-2">
            <p className="m-0 ps-3 fs-5">
            Testcase Executed
            </p>
            <CustomTooltip text={infoText} key="TestcaseExecuted" name="Testcase Executed"/>
            </div>
            <p className="mt-auto mb-0 pe-2 durationText">based on past {duration} days</p>
          </div>
          <hr />
          {executionCount ? (
            <div className="ps-3">
              <p className="my-3 ps-2">Total Percentage</p>
              <div className="d-flex align-items-center">
                <ProgressBar
                  className="dashboard-progress-bar w-75 mx-1"
                  color={
                    executionCount < 60
                      ? "#C21010"
                      : executionCount >= 60 && executionCount < 75
                      ? "#E64848"
                      : executionCount >= 75 && executionCount < 85
                      ? "#FD841F"
                      : executionCount >= 85 && executionCount < 90
                      ? "#82CD47"
                      : executionCount >= 90
                      ? "#379237"
                      : null
                  }
                  value={executionCount}
                  showValue={false}
                ></ProgressBar>
                <b className="fs-4 ms-1">{`${executionCount} %`}</b>
              </div>
            </div>
          ) : (
            <div className="fadeText">No Data Found</div>
          )}
        </div>
      ) : (
        <Skeleton className="col-5" width="48%" height="13rem"></Skeleton>
      )}
    </>
  );
};


const CustomTooltip = ({ text,name }) => {
  const [showInfo, setShowInfo] = useState(false);
  return (
    <>
      <Dialog
        blockScroll={true}
        draggable={false}
        header={name}
        visible={showInfo}
        onHide={() => {
          setShowInfo(false);
        }}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "30vw" }}
      >
        <p>{text}</p>
      </Dialog>
      <i className="pi pi-info-circle fs-5 pointer" onClick={() => setShowInfo(true)}></i>
    </>
  );
};