import Auth from "../../Pages/AuthPage/Auth";
import "./Login.css";
import passwordIcon from "../../../assets/passwordIcon.svg";
import userIcon from "../../../assets/userIcon.svg";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { login } from "../../../actions/auth";
import { connect } from "react-redux";
import { Password } from "primereact/password";
import md5Hex from "md5-hex";
import { isJwtExpired } from "jwt-check-expiration";
import { logPageTitleForGA } from "../../Helper/Helper";
import {
  getInfoToast,
  getSuccessToast,
  statementError,
} from "../../Helper/ToastHelper";

const Login = (props) => {
  const navigate = useNavigate();
  const [loginClicked, setLoginClicked] = useState(false);
  const username = useRef("");
  const password = useRef("");

  useEffect(() => {
    let title = "Jewel | Login";
    document.title = title;
    logPageTitleForGA(title);
    if (props.isLoggedIn) {
      if (!isJwtExpired(localStorage.getItem("usertoken"))) {
        toast.info("You are already Logged in !", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,

          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        navigate("/home", { replace: true });
      } else {
        getInfoToast("Session Expired ! Please Log In to continue. ");
      }
    }
    return () => {
      if (!window.location.hash.includes("/signup"))
        sessionStorage.removeItem("redirect");
    };
  }, []);

  const loginUser = (e) => {
    e.preventDefault();

    if (username.current.length === 0 || password.current.length === 0) {
      statementError("All fields are required !");
      return;
    }
    setLoginClicked(true);
    props
      .dispatch(login(username.current.trim(), md5Hex(password.current)))
      .then((data) => {
        getSuccessToast("Login Successful !");
        const redirectPath = sessionStorage.getItem("redirect");
        if (redirectPath) {
          if (
            redirectPath === "/" ||
            redirectPath === "/signup" ||
            redirectPath === "/login"
          ) {
            navigate("/home", { replace: true });
          } else {
            navigate(redirectPath, { replace: true });
          }
          sessionStorage.removeItem("redirect");
        } else {
          navigate("/home", { replace: true });
        }
      })
      .catch((err) => {
        if (err.message.toLowerCase() === "account verification required!") {
          getInfoToast("Account verification required!");
          navigate("/account-verification", {
            state: {
              firstName: err?.data?.firstName,
              lastName: err?.data?.lastName,
              username: err?.data?.username,
              email: err?.data?.email,
              hashedPassword: md5Hex(password.current),
              companyName: err?.data?.company,
            },
          });
        }
      })
      .finally(() => {
        setLoginClicked(false);
      });
  };

  return (
    <>
      <Auth>
        <div className="d-flex card customSignup">
          <div className="card-body py-5 px-lg-5 px-md-5 px-sm-4 px-xs-2">
            <div className="text-center">
              <span className="titleSignup mt-5">Welcome Back!</span>
              <br />
              <span className="acc">Signin to continue to Jewel</span>
              <br />
              <div id="warningCredentials"></div>
            </div>
            <form className="mt-3 formSignup" onSubmit={(e) => loginUser(e)}>
              <div className="mt-2 inputfield">
                <img src={userIcon} />
                <input
                  type="text"
                  className="form-control"
                  id="usernameField"
                  onChange={(e) => (username.current = e.target.value)}
                  placeholder="Username or Email"
                />
              </div>
              <div className="mt-2 inputfield">
                <img src={passwordIcon} />
                <Password
                  id="passField"
                  onChange={(e) => (password.current = e.target.value)}
                  placeholder="Password"
                  toggleMask
                  feedback={false}
                />
              </div>

              <div className="text-center">
                <button
                  disabled={loginClicked}
                  type="submit"
                  className="mt-3 btn btn-primary text-center px-5"
                >
                  Login
                </button>
              </div>
              <hr className="mb-1" />

              <div className="mt-2 text-center forgotPass">
                <Link to="/login/password/forgot">Forgot Password?</Link>
              </div>
              <div className="mt-1 text-center forgotPass">
                <span className="already">Not A User ?</span>{" "}
                <Link to="/signup">Signup</Link>
              </div>
            </form>
          </div>
        </div>
        {/* <button
          onClick={() => props.dispatch(logout())}
          className="mt-3 btn btn-primary text-center px-5"
        >
          Logout
        </button> */}
      </Auth>
    </>
  );
};
function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message,
  };
}
export default connect(mapStateToProps)(Login);
