import {
  faFilter,
  faInfoCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useRef } from "react";
import "./index.css";
import httpService from "../../../../services/http.service";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";

import dashboardService from "../../../../services/Dashboard/dashboard.service";
import {
  handleToastErrorCatch,
  statementError,
} from "../../../Helper/ToastHelper";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import {
  ApplicationStabilityCard,
  AutomationCoverageCard,
  AutomationStabilityCard,
  StabilityIndexCard,
  TestcaseExecutedCard,
  TotalTestcaseCard,
} from "./TestcaseCards";
import { Skeleton } from "primereact/skeleton";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const convertTextToFilterPill = (
  item,
  setFilteredProjects,
  setSelectedProjects,
  selectedProjects,
  filteredProjects
) => {
  return (
    <div
      key={item?.["P ID"]}
      className="filterPill d-flex justify-content-between align-items-center px-2 py-1"
    >
      <p className="mx-0 my-auto">{item?.["Project Name"]}</p>
      <FontAwesomeIcon
        className="pointer mx-2"
        icon={faTimes}
        onClick={() => {
          if (filteredProjects?.length > 1) {
            setFilteredProjects(
              filteredProjects?.filter(
                (proj) => proj?.["P ID"] !== item?.["P ID"]
              )
            );
            setSelectedProjects(
              selectedProjects?.filter((proj) => proj !== item?.["P ID"])
            );
          } else {
            statementError("At least 1 or more projects must be selected");
          }
        }}
      />
    </div>
  );
};

const TestcaseHeader = ({
  setShowAllProjects,
  setShowFilters,
  showFilters,
  selectedProjects,
  options,
  applyFilter,
  filteredProjects,
  setSelectedProjects,
}) => {
  const filterDropdownRef = useRef(null);
  const filterButtonRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      filterDropdownRef.current &&
      !filterDropdownRef.current.contains(event.target) &&
      filterButtonRef.current &&
      !filterButtonRef.current.contains(event.target)
    ) {
      setShowFilters(false);
    }
  };

  useEffect(() => {
    if (showFilters) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showFilters]);
  return (
    <>
      {filteredProjects?.length > 0 ? (
        <div className=" my-3 d-flex justify-content-between aligh-items-center">
          <p className="mx-2 my-auto fs-5">
            Total Projects Count :{" "}
            <b
              onClick={() => setShowAllProjects(true)}
              className="fs-4 projectCountHeader pointer mx-1"
            >
              {filteredProjects?.length}
            </b>
            <i class="pi pi-info-circle fs-5 mx-2 pointer" onClick={()=>setShowAllProjects(true)}></i>
        
          </p>

          <div className="my-auto filter-container">
            <Button
              label="Apply Filters"
              type="button"
              onClick={() => setShowFilters(true)}
              icon={
                <FontAwesomeIcon icon={faFilter} className="filterIcon me-1" />
              }
              iconPos="left"
              ref={filterButtonRef}
              className="btn-success btn btn-sm applyFilterBtn px-2 py-2 p-applyFilterBtn d-flex align-items-center filterProject"
            />
            {showFilters && (
              <div
                className="mt-2 py-3 px-4 row filterDropdowns "
                ref={filterDropdownRef}
                onClick={(e) => e.stopPropagation()}
              >
                <MultiSelect
                  value={selectedProjects}
                  options={options}
                  onChange={(e) => setSelectedProjects(e.value)}
                  placeholder="Select Projects"
                  maxSelectedLabels={1}
                  // display="chip"
                  filter
                  className="w-75 col-12 mx-auto mt-2 mb-3"
                />
                <div className="col-12 d-flex justify-content-end gap-2 px-0">
                  <Button
                    label="Apply"
                    type="button"
                    disabled={selectedProjects?.length === 0}
                    onClick={() => applyFilter("apply")}
                    className="btn-success btn btn-sm filterBtn p-1 mt-2 p-ThemeBtn"
                  />
                  <Button
                    label="Save"
                    type="button"
                    disabled={selectedProjects?.length === 0}
                    onClick={() => applyFilter("save")}
                    className="btn-success btn btn-sm filterBtn p-1 mt-2 p-ThemeBtn"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <Skeleton width="100%" height="4rem" />
      )}
    </>
  );
};

const Testcase = ({ reportData }) => {
  const [allProject, setAllProject] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [showStabilityFilters, setShowStabilityFilters] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [options, setOptions] = useState(null);
  const chartRefTotal = useRef(null);
  const chartRefAutomation = useRef(null);
  const [classificationType, setClassificationType] = useState("Regression");
  const [env, setEnv] = useState(reportData?.[0]?.env?.[0]);
  const [duration, setDuration] = useState(90);
  const [showAllProjects, setShowAllProjects] = useState(false);
  const [totalTestcaseData, setTotalTestcaseData] = useState(null);
  const [automationCoverageData, setAutomationCoverageData] = useState(null);
  const [stabilityData, setStabilityData] = useState(null);
  const [executionCount, setExecutionCount] = useState(null);
  const [totalTestcaseDataLoading, setTotalTestcaseDataLoading] =
    useState(false);
  const [automationCoverageDataLoading, setAutomationCoverageDataLoading] =
    useState(false);
  const [stabilityDataLoading, setStabilityDataLoading] = useState(false);
  const [executionCountLoading, setExecutionCountLoading] = useState(false);
  const [envOptions, setEnvOptions] = useState(null);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  

  const navigate = useNavigate();

  const projectData = useSelector((state) => state.autolytics.projectData);

  const handleClickChart = (label, elements, heading) => {
    const projectNames = filteredProjects?.map(
      (proj) => proj?.["Project Name"]
    );
    const projectId = filteredProjects?.map((proj) => proj?.["P ID"]);
    const classification = [label?.toUpperCase()];
    const type = [classificationType?.toUpperCase()];
    const environment = [env];
    const payload = {
      reportid: 9,
      project: projectNames,
      projectid: projectId,
      env: environment,
      testcaseType:classification,
      
    };
    if(heading==="AutomationCoverage"){
      payload.testcaseClassification=type
    }
    if (elements?.length) {
      const selectedProjectsDetails = projectData?.filter((project) =>
        projectId.includes(project?.pid)
      );
      navigate("/autolytics/reporting", {
        state: {
          label,
          heading,
          payload,
          reportName: "Testcase List Report",
          selectedProjectsDetails,
        },
      });
      return;
    }
    return;
  };

  const getTestcaseTabDetails = () => {
    setTotalTestcaseDataLoading(true);
    setAutomationCoverageDataLoading(true);
    setExecutionCountLoading(true);
    setStabilityDataLoading(true);

    const pid = [];
    filteredProjects?.forEach((item) => pid?.push(item?.["P ID"]));
    if (pid?.length > 0 && env) {
      dashboardService
        .getAutomationCoverage(pid)
        .then((data) => {
          if (data?.message?.toLowerCase() === "testcase details not found")
            setTotalTestcaseData(null);
          else setTotalTestcaseData(data?.data);
        })
        .catch((err) => handleToastErrorCatch(err))
        .finally(() => setTotalTestcaseDataLoading(false));

      dashboardService
        .getAutomationCoverage(pid, classificationType)
        .then((data) => {
          if (data?.message?.toLowerCase() === "testcase details not found")
            setAutomationCoverageData(null);
          else setAutomationCoverageData(data?.data);
        })
        .catch((err) => handleToastErrorCatch(err))
        .finally(() => setAutomationCoverageDataLoading(false));
      dashboardService
        .getAutomationScore(pid, duration, env)
        .then((data) => {
          if (data?.message?.toLowerCase() === "test executions not available")
            setStabilityData(null);
          else setStabilityData(data?.data);
        })
        .catch((err) => handleToastErrorCatch(err))
        .finally(() => setStabilityDataLoading(false));
      dashboardService
        .getExecutionCount(pid, duration)
        .then((data) => {
          if (data?.message?.toLowerCase() === "test executions not available")
            setExecutionCount(null);
          else setExecutionCount(data?.data);
        })
        .catch((err) => handleToastErrorCatch(err))
        .finally(() => setExecutionCountLoading(false));
    }
  };

  const getAllProjects = () => {
    httpService
      .getAllProjectsWithAccess()
      .then((data) => {
        const projectData = [];
        data?.data?.forEach((item) => {
          projectData?.push(item);
        });
        setAllProject(projectData);
      })
      .catch((err) => handleToastErrorCatch(err));
  };

  const saveFilterFunction = {
    getFilter: () => {
      if (allProject?.length > 0) {
        dashboardService
          .getSaveFilter()
          .then((data) => {
            if (data?.data) {
          
              if (data?.data?.projects?.length > 0) {
                setFilteredProjects(data?.data?.projects);
                setSelectedProjects(
                  data?.data?.projects?.map((proj) => proj?.["P ID"])
                );
              } else {
                setFilteredProjects(allProject);
                setSelectedProjects(allProject?.map((proj) => proj?.["P ID"]));
              }

              if (data?.data?.env) setEnv(data?.data?.env);
              if (data?.data?.duration) setDuration(data?.data?.duration);
            } else {
              setFilteredProjects(allProject);
              setSelectedProjects(allProject?.map((proj) => proj?.["P ID"]));
            }
          })
          .catch((err) => handleToastErrorCatch(err));
      }
    },
    postFilter: (payload) => {
      dashboardService
        .postSaveFilter(payload)
        .then((data) => {})
        .catch((err) => handleToastErrorCatch(err));
    },
  };

  useEffect(() => {
    if (reportData?.length > 0) {
      getAllProjects();
      setEnv(reportData?.[0]?.env?.[0]?.toLowerCase());
    }
  }, [reportData]);

  const updateStabilityCards = (type) => {
    setStabilityDataLoading(true);
    setExecutionCountLoading(true);
    const pid = [];
    filteredProjects?.forEach((item) => pid?.push(item?.["P ID"]));
    dashboardService
      .getAutomationScore(pid, duration, env)
      .then((data) => {
        if (data?.message?.toLowerCase() === "test executions not available")
          setStabilityData(null);
        else setStabilityData(data?.data);
      })
      .catch((err) => handleToastErrorCatch(err))
      .finally(() => setStabilityDataLoading(false));
    dashboardService
      .getExecutionCount(pid, duration)
      .then((data) => {
        if (data?.message?.toLowerCase() === "test executions not available")
          setExecutionCount(null);
        else setExecutionCount(data?.data);
      })
      .catch((err) => handleToastErrorCatch(err))
      .finally(() => setExecutionCountLoading(false));

    if (type === "save") {
      const payload = {
       
          
          env: env,
          duration: duration,
        
      };

      saveFilterFunction?.postFilter(payload);
    }
  };

  const applyFilter = (type) => {
    const selectedObjects = selectedProjects.map((value) => {
      return allProject.find((project) => project["P ID"] === value);
    });
    setFilteredProjects(selectedObjects);

    if (type === "save") {
      const payload = {
       
          projects: selectedObjects,
      
      };

      saveFilterFunction?.postFilter(payload);
    }
  };

  const getEnvData = () => {
    let envSet = new Set();

    filteredProjects?.forEach((filteredProject) => {
      const matchingProjects = reportData?.filter(
        (project) => project?.pid === filteredProject?.["P ID"]
      );

      matchingProjects?.forEach((matchingProject) => {
        matchingProject?.env?.forEach((env) => {
          envSet?.add(env?.toLowerCase());
        });
      });
    });

    let newArr = Array.from(envSet);

    const transformedArray = newArr?.map((item) => ({
      label: item.charAt(0).toUpperCase() + item.slice(1),
      value: item,
    }));

    setEnvOptions(transformedArray);
  };

  useEffect(() => {
    setAutomationCoverageDataLoading(true);

    const pid = [];
    filteredProjects?.forEach((item) => pid?.push(item?.["P ID"]));
    if (pid?.length > 0) {
      dashboardService
        .getAutomationCoverage(pid, classificationType)
        .then((data) => {
          if (data?.message?.toLowerCase() === "testcase details not found")
            setAutomationCoverageData(null);
          else setAutomationCoverageData(data?.data);
        })
        .catch((err) => handleToastErrorCatch(err))
        .finally(() => setAutomationCoverageDataLoading(false));
    }
  }, [classificationType]);

  useEffect(() => {
    const option = allProject?.map((project) => ({
      label: project?.["Project Name"],
      value: project?.["P ID"],
    }));
    setOptions(option);

    saveFilterFunction?.getFilter();
    // setSelectedProjects(options?.map((option) => option.value));
  }, [allProject]);

  useEffect(() => {
    getEnvData();
    getTestcaseTabDetails();
  }, [filteredProjects]);

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setShowStabilityFilters(false);
    }
  };

  useEffect(() => {
    if (showStabilityFilters) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showStabilityFilters]);

  return (
    <div>
      <TestcaseHeader
        setShowAllProjects={setShowAllProjects}
        setShowFilters={setShowFilters}
        showFilters={showFilters}
        selectedProjects={selectedProjects}
        options={options}
        applyFilter={applyFilter}
        filteredProjects={filteredProjects}
        setSelectedProjects={setSelectedProjects}
      />
      <div className="row justify-content-around mt-3">
        <TotalTestcaseCard
          totalTestcaseDataLoading={totalTestcaseDataLoading}
          totalTestcaseData={totalTestcaseData}
          chartRefTotal={chartRefTotal}
          handleClickChart={handleClickChart}
        />
        <AutomationCoverageCard
          automationCoverageData={automationCoverageData}
          automationCoverageDataLoading={automationCoverageDataLoading}
          chartRefAutomation={chartRefAutomation}
          handleClickChart={handleClickChart}
          classificationType={classificationType}
          setClassificationType={setClassificationType}
        />
      </div>
      <Dialog
        blockScroll={true}
        draggable={false}
        header="Projects"
        visible={showAllProjects}
        onHide={() => {
          setShowAllProjects(false);
        }}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "70vw" }}
      >
        <div className="all-projects px-3">
          {filteredProjects?.map((item) =>
            convertTextToFilterPill(
              item,
              setFilteredProjects,
              setSelectedProjects,
              selectedProjects,
              filteredProjects
            )
          )}
        </div>
      </Dialog>
      <div className="footerCards my-4">
        <div className="my-auto d-flex justify-content-end filter-container">
          <Button
            label="Apply Filters"
            type="button"
            onClick={() => setShowStabilityFilters(true)}
            icon={
              <FontAwesomeIcon icon={faFilter} className="filterIcon me-1" />
            }
            iconPos="left"
            ref={buttonRef}
            className="btn-success btn btn-sm applyFilterBtn px-2 py-2 p-applyFilterBtn d-flex align-items-center filterConfig"
          />

          {showStabilityFilters && (
            <div
              className="mt-2 py-3 px-4 row filterConfigDropdowns "
              // onMouseLeave={() => setShowStabilityFilters(false)}
              ref={dropdownRef}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="col-10 col-xl-5 d-flex flex-column mb-2 me-2">
                <label htmlFor="envDropdown" className="dropdown-label my-2">
                  Environment<sup>*</sup>
                </label>
                <Dropdown
                  id="envDropdown"
                  className="dashboard-dialog-dropdown"
                  options={envOptions}
                  optionLabel="label"
                  value={env}
                  onChange={(e) => setEnv(e.value)}
                  placeholder="Select env"
                  maxSelectedLabels={1}
                />
              </div>
              <div className="col-10 col-xl-5 d-flex flex-column ms-2">
                <label
                  htmlFor="durationDropdown"
                  className="dropdown-label my-2"
                >
                  Duration<sup>*</sup>
                </label>
                <Dropdown
                  id="durationDropdown"
                  className="stability-dialog-dropdown"
                  options={[
                    { label: "Past 30 days", value: 30 },
                    { label: "Past 60 days", value: 60 },
                    { label: "Past 90 days", value: 90 },
                  ]}
                  value={duration}
                  optionLabel="label"
                  onChange={(e) => setDuration(e.value)}
                  placeholder="Select duration"
                  maxSelectedLabels={1}
                />
              </div>
              <div className="col-12 d-flex justify-content-end gap-2 px-0">
                <Button
                  label="Apply"
                  type="button"
                  disabled={envOptions?.length===0}
                  onClick={() => updateStabilityCards("apply")}
                  className="btn-success btn btn-sm filterBtn p-1 mt-2 p-ThemeBtn"
                />
                <Button
                  label="Save"
                  type="button"
                  disabled={envOptions?.length===0}
                  onClick={() => updateStabilityCards("save")}
                  className="btn-success btn btn-sm filterBtn p-1 mt-2 p-ThemeBtn"
                />
              </div>
            </div>
          )}
        </div>
        <div className="row justify-content-around my-4">
          <ApplicationStabilityCard
            duration={duration}
            stabilityData={stabilityData}
            stabilityDataLoading={stabilityDataLoading}
          />
          <AutomationStabilityCard
            duration={duration}
            stabilityData={stabilityData}
            stabilityDataLoading={stabilityDataLoading}
          />
        </div>
        <div className="row justify-content-around my-4">
          <StabilityIndexCard
            duration={duration}
            stabilityData={stabilityData}
            stabilityDataLoading={stabilityDataLoading}
          />
          <TestcaseExecutedCard
            duration={duration}
            executionCount={executionCount}
            executionCountLoading={executionCountLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default Testcase;
