import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faTrashCan,
  faCircleUser,
} from "@fortawesome/free-regular-svg-icons";
import { useState } from "react";
import "./CrudProject.css";


const CrudProject = ({ props }) => {
  const isAdmin = props.rowData?.Access?.value?.toLowerCase() === "admin";
  const company = props?.rowData?.["Company Name"]?.sortValue;
  return (
    <>
      <div className="crud-icons">
        <FontAwesomeIcon
          className={`crudIcons mx-2 ${
            isAdmin ? "notDisabledAdmin" : "disabledIcon"
          }`}
          icon={faPenToSquare}
          onClick={(e) => {
            if (isAdmin) {
              props["edit_project"]["editProject"](e, props.val);
            }
          }}
          title={`${isAdmin ? "Edit Project Details" : "No Admin Access"}`}
        />

        {/* <button className="adminAction"> */}
        <FontAwesomeIcon
          className={`crudIcons mx-2 ${
            isAdmin ? "notDisabledAdmin" : "disabledIcon"
          }`}
          icon={faTrashCan}
          onClick={(e) => {
            if (isAdmin) {
              props["delete_project"]["deleteProject"](
                e,
                props.val,
                props.rowData["Project Name"],
                props.rowData["Access"]
              );
            }
          }}
          title={`${isAdmin ? "Delete Project" : "No Admin Access"}`}
        />
        {/* </button> */}
        <FontAwesomeIcon
          className={`crudIcons mx-2 ${
            isAdmin ? "notDisabledAdmin" : "disabledIcon"
          }`}
          icon={faCircleUser}
          onClick={(e) => {
            if (isAdmin) {
              props["edit_roles"]["editProjectAccess"](
                e,
                props?.val,
                props?.rowData?.["Project Name"]?.["value"],
                props?.rowData?.["Company Name"]?.sortValue
              );
            }
          }}
          title={`${isAdmin ? "Edit Access" : "No Admin Access"}`}
        />
      </div>
    </>
  );
};

export default CrudProject;
